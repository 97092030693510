import {
  CSRFToken,
  onboardingConstants,
  propertyConstants,
} from "../constants";

export const updateOnboardingSteps = (step, substep) => {
  return (dispatch) => {
    dispatch({
      type: onboardingConstants.ONBOARDING_UPDATE_STEPS,
      step: step,
      substep: substep,
    });
  };
};

export const saveOnboardingProperty = (propertyInfo) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));
  const propertyId = JSON.parse(localStorage.getItem("id_for_fetch_property"));

  return async function (dispatch) {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": CSRFToken,
          Authorization: "Token " + key,
        },

        body: JSON.stringify({
          ...propertyInfo,
          id: propertyId,
          status: propertyConstants.status.completed,
          created_at: null,
          guesty_id: null,
          breezeway_id: null,
          recipient_tin_type: "Individual/Sole proprietor",
        }),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/owners/owner/properties/",
        requestOptions
      );

      if (response.ok) {
        const property_id = await response.json();
        localStorage.setItem("id_for_fetch_property", property_id);

        dispatch({
          type: onboardingConstants.ONBOARDING_ADD_PROPERTY_INFO,
          payload: propertyInfo,
        });
        dispatch({
          type: propertyConstants.GET_PROPERTY_BY_ID,
          payload: propertyInfo,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

// export const updatePropertyStatus = (id) => {
//   return async (dispatch, getState) => {
//     try {
//       const propertyId = JSON.parse(localStorage.getItem('id_for_fetch_property'));
//       const { key } = JSON.parse(localStorage.getItem("hoste_user"));
//       const { property } = getState();

//       const propertyData = Object.assign(property.property);
//       const completedProperty = { ...propertyData, id: propertyId, status: propertyConstants.status.completed };

//       const requestOptions = {
//         method: "PUT",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: "Token " + key
//         },
//         body: JSON.stringify(completedProperty)
//       };
//       const response = await fetch(process.env.REACT_APP_API_URL + "/owners/owner/properties/", requestOptions);

//       if (response.ok) {
//         dispatch({
//           type: propertyConstants.UPDATE_STATUS,
//           payload: completedProperty
//         });
//       };

//     } catch (error) {
//       console.error("There has been a problem with your fetch operation:", error);
//     }
//   };
// };
