import { Spinner } from "reactstrap";

const Loader = () => (
    <Spinner
      className="m-5"
      color="primary"
    >
      Loading...
    </Spinner>
)

export default Loader;

