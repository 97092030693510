import { globalConstants } from "app/constants";



export const getRequestOptions = (method, body = null) => {
    const user = JSON.parse(localStorage.getItem("hoste_user"));
    const isAuthenticated = !!user;
    const noAuthHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": globalConstants.CSRFTOKEN,
    };
    const authHeaders = {
        ...noAuthHeaders,
        "Authorization": "Token " + user?.key
    };

    if (!body) {
        return {
            method: method,
            headers: isAuthenticated ? authHeaders : noAuthHeaders,
        };
    }

    return {
        method: method,
        headers: isAuthenticated ? authHeaders : noAuthHeaders,
        body: JSON.stringify(body)
    };
};