/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useHistory } from "react-router-dom";
import AdminFooter from "components/Footers/AdminFooter";

function Terms(props) {
  const history = useHistory();
  const redirect = () => {
    history.push("/");
  };

  return (
    <>
      <div
        style={{
          boxSizing: "border-box",
          backgroundColor: "white",
          color: "#747a87",
          lineHeight: "30px",
          fontSize: "18px",
        }}
      >
        <img
          style={{ marginLeft: "45%" }}
          width={"189"}
          src={require("assets/img/main-logo.png").default}
          onClick={() => redirect()}
        ></img>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            THIS WEBSITE TERMS OF SERVICE AGREEMENT (this “Agreement”) is
            entered into as of the date the User (defined below) accepts the
            terms of this Agreement (the “Effective Date”), by and between
            Hoste, LLC, a Colorado limited liability company (the “Company”),
            and the individual indicating its acceptance to this Agreement in
            digital or written/recorded form (“User”).
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>RECITALS:</b>
        </p>
        <ol>
          <li>
            <span style={{ fontWeight: "400" }}>
              The Company is the owner and operator of a website named
              iamhoste.com (the “Website”), which manages short term rentals of
              properties for users (the “Services”).
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400" }}>
              User desires to obtain the right to utilize the Website for the
              purpose of having access to the Services.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400" }}>
              Subject to the terms of this Agreement, the Company desires to
              permit User to use the Website for the purpose of utilizing the
              Services.
            </span>
          </li>
        </ol>
        <p>
          <b style={{ fontWeight: "bold" }}>AGREEMENT:</b>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            NOW, THEREFORE, in consideration of the mutual promises set forth
            herein, and other good and valuable consideration, the receipt and
            sufficiency of which are hereby acknowledged, the Company and User
            hereby agree as follows:
          </span>
        </p>
        <ol>
          <li>
            <span style={{ fontWeight: "400" }}>
              Use of Website. User is hereby granted the right to access the
              Website in order to utilize the Services (the “License”). The
              License is non-transferable, non-exclusive, and personal to User.
              User shall not utilize the Website for any purpose other than in
              connection with the Services. The License may be revoked at any
              time by the Company, with or without cause, including without
              limitation for any violation of this Agreement or otherwise. User
              hereby acknowledges that he/she has no right or license to the
              Services or access to the Website in the absence of the Company’s
              continuing approval, in its sole discretion.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400" }}>
              Term. The term of this Agreement (the “Term”) shall commence on
              the Effective Date and shall expire on the date that is one month
              following the Effective Date, unless sooner terminated by either
              party upon five days’ written notice. The Term shall automatically
              renew for successive one month periods unless either party gives
              the other party written notice of its intention not to renew not
              less than five days prior to the effectiveness of any renewal of
              the Term. User understands that if it terminates this Agreement,
              or if the Company terminates this Agreement, the License shall be
              considered immediately revoked. Upon the expiration or termination
              of this Agreement, User shall cooperate with the Company’s removal
              of all User data from the Website.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400" }}>
              User Representations, Warranties, and Acknowledgements. User
              represents, warrants, and acknowledges that (a) its use of the
              License shall comply with all laws, rules, regulations,
              ordinances, and other legal requirements as the same may be
              amended from time to time (the “Legal Requirements”), (b) its use
              of the Website and the Services shall not infringe upon any rights
              held by third parties, including any intellectual property rights,
              and (c) the Company makes no warranties, implied or express,
              regarding the functionality of the Website, which may incur
              glitches, bugs, and may crash or otherwise be non-functional for
              periods of time covered by this Agreement. User hereby disclaims
              any right to seek damages or pursue any remedy, legal or
              otherwise, in connection with any of the above, or with respect to
              the Website or the Services, to the fullest extent permitted by
              applicable law.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400" }}>
              Default and Remedies. The Company and User hereby agree that time
              is of the essence with respect to the performance by User of the
              terms, covenants and conditions of this Agreement. If User
              defaults in the performance of any of the terms, covenants and
              conditions of this Agreement, the Company may, at its option and
              in addition to all other remedies available under applicable law,
              immediately terminate the License pursuant to this Agreement
              without the necessity of prior notice to User. If User defaults in
              the performance of any of the terms, covenants and conditions of
              this Agreement, the Company may pursue all remedies available
              under applicable law. The remedies provided in this paragraph are
              cumulative and not to the exclusion of any other rights or
              remedies that may be available to the Company, at law or in
              equity.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400" }}>
              Terms of Use. The Website is provided by the Company. By accessing
              the Website, User is indicating its acknowledgement and acceptance
              of the following Terms of Use. These Terms of Use are subject to
              change by the Company at any time in its discretion. User’s use of
              the Website after such changes are implemented constitutes User’s
              acknowledgement and acceptance of the changes.
            </span>
          </li>
        </ol>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (a) All material included on the Website, and any other Website
            owned, operated, licensed or controlled by the Company, such as
            text, graphics, logos, images, photographs, audio clips, digital
            downloads, data compilations and software (the “Content”), is the
            property of the Company, its affiliated companies and/or third-party
            licensors and is protected by United States and international
            copyright laws. Modification or use of the Content except as
            expressly provided in these Terms of Use violates the Company’s
            intellectual property rights. The Content may not be copied,
            distributed, republished, uploaded, posted or transmitted in any way
            without the prior written consent of the Company, except that: (i)
            User may download, print, distribute and use pages from the Website
            for its own informational, non-commercial purposes, but not for
            commercial use or general distribution, (ii) any copies of documents
            or pages from the Website must not alter the original Website
            content and must include the Company copyright notice: © 2019 Hoste,
            LLC All Rights Reserved, (c) User may link to the Website provided
            that the link does not falsely imply or suggest that the Company has
            endorsed or is affiliated with the linked site or application.
          </span>
        </p>

        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (b) Users of the Website must not (i) monitor, gather or copy any
            Content on the Website by using any robot, “bot,” spider, crawler,
            spyware, engine, device, software, extraction tool or any other
            automatic device, utility or manual process of any kind, (ii) frame
            or utilize framing techniques to enclose any trademark or other
            proprietary information (including, without limitation, any images,
            text or page layout), (iii) engage in any activities through or in
            connection with the Website that seek to attempt to harm minors or
            are unlawful, offensive, obscene, threatening, harassing, abusive or
            that violate any right of any third party, (iv) attempt to
            circumvent the security systems of the Website, (v) attempt to gain
            unauthorized access to services, materials, other accounts, computer
            systems or networks connected to any Company server, (vi) attempt to
            use the Website for any purposes other than those intended by the
            Company, as determined by the Company in its sole discretion, (vii)
            upload or submit any data or information that contains viruses or
            any other computer code, corrupt files or programs designed to
            interrupt, destroy or limit the functionality or disrupt any
            software, hardware, telecommunications, networks, servers or other
            equipment, (viii) engage in any activity that interferes with a
            user’s access to the Website or the proper operation of the Website.
            User also agrees that, in using the Website, it will not impersonate
            any person or entity. The Content and the functionality of the
            Website may be updated or changed at any time without prior notice.
            In addition, if the Company becomes aware that User is copying,
            modifying or distributing the Content of the Website other than for
            the permitted uses of the Website, the Company reserves the right to
            revoke User’ right to these permitted uses.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (c) The Company shall have the right to monitor use of the Website
            to determine compliance with these Terms of Use, and may revoke its
            consent at any time and User shall immediately cease any further use
            of the Website. User may not copy the design elements, look and feel
            or layout of the Website. Subject to the further terms set forth
            above, User assumes all risks concerning the suitability and
            accuracy of the information within the Website which it proposes to
            use, subject to the Company’s consent.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (d) The Company cannot guarantee that the Website or its content is
            error free and the Company makes no representations about the
            technical accuracy or functionality of the Website or that the
            Content is accurate, error free or up to date.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (e) THE WEBSITE IS PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
            OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS OR
            INTELLECTUAL PROPERTY. USER EXPRESSLY AGREES THAT ITS USE OF THE
            WEBSITE IS AT USER’S SOLE RISK. THE COMPANY DOES NOT WARRANT THAT
            THE INFORMATION IN THE WEBSITE IS ACCURATE, RELIABLE, UP TO DATE OR
            CORRECT, THAT THE WEBSITE WILL BE AVAILABLE AT ANY PARTICULAR TIME
            OR LOCATION OR THAT THE WEBSITE IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR
            TYPOGRAPHICAL ERRORS, AND THE COMPANY MAY MAKE CHANGES OR
            IMPROVEMENTS AT ANY TIME. USER, AND NOT THE COMPANY, ASSUMES THE
            ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE
            EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THE WEBSITE OR
            ITS CONTENT. THE COMPANY MAKES NO WARRANTIES THAT USER’S USE OF THE
            CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO
            LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (f) Neither the Company, any of its affiliates, directors, officers
            and employees, nor any other party involved in creating, producing
            or delivering the Website is liable for any direct, incidental,
            consequential, indirect or punitive damages arising out of User’s
            access to, or use of, the Website or the operation of the Website or
            failure of the Website to operate. In no event shall the Company be
            liable for any direct, indirect, special, punitive, incidental,
            exemplary or consequential, damages or any damages whatsoever, even
            if the Company has been previously advised of the possibility of
            such damages, whether in an action in contract, negligence, or any
            other theory, arising out of or in connection with the use,
            inability to use or performance of the information, services,
            products and materials available from the Website. These limitations
            shall apply notwithstanding any failure of essential purpose of any
            limited remedy. User’s acceptance of this limitation of liability is
            an essential term of this agreement and the parties acknowledge that
            the Company would not grant access to the Website without User’s
            agreement to this term.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (g) User should assume that everything it sees or reads in the
            Website is proprietary information protected by copyright or
            trademark unless otherwise noted, and may not be used except as
            provided in these Terms of Use or with the written permission of the
            Company. Nothing herein grants a license to any Company trademarks,
            copyrights or other intellectual property rights, whether by
            implication, estoppel or otherwise.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (h) The Website may link to or be linked from other websites that
            are not maintained by, or related to, the Company. The Company does
            not endorse, and is not responsible for, the content of any of those
            third-party websites.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (i) The Company makes no representation that materials on the
            Website are appropriate or available for use in locations outside
            the United States. Access to the Website from countries or
            territories where such access is illegal is prohibited. Those who
            choose to access the Website outside the United States do so on
            their own initiative and are responsible for compliance with local
            laws.
          </span>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (j) User represents that User will utilize the Website solely for
            Services personal to User and not on behalf of any third party.
          </span>
        </p>
        <ol start={"6"}>
          <li>
            <span style={{ fontWeight: "400" }}>Miscellaneous.</span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (a) Assignment. User shall have no right to assign, sell or transfer
            its rights or obligations under this Agreement to any third party
            without the prior written consent of the Company, in the Company’s
            sole discretion.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (b) Entire Agreement. This Agreement is the entire agreement between
            the parties concerning its subject matter, and it supersedes and
            cancels all prior agreements or understandings, written or oral,
            between the parties.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (c) Choice of Laws. THIS AGREEMENT SHALL BE GOVERNED BY, AND
            CONSTRUED AND ENFORCED IN ACCORDANCE WITH THE LAWS OF THE STATE OF
            COLORADO WITHOUT REGARD TO ANY OTHERWISE APPLICABLE PRINCIPLES OF
            CONFLICTS OF LAW.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (d) Acknowledgement. The parties hereto acknowledge that this
            Agreement is merely a License. This Agreement does not create any
            intellectual property rights or rights of possession whatsoever in
            favor of or on behalf of User, but only licenses to User the right
            to access the Website for the purposes, and in the manner, provided
            for in this Agreement.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (e) Binding Effect. This Agreement shall be binding on the
            successors, permitted assigns, heirs, executors and administrators
            of the parties hereto.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (f) Amendments and Waiver. This Agreement may be changed or modified
            at any time by the Company in its sole discretion.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (g) Severability. In the event one or more of the provisions of this
            Agreement are declared invalid, illegal or unenforceable in any
            respect, such invalidity, illegality or unenforceability shall not
            affect any other provisions hereof, and this Agreement shall be
            construed as if such invalid, illegal or unenforceable provision had
            never been contained herein.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            (h) No Personal Liability of the Company. The Company, nor such
            party’s owners, partners, members, managers, shareholders, or
            venturers shall have any personal, corporate or other liability
            hereunder.
          </span>
        </p>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <AdminFooter />
      </div>
    </>
  );
}

export default Terms;
