import { alertConstants, userConstants } from "../constants";

export const getUser = () => {
  return async (dispatch) => {
    try {
      const { key } = JSON.parse(localStorage.getItem("hoste_user"));
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/user/",
        requestOptions
      );
      const user = await response.json();

      if (response.ok) {
        dispatch({
          type: userConstants.GETALL_SUCCESS,
          payload: user,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const updateUser = () => {
  return async (dispatch) => {
    try {
      const { key } = JSON.parse(localStorage.getItem("hoste_user"));
      const requestOptions = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/user/",
        requestOptions
      );
      const user = await response.json();

      if (response.ok) {
        dispatch({
          type: userConstants.UPDATE_USER,
          payload: user,
        });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: "Updating failed",
        });
        setTimeout(() => {
          dispatch({
              type: alertConstants.CLEAR
          });
      }, 3000);
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const createOwnerProfile = (profile, photo) => {
  const body = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    primary_email: profile.primary_email,
    secondary_email: profile.secondary_email,
    address: profile.address,
    active: true,
    account_id: 1,
    created_at: null,
    picture: photo ? photo : "",
    phone: profile.phone
  };

  return async (dispatch) => {
    try {
      const { key } = JSON.parse(localStorage.getItem("hoste_user"));
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/owners/create-owner-profile/",
        requestOptions
      );
        
      if (response.ok) {
        dispatch({
          type: userConstants.CREATE_OWNER_PROFILE,
          payload: {...profile,picture: photo}
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: "The operation was successful! If primary email address was changed - confirm email",
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR
          });
        }, 3000);
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: `Error occured during processing your request. Try again!`,
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR
          });
        }, 3000);
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: `Error occured during processing your request: ${error}`,
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR
        });
      }, 3000);
      console.error(
        "There has been a problem with your fetch operation:",
        error.message
      );
    }
  };
};

export const getOwnerProfile = () => {
  return async (dispatch) => {
    try {
      const { key } = JSON.parse(localStorage.getItem("hoste_user"));
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/owners/owner/",
        requestOptions
      );
      const profile = await response.json();
      if (response.ok) {
        dispatch({
          type: userConstants.FETCH_OWNER_PROFILE,
          payload: profile,
        });
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: "Error occured during proccesing your request. Try again!",
      });
      setTimeout(() => {
        dispatch({
            type: alertConstants.CLEAR
        });
    }, 3000);
      console.error(
        "There has been a problem with your fetch operation:",
        error.message
      );
    }
  };
};

export const getOwnerProfiles = () => {
  return async (dispatch) => {
    try {
      const { key } = JSON.parse(localStorage.getItem("hoste_user"));
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/owners/",
        requestOptions
      );
      const profiles = await response.json();

      if (response.ok) {
        dispatch({
          type: userConstants.FETCH_OWNER_PROFILES,
          payload: profiles,
        });
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: "Error occured during proccesing your request. Try again!",
      });
      setTimeout(() => {
        dispatch({
            type: alertConstants.CLEAR
        });
    }, 3000);
      console.error(
        "There has been a problem with your fetch operation:",
        error.message
      );
    }
  };
};
