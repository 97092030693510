import moment from "moment";

export function parseDate(array) {
    if (!array.length) return array
    return (
        array &&
        array.length !== 0 &&
        array.map((item) => {
            let startDate = moment(item.startDate, "YYYY-MM-DD");
            let endDate = moment(item.endDate, "YYYY-MM-DD");
            
            if (item.typeOfReservation === "Maintenance"){
                endDate = endDate.add(1, "days")
            }
            return {
                ...item,
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                arrival: item.arrival,
                color: item.color,
            };
        })
    );
};

export function splitToDates(startDate, endDate) {
    const dates = [];
    let currentDate = moment(startDate).toDate();

    while (moment(currentDate).isSameOrBefore(moment(endDate))) {
        dates.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
    };

    return dates;
};