import { useEffect, useState } from "react";
import { Badge, Table } from "reactstrap";
import { capitalizeFirstLetter, generateKey } from "app/helpers";



const FormTable = ({ columns, data, handler, onClickHandler }) => {
    const [id, setId] = useState(null);
    const [title, setTitle] = useState(null);
    const [finish, setFinish] = useState(false);

    useEffect(() => {
        const sorters = { id, finish, title };
        handler && handler(sorters);
    }, [id, finish, title]);

    const sortById = () => {
        setTitle(null);
        setFinish(null);
        setId(!id);
    };

    const sortByDate = () => {
        setTitle(null);
        setFinish(!finish);
        setId(null);
    };

    const sortByTitle = () => {
        setTitle(!title);
        setFinish(null);
        setId(null);
    };

    const sortFunctions = {
        sortById,
        sortByDate,
        sortByTitle
    };

    const defineDataSort = (column) => {
        return column.isSorted ? column.accessor : null;
    };

    const defineHandler = (column) => {
        switch (column.descriptionType) {
            case "string": {
                return sortFunctions.sortByTitle;
            }
            case "number": {
                return sortFunctions.sortById;
            }
            case "date": {
                return sortFunctions.sortByDate;
            }
        };
    };

    const getCorrectValue = (data) => {
        const item = capitalizeFirstLetter(data);
        return item ? item : "No data";
    };

    const getCorrectDescription = (column, item) => {
        if (column.isShown) {
            if (column.isEmptyTitle) {
                return <td>
                    <Badge color="" className="badge-dot mr-4">
                        <i className="bg-success" />
                        <span className="status">
                            {getCorrectValue(item[column.accessor])}
                        </span>
                    </Badge>
                </td>
            }
            return <td>
                {getCorrectValue(item[column.accessor])}
            </td>
        }
    };

    const getCorrectHead = (column) => {
        if (column.isShown && column.isEmptyTitle) {
            return <th></th>
        }
        return column.isShown &&
            <th
                scope="col"
                key={generateKey()}
                data-sort={defineDataSort(column)}
                onClick={defineHandler(column)}
            >
                {column.header}
            </th>
    };

    return (
        <Table className="align-items-center table-flush" responsive hover>
            <thead className="thead-light">
                <tr>
                    {columns.map(col => getCorrectHead(col))}
                </tr>
            </thead>

            <tbody className="list">
                <>
                    {data && data.length ? (
                        // data.map((item) => (
                        //     <tr key={generateKey()} onClick={onClickHandler}>
                        //         {columns.map(col => getCorrectDescription(col, item))}
                        //     </tr>
                        // ))
                        data.map((item, idx) => (
                            <tr key={`idx ${idx}`} onClick={() => onClickHandler(idx)}>
                                {columns.map(col => getCorrectDescription(col, item))}
                            </tr>
                        ))
                    ) : (
                        <td>You have not had any records yet...</td>
                    )}
                </>
            </tbody>
        </Table>
    );
};

export default FormTable;