/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useHistory } from "react-router-dom";
import AdminFooter from "components/Footers/AdminFooter";

function PrivacyPolicy(props) {
  const history = useHistory();
  const redirect = () => {
    history.push("/");
  };

  return (
    <>
      <div
        style={{
          boxSizing: "border-box",
          backgroundColor: "white",
          color: "#747a87",
          lineHeight: "30px",
          fontSize: "18px ",
          fontWeight: "400",
        }}
      >
        <img
          style={{ marginLeft: "45%" }}
          width={"189"}
          src={require("assets/img/main-logo.png").default}
          onClick={() => redirect()}
        ></img>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Protecting your private information is our priority. This Statement
            of Privacy applies to iamhoste.com and Hoste LLC and governs data
            collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to Hoste LLC include
            iamhoste.com, Hoste and booking.iamhoste.com. The Hoste website is a
            Vacation Rental Management Services, Vacation Rental Guest Services,
            and Vacational Rental Booking Site site. By using the Hoste website,
            you consent to the data practices described in this statement.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            Collection of your Personal Information
          </b>
        </p>
        <p style={{ marginBottom: "4rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            In order to better provide you with products and services offered on
            our Site and fulfill the terms of a contract between you and us,
            Hoste may collect personally identifiable information. If this is
            information is not provided by you, we may not be able to provide
            services to you. Personally identifiable information collected would
            be the following:
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Contact Information:
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – First and Last Name
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – Mailing Address
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – E-mail Address
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – Phone Number
          </span>
        </p>
        <p style={{ margin: "0 0 5rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Identity Information:
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – Government ID such as government-issued driver’s license, or
            passport
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – Selfie/Photo of Yourself
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – Other identity information associated with your stay or your owned
            Hoste managed property
          </span>
        </p>
        <p style={{ margin: "0 0 5rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Other Information:
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – Automobile and license plate number(s) in association with a stay
            at a Hoste property
          </span>
        </p>
        <p style={{ margin: "0 0 5rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Payment Information:
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            If you purchase Hoste’s products, stay at a Hoste vacation rental,
            or services, we collect billing and credit card information. This
            information is used to complete the purchase transaction and to
            verify that the credit card information is not fraudulent.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may also collect information about you and your guests age for
            purposes of verifying with property minimum renter age requirements.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may also collect bank account, tax information, and other
            information so that we can make payments to you. We also may collect
            tax information for tax reporting purposes. In order to get
            necessary permits and sales tax licenses or pay bills on your
            behalf, we may also collect other types of information necessary to
            do so.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste also may collect information about an entity or company you
            own that owns a property we manage, owners of an entity that owns
            the property, or family members or guests who stay at the property.
            We also may collect information on other owners of the property.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to stay in
            a vacation rental, have us manage a property you own, or use any
            other service or product that we provide. These may include: (a)
            registering for an account on our Site; (b) entering a sweepstakes
            or contest sponsored by us or one of our partners; (c) signing up
            for special offers from selected third parties; (d) sending us an
            email message; (e) submitting your credit card or other payment
            information when ordering and purchasing products and services on
            our Site (f) communicating with us on any vacation rental marketing
            platform. We will use your information for, but not limited to,
            communicating with you in relation to services and/or products you
            have requested from us. We also may gather additional personal or
            non-personal information in the future.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We may collect information you choose to provide during
            communications with us by phone, in person, email, text message,
            application, online vacation rental marketing platforms, or any
            electronic means of communications.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We may collect information on your submitted reviews, social media
            account interactions, or any other part of our service.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            To the extent allowed by applicable law, we may conduct criminal
            background checks or aquire police reports. We may receive potential
            fraud alerts from third-party providers with relevant information.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Use of your Personal Information</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste collects and uses your personal information to operate its
            website(s) and deliver the services you have requested.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may also use your personally identifiable information to
            inform you of other products or services available from Hoste and
            its affiliates.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may use your personal information to take payment from you for
            Hoste services or to make a payment to you.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste will use your personal information to respond to inquiries,
            communicate with you about your stay, for rental management, and to
            prevent fraud or illegal activity.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may use your personal information or any information about you
            to better user experience or any aspect of our service. We may use
            information to better your personal experience, such as your booking
            preferences and booking history.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            Sharing Information with Third Parties
          </b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste does not sell, rent or lease its customer lists to third
            parties.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may share information with handymen/maintenance providers,
            cleaning providers, and contractors in order to provide the services
            you ask for.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may also share contact information with property owners so
            they can reply to reviews and questions.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may share data with trusted partners to help perform
            statistical analysis, offer additional services, send you email or
            postal mail, provide customer support, fraud prevention, identity
            verification, payment processing, access to the property, anti-money
            laundering compliance, risk assessment or arrange for deliveries.
            All such third parties are prohibited from using your personal
            information except to provide these services to Hoste, and they are
            required to maintain the confidentiality of your information.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Hoste or the site; (b) protect
            and defend the rights or property of Hoste; and/or (c) act under
            exigent circumstances to protect the personal safety of users of
            Hoste, or the public.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Tracking User Behavior</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste may keep track of the websites and pages our users visit
            within Hoste, in order to determine what Hoste services are the most
            popular. This data is used to deliver customized content and
            advertising within Hoste to customers whose behavior indicates that
            they are interested in a particular subject area.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            Automatically Collected Information
          </b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Information about your computer hardware and software may be
            automatically collected by Hoste. This information can include: your
            IP address, browser type, domain names, access times and referring
            website addresses. This information is used for the operation of the
            service, to maintain quality of the service, and to provide general
            statistics regarding use of the Hoste website.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Use of Cookies</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            The Hoste website may use “cookies” to help you personalize your
            online experience. A cookie is a text file that is placed on your
            hard disk by a web page server. Cookies cannot be used to run
            programs or deliver viruses to your computer. Cookies are uniquely
            assigned to you, and can only be read by a web server in the domain
            that issued the cookie to you.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            One of the primary purposes of cookies is to provide a convenience
            feature to save you time. The purpose of a cookie is to tell the Web
            server that you have returned to a specific page. For example, if
            you personalize Hoste pages, or register with Hoste site or
            services, a cookie helps Hoste to recall your specific information
            on subsequent visits. This simplifies the process of recording your
            personal information, such as billing addresses, shipping addresses,
            and so on. When you return to the same Hoste website, the
            information you previously provided can be retrieved, so you can
            easily use the Hoste features that you customized.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hostē uses cookies to advertise online using remarketing. Hostē ads
            will show on third-party websites including Google, Facebook, and
            other sites across the internet.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            You have the ability to accept or decline cookies. Most Web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the
            interactive features of the Hoste services or websites you visit.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            To opt out of Google’s use of cookies or device identifiers you may
            visit: https://adssettings.google.com/authenticated
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Links</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            Security of your Personal Information
          </b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste secures your personal information from unauthorized access,
            use, or disclosure. Hoste uses the following methods for this
            purpose:
          </span>
        </p>
        <p style={{ margin: "0 0 5rem 3rem" }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            – SSL Protocol
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the Secure Sockets Layer (SSL) protocol.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through this Site cannot be guaranteed.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Children Under Thirteen</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste does not knowingly collect personally identifiable information
            from children under the age of thirteen. If you are under the age of
            thirteen, you must ask your parent or guardian for permission to use
            this website.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>E-mail Communications</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            From time to time, Hoste may contact you via email for the purpose
            of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication. In order
            to improve our Services, we may receive a notification when you open
            an email from Hoste or click on a link therein.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            If you would like to stop receiving marketing or promotional
            communications via email from Hoste, you may opt out of such
            communications by clicking on the UNSUBSCRIBE button.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>External Data Storage Sites</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            California Online Privacy Protection Act
          </b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require any person or
            company in the United States (and conceivably the world) that
            operates websites collecting Personally Identifiable Information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals or companies with whom it is being shared. – See
            more at:
            <a href="https://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">
              https://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </a>
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            According to CalOPPA, we agree to the following:
          </b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Users can visit our site anonymously.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Once this privacy policy is created, we will add a link to it on our
            home page or as a minimum, on the first significant page after
            entering our website.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Our Privacy Policy link includes the word ‘Privacy’ and can easily
            be found on the page specified above.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            You will be notified of any Privacy Policy changes on our Privacy
            Policy Page.
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            You can change your personal information by emailing us.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Fair Information Practices</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            The Fair Information Practices Principles form the backbone of
            privacy law in the United States and the concepts they include have
            played a significant role in the development of data protection laws
            around the globe. Understanding the Fair Information Practice
            Principles and how they should be implemented is critical to comply
            with the various privacy laws that protect personal information.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>
            In order to be in line with Fair Information Practices we will take
            the following responsive action, should a data breach occur:
          </b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We will notify you via email within 7 business days
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            We also agree to the Individual Redress Principle which requires
            that individuals have the right to legally pursue enforceable rights
            against data collectors and processors who fail to adhere to the
            law. This principle requires not only that individuals have
            enforceable rights against data users, but also that individuals
            have recourse to courts or government agencies to investigate and/or
            prosecute non-compliance by data processors.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>CAN SPAM Act</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold", margin: "0 0 4rem " }}>
            We collect your email address in order to:
          </b>
        </p>
        <ul>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Send information, respond to inquiries, and/or other requests or
              questions
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Market to our mailing list or continue to send emails to our
              clients after the original transaction has occurred.
            </span>
          </li>
        </ul>
        <p>
          <b style={{ fontWeight: "bold" }}>
            To be in accordance with CANSPAM, we agree to the following:
          </b>
        </p>
        <ul style={{ margin: "0 0 4rem " }}>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Not use false or misleading subjects or email addresses.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Identify the message as an advertisement in some reasonable way.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Include the physical address of our business or site headquarters.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Monitor third-party email marketing services for compliance, if
              one is used.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Honor opt-out/unsubscribe requests quickly.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Allow users to unsubscribe by using the link at the bottom of each
              email.
            </span>
          </li>
        </ul>
        <p>
          <b style={{ fontWeight: "bold" }}>
            If at any time you would like to unsubscribe from receiving future
            emails, you can email us at the email address set forth below
          </b>
        </p>
        <ul style={{ margin: "0 0 4rem " }}>
          <li>
            <span style={{ fontWeight: "400", fontSize: "18px" }}>
              Follow the instructions at the bottom of each email and we will
              promptly remove you from ALLcorrespondence.
            </span>
          </li>
        </ul>
        <p>
          <b style={{ fontWeight: "bold" }}>Changes to this Statement</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste reserves the right to change this Privacy Policy from time to
            time. We will notify you about significant changes in the way we
            treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our site, and/or by updating any privacy information on this page.
            Your continued use of the Site and/or Services available through
            this Site after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
          </span>
        </p>
        <p>
          <b style={{ fontWeight: "bold" }}>Contact Information</b>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Hoste welcomes your questions or comments regarding this Statement
            of Privacy. If you believe that Hoste has not adhered to this
            Statement, please contact Hoste at:
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>Hoste LLC</span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            3520 Galley Road Suite 204
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Colorado Springs, Colorado 80909
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Email Address:
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            info@iamhoste.com
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Telephone number:
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            719-344-9974
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Effective as of March 31, 2019
          </span>
        </p>
        <p style={{ margin: "0 0 4rem " }}>
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Updated May 5, 2019
          </span>
        </p>
      </div>
      {/* <div style={{ marginBottom: "2rem" }}> */}
        <AdminFooter />
      {/* </div> */}
    </>
  );
}

export default PrivacyPolicy;
