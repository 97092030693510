function GlobalBg() {
  return (
    <>
        <img
          alt=''
          className="global-bg full-height"
          src={
            require("assets/img/bg.jpg").default
          }
        />
    </>
  );
}

export default GlobalBg;
