// export function capitalizeFirstLetter(string) {
//     return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
// };
export function capitalizeFirstLetter(string) {
    const isValidStr = typeof(string) === "string" && string;
    return isValidStr ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};

const toCamel = (str) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

const isObject = function (obj) {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const keysToCamel = function (obj) {
    if (isObject(obj)) {
        const n = {};

        Object.keys(obj)
            .forEach((k) => {
                n[toCamel(k)] = keysToCamel(obj[k]);
            });

        return n;
    } else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return keysToCamel(i);
        });
    }

    return obj;
};
