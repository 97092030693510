import { reservationConstants } from 'app/constants';
import { keysToCamel } from 'app/helpers';
import { parseDate } from 'app/helpers';


const initialState = {
    reservations: null,
    reservation: null,
    availabillitySettings: [],
    calendar: [],
    pages: 1
};

export function reservation(state = initialState, action) {
    let updatedList = [];

    switch (action.type) {
        case reservationConstants.CREATE_RESERVATION_SUCCESS:
            updatedList = parseDate(keysToCamel([...state.reservations, action.payload]));

            return {
                ...state,
                reservation: parseDate(keysToCamel([action.payload]))[0]
            };
        case reservationConstants.GET_ALL_RESERVATIONS_BY_PROPERTY_ID:
            return {
                ...state,
                reservations: parseDate(keysToCamel(action.payload)) ?? []
            };
        case reservationConstants.GET_ALL_RESERVATIONS:
            const parsedList = parseDate(keysToCamel(action.payload[0]));

            return {
                ...state,
                reservations: parsedList ?? [],
                pages: action.payload[1]
            };
        case reservationConstants.GET_RESERVATION_BY_ID:
            updatedList = state.reservations.map((range) => {
                if (range.id === action.payload.id) {
                    const updatedReservation = parseDate(keysToCamel([action.payload]));
                    return { ...range, ...updatedReservation[0] };
                }
                return range;
            });

            return {
                ...state,
                reservations: updatedList,
                reservation: parseDate(keysToCamel([action.payload]))[0]
            };
        case reservationConstants.UPDATE_RESERVATION:
            updatedList = state.reservations.map((range) => {
                if (range.id === action.payload.id) {
                    const updatedReservation = parseDate(keysToCamel([action.payload]));
                    return { ...range, ...updatedReservation[0] };
                }
                return range;
            });

            return {
                ...state,
                reservations: updatedList
            };
        case reservationConstants.RETREIVE_CALENDAR_FOR_COST:
            return {
                ...state,
                calendar: action.payload
            };
        case reservationConstants.RETREIVE_CALENDAR_FOR_SETTINGS:
            return {
                ...state,
                availabillitySettings: action.payload
            };
        case reservationConstants.CLEAR_CALENDAR:
            return {
                ...state,
                calendar: []
            };
        case reservationConstants.CLEAR:
            return {
                ...state,
                reservations: [],
                reservation: {},
                availabillitySettings: []
            };
        default:
            return state;
    }
}