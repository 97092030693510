import React, { useEffect } from "react";
import DashboardHeader from "components/Headers/DashboardHeader.js";

import { connect, useDispatch, useSelector } from "react-redux";

import {
  getAllProperties,
  getDashboardData,
  clearReservationState,
  getOwnerProfile,
  startLoading,
  stopLoading
} from "app/actions";
import { Row } from "reactstrap";

import { alertConstants } from "app/constants";
import LineChart from "components/Graphs/LineGraph";
import Loader from "components/Loaders/Loader";
import QuickPropertyAccessWidget from "components/Widgets/QuickPropertyAccessTable";
import LatestPropertyCareWidget from "components/Widgets/LatestPropertyCareWidget";

function Dashboard() {
  const properties = useSelector(state => state.property.allProperties);
  const owner = useSelector(state => state.user.ownerProfile);
  const dashboardData = useSelector(state => state.property.dashboard);
  const isLoading = useSelector(state => state.loading.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: alertConstants.CLEAR,
    });

    !properties && dispatch(getAllProperties());
    dispatch(clearReservationState());
    !owner && dispatch(getOwnerProfile());

    localStorage.removeItem("property_id");
  }, []);

  useEffect(() => {
    if (properties && properties.length) {
      const idsList = properties.map((prop) => prop.id);
      dispatch(getDashboardData(idsList));
    }
  }, [properties]);

  useEffect(() => {
    (dashboardData && Object.keys(dashboardData).length)
      ? dispatch(stopLoading())
      : dispatch(startLoading());
  }, [dashboardData]);


  return (
    <>
      <div className="dashboard-header mb-4">
        <DashboardHeader page={"properties"} />
      </div>

      {isLoading
        ? (<Row className="justify-content-center mt-6">
          <Loader />
        </Row>)
        : (<>
          <LineChart />
          <div className="dashboard-tables-container">
              <QuickPropertyAccessWidget />
              <LatestPropertyCareWidget />
          </div>
        </>)
      }
    </>
  );
}
export default connect(null, {
  getAllProperties,
  clearReservationState,
})(Dashboard);
