import {
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";



const FormModal = ({ title, isOpen, children }) => {
    return (
        <div>
            <Modal isOpen={isOpen}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>{children}</ModalBody>
            </Modal>
        </div>
    )
};

export default FormModal;