/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useRef, useState} from "react";
// javascript plugin that creates a sortable object from a dom object
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import {connect, useDispatch} from "react-redux";
import OwnerHeader from "components/Headers/OwnerHeader";
import OwnerSubNav from "components/Navbars/OwnerSubNav";
import {clearPropertyCareUrl, getAllPropertyCares, getUrlPropertyCare,} from "app/actions";
import Loader from "components/Loaders/Loader";
import {BreadCrumb} from "primereact/breadcrumb";
import moment from "moment";


function PropertyCare({tasksData, reportUrl, pagesData}) {
    const [tasks, setTasks] = useState(null);
    const [report, setReport] = useState("");
    const [isLoading, setLoading] = useState(true);

    const listRef = useRef(null);
    const iframeRef = useRef(null);

    const dispatch = useDispatch();
    const [id, setId] = useState(1);
    const [finish, setFinish] = useState(1);
    const [title, setTitle] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [amountPage, setAmountPage] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);

    const items = [{label: "Selected Task Report"}];
    const home = {icon: "pi pi-home"};

    useEffect(() => {
        const url = window.location.pathname;
        const words = url.split("/");
        const propertyId = words[words.length - 1];

        dispatch(getAllPropertyCares(propertyId, currentPage, id, finish, title));
    }, [currentPage, id, finish, title]);

    useEffect(() => {
        if (tasksData) {
            setTasks(tasksData);
            setLoading(false);
        }

        if (pagesData) {
            setAmountPage(Array.from({length: pagesData}, (_, i) => i + 1));
        }
    }, [tasksData, reportUrl]);

    function onClickUrl(e, url) {
        e.preventDefault();
        setSelectedTask(url);
    }

    function sortById() {
        setTitle(1);
        setFinish(1);
        setId(!id);
    }

    function sortByDate() {
        setTitle(1);
        setFinish(!finish);
        setId(1);
    }

    function sortByTitle() {
        setTitle(!title);
        setFinish(1);
        setId(1);
    }

    function onClickPage(e) {
        e.preventDefault();
        const a = Number(e.target.innerText);
        setCurrentPage(a);
    }

    const onNext = (e) => {
        e.stopPropagation();
        setCurrentPage(currentPage + 1);
    };

    const onPrevious = (e) => {
        e.stopPropagation();
        setCurrentPage(currentPage - 1);
    };

    function clickOnHome() {
        setSelectedTask(null);
    }

    function isValidReport(date) {
        return moment(date).isSameOrAfter(moment("2023-10-16"));
    }

    return (
        <>
            <div className="dashboard-header">
                <OwnerHeader/>
            </div>
            <OwnerSubNav curPage="Property Care"/>

            <Container fluid>
                <Row>
                    <Col>
                        <Card className="bg-default">
                            <CardBody className="pl-0 pr-0">
                                <Row>
                                    <Col md={7}>
                                        <h3 className="mt-1 pl-4 mb-4">
                                            <div
                                                className={
                                                    "icon-shape text-white rounded-circle bg-traffic1 title-icon"
                                                }
                                            >
                                                <i className={"ni ni-folder-17"}/>
                                            </div>
                                            Property Care
                                        </h3>
                                    </Col>
                                </Row>

                                {isLoading ? (
                                    <div className="text-center">
                                        <Loader/>
                                    </div>
                                ) : selectedTask ? (
                                    <div>
                                        <BreadCrumb
                                            model={items}
                                            home={home}
                                            onClick={clickOnHome}
                                        />

                                        <iframe
                                            src={selectedTask}
                                            style={{width: "100%", height: "500px", border: "0px"}}
                                            title="Hoste report"
                                            sandbox="allow-same-origin allow-scripts"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="table-responsive" ref={listRef}>
                                            <CardFooter className="py-4">
                                                <nav aria-label="...">
                                                    <Pagination
                                                        className="pagination justify-content-end mb-0 pagi"
                                                        listClassName="justify-content-end mb-0"
                                                        onClick={(e) => onClickPage(e)}
                                                    >
                                                        <PaginationItem
                                                            style={{
                                                                visibility:
                                                                    currentPage == "1" ? "hidden" : "visible",
                                                            }}
                                                        >
                                                            <PaginationLink
                                                                href="#pablo"
                                                                onClick={(e) => onPrevious(e)}
                                                            >
                                                                <i className="fas fa-angle-left"/>
                                                                <span className="sr-only">Previous</span>
                                                            </PaginationLink>
                                                        </PaginationItem>

                                                        <>
                                                            {amountPage.map((numPage) => {
                                                                if (
                                                                    (currentPage == 1
                                                                        ? numPage <= currentPage + 4
                                                                        : numPage <= currentPage + 2 ||
                                                                        currentPage == 2
                                                                            ? numPage <= currentPage + 3
                                                                            : numPage <= currentPage + 2) &&
                                                                    (currentPage == pagesData
                                                                        ? numPage >= currentPage - 4
                                                                        : numPage >= currentPage - 2 ||
                                                                        currentPage == pagesData - 1
                                                                            ? numPage >= currentPage - 3
                                                                            : numPage >= currentPage - 2)
                                                                ) {
                                                                    return (
                                                                        <PaginationItem
                                                                            className={{
                                                                                active: numPage == currentPage,
                                                                            }}
                                                                        >
                                                                            <PaginationLink href="#pablo">
                                                                                {numPage}
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                    );
                                                                }
                                                            })}
                                                        </>
                                                        <PaginationItem
                                                            style={{
                                                                visibility:
                                                                    currentPage == pagesData
                                                                        ? "hidden"
                                                                        : "visible",
                                                            }}
                                                        >
                                                            <PaginationLink
                                                                href="#pablo"
                                                                onClick={(e) => onNext(e)}
                                                            >
                                                                <i className="fas fa-angle-right"/>
                                                                <span className="sr-only">Next</span>
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    </Pagination>
                                                </nav>
                                            </CardFooter>

                                            <Table
                                                className="align-items-center table-flush"
                                                responsive
                                                hover
                                            >
                                                <thead className="thead-light">
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th
                                                        className="sort"
                                                        onClick={() => sortByTitle()}
                                                        data-sort="name"
                                                        scope="col"
                                                    >
                                                        Task title
                                                    </th>
                                                    <th
                                                        className="sort"
                                                        scope="col"
                                                        data-sort="name"
                                                        onClick={() => sortById()}
                                                    >
                                                        Task ID
                                                    </th>
                                                    <th
                                                        className="sort"
                                                        data-sort="name"
                                                        onClick={() => sortByDate()}
                                                        scope="col"
                                                    >
                                                        Completed Date
                                                    </th>
                                                    <th className="sort text-center" scope="col"></th>
                                                </tr>
                                                </thead>

                                                <tbody className="list">
                                                {tasks && tasks.length !== 0 ? (
                                                    tasks.map((item, idx) => (
                                                        <tr
                                                            key={idx}
                                                            onClick={(e) => {
                                                                isValidReport(item.finished_at) &&
                                                                onClickUrl(e, item.report_url);
                                                            }}
                                                            style={{cursor: "pointer"}}
                                                        >
                                                            <td>
                                                                <Badge color="" className="badge-dot mr-4">
                                                                    {item.type_task_status === "finished" ? (
                                                                        <>
                                                                            <i className="bg-success"/>

                                                                            <span className="status">
                                          Completed
                                        </span>
                                                                        </>
                                                                    ) : null}
                                                                    {item.type_task_status === "closed" ? (
                                                                        <>
                                                                            <i className="bg-success"/>

                                                                            <span className="status">
                                          Completed
                                        </span>
                                                                        </>
                                                                    ) : null}
                                                                </Badge>
                                                            </td>
                                                            <td>{item.name}</td>
                                                            <td>{item.breezeway_task_id}</td>
                                                            <td>{item.finished_at}</td>
                                                            {isValidReport(item.finished_at) && (
                                                                <td className="text-center">
                                    <span>
                                      <a href={report}>View report</a>
                                    </span>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <td>You have not had any tasks yet...</td>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0 pagi"
                                                    listClassName="justify-content-end mb-0"
                                                    onClick={(e) => onClickPage(e)}
                                                >
                                                    <PaginationItem
                                                        style={{
                                                            visibility:
                                                                currentPage == "1" ? "hidden" : "visible",
                                                        }}
                                                    >
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) => onPrevious(e)}
                                                        >
                                                            <i className="fas fa-angle-left"/>
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>

                                                    <>
                                                        {amountPage.map((numPage) => {
                                                            if (
                                                                (currentPage == 1
                                                                    ? numPage <= currentPage + 4
                                                                    : numPage <= currentPage + 2 ||
                                                                    currentPage == 2
                                                                        ? numPage <= currentPage + 3
                                                                        : numPage <= currentPage + 2) &&
                                                                (currentPage == pagesData
                                                                    ? numPage >= currentPage - 4
                                                                    : numPage >= currentPage - 2 ||
                                                                    currentPage == pagesData - 1
                                                                        ? numPage >= currentPage - 3
                                                                        : numPage >= currentPage - 2)
                                                            ) {
                                                                return (
                                                                    <PaginationItem
                                                                        className={{
                                                                            active: numPage == currentPage,
                                                                        }}
                                                                    >
                                                                        <PaginationLink href="#pablo">
                                                                            {numPage}
                                                                        </PaginationLink>
                                                                    </PaginationItem>
                                                                );
                                                            }
                                                        })}
                                                    </>
                                                    <PaginationItem
                                                        style={{
                                                            visibility:
                                                                currentPage == pagesData ? "hidden" : "visible",
                                                        }}
                                                    >
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) => onNext(e)}
                                                        >
                                                            <i className="fas fa-angle-right"/>
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>
                                        </CardFooter>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    tasksData: state.propertyCare.propertyCares,
    reportUrl: state.propertyCare.propertyCareUrl,
    pagesData: state.propertyCare.pages,
});

export default connect(mapStateToProps, {
    getAllPropertyCares,
    getUrlPropertyCare,
    clearPropertyCareUrl,
})(PropertyCare);
