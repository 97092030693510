import { useState } from "react";
import { Alert } from "reactstrap";

const Popup = ({ bodyText}) => {
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);

    return (
        <div className="p-2 my-3"
            style={{
                width: '22%',
                position: 'fixed',
                left: 50,
                bottom: 0,
                zIndex: 9999
            }}>
            <Alert color='danger' isOpen={visible} toggle={onDismiss}>
                <h4 className="alert-heading">
                    Pay attention!
                </h4>
                {bodyText}
            </Alert>
        </div>
    )
}

export default Popup;