/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "mapbox-gl/dist/mapbox-gl.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import ConfirmedReservation from "layouts/ConfirmedReservation";
import PrivacyPolicy from "layouts/PrivacyPolicy";
import AdminLayout from "layouts/Admin.js";
import OnboardingLayout from "layouts/Onboarding.js";
import OnboardingOwner from "layouts/OnboardingOwner";
import LandingLayout from "layouts/Landing.js";
import Terms from "layouts/Terms";
import ManagementTerms from "layouts/ManagementTerms";

import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUser } from "app/actions";
import { intercomInitWidget } from "./libs/intercome";


const App = ({user}) => {
    const dispatch = useDispatch();
    const realUser = useSelector(state => state.user.userData);

    useEffect(() => {
        dispatch(getUser());
    }, []);


    useEffect(() => {
        if (realUser.email) {
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "y2sd6rqw",
            user_id: realUser.email,
          };
          intercomInitWidget();
        }
    }, [user, realUser]);


  return (
      <Switch>
        <Route path="/owner" render={(props) => <AdminLayout {...props} />} />
        <Route path="/onboarding" render={(props) => <OnboardingLayout {...props} />} />
        <Route path="/profile" render={(props) => <OnboardingOwner {...props} />} />
        <Route path="/confirmed-info" render={(props) => <ConfirmedReservation {...props} />} />
        <Route path="/terms-of-service" render={(props) => <Terms {...props} />} />
        <Route path="/terms" render={(props) => <ManagementTerms {...props} />} />
        <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
        <Route path="/" render={(props) => <LandingLayout {...props} />} />

        <Redirect from="*" to="/login" />
      </Switch>
  )
}

const mapStateToProps = state => ({
  user: state.authentication.user
});

export default connect(mapStateToProps, { getUser })(App);
