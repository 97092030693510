/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getOwnerProfile } from "app/actions";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useEffect } from "react";


function OnboardingHeader({ name, step, children }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOwnerProfile());
  }, []);

  const submitHandler = () => {
    history.push('/owner/dashboard');
  };

  return (
    <>
      <div className={step === 4 ? "header onboarding-header full" : step === 2 ? "header pb-1 onboarding-header" : "header pb-8 onboarding-header"}>
        <Container fluid>
          <Row className="py-4">
            <Col lg="4" md="5">
              <h2 className="h2 text-white d-inline-block mb-2 mt-1">
                {name}
              </h2>
              <p className="text-white max-width-425">{children}</p>
            </Col>
            <Col lg="8" md="7">
              <div className="onboarding-steps">
                <Row className="justify-content-between align-items-center">
                  <Col lg="3" md="3" className="text-center">
                    <div className={step === 1 ? "step-item selected" : "step-item checked"}><span >1</span></div>
                  </Col>
                  <Col lg="3" md="3" className="text-center">
                    <div className={step === 2 ? 'step-item selected' : step > 2 ? 'step-item checked' : 'step-item'}><span >2</span></div>
                  </Col>
                  <Col lg="3" md="3" className="text-center">
                    <div className={step === 3 ? 'step-item selected' : step > 3 ? 'step-item checked' : 'step-item'}><span >3</span></div>
                  </Col>
                  <Col lg="3" md="3" className="text-center">
                    <div className={step === 4 ? 'step-item checked' : 'step-item'}><span >4</span></div>
                  </Col>
                </Row>
                <Row className="justify-content-between align-items-center">
                  <Col lg="3" md="3" className="text-center">
                    <p>Complete your<br /> owner profile</p>
                  </Col>
                  <Col lg="3" md="3" className="text-center">
                    <p>Tell us about your<br /> first property</p>
                    <span>We can add more later</span>
                  </Col>
                  <Col lg="3" md="3" className="text-center">
                    <p>Hoste prepares your<br /> property for listing</p>
                    <span>1 - 2 weeks</span>
                  </Col>
                  <Col lg="3" md="3" className="text-center">
                    <p>List your<br /> property live!</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    {step === 4 ? (<>
                      <Row>
                        <Col className="mt-5 mb-3">
                          <Button className="primary-btn" color="info" type="button" onClick={submitHandler}>
                            View My Dashboard
                          </Button>
                        </Col>
                      </Row>
                    </>) : null}
                  </Col>
                </Row>
              </div>
              {/* }  */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

OnboardingHeader.propTypes = {
  name: PropTypes.string,
  step: PropTypes.number,
  children: PropTypes.string,
};

const mapStateToProps = state => ({
  ownerProfile: state.user.ownerProfile,
});

export default connect(mapStateToProps, { getOwnerProfile })(OnboardingHeader);

