/* eslint-disable no-fallthrough */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { startOfDay, format, isSameDay, isAfter, isBefore, endOfDay } from 'date-fns';
import moment from "moment";

class DayCell extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hover: false,
      active: false,
      hoveredDay: ''
    };
  }

  checkIfOneDayReservation(day) {
    const { predefinedRanges } = this.props;

    return predefinedRanges.find(range => {
      if (moment(day).isSame(range.startDate)
        && moment(day).isSame(range.endDate)) {
        return range;
      }
    });
  };

  checkIfBetween(date) {
    const { predefinedRanges } = this.props;

    return predefinedRanges.find(range => {
      if (moment(date).isBetween(range.startDate, range.endDate, 'days', '()')) {
        return range;
      }
    });
  };

  checkCheckIn = (day) => {
    const { predefinedRanges } = this.props;

    return predefinedRanges.find(range => {
      if (moment(day).isSame(range.startDate)) {
        return range;
      }
    })
  };

  checkCheckOut = (day) => {
    const { predefinedRanges } = this.props;

    return predefinedRanges.find(range => {
      if (moment(day).isSame(range.endDate)) {
        return range;
      }
    })
  };

  betweenNumbers(x, min, max) {
    return x >= min && x <= max;
  };

  handleKeyEvent = event => {
    const { day, onMouseDown, onMouseUp } = this.props;
    if ([13 /* space */, 32 /* enter */].includes(event.keyCode)) {
      if (event.type === 'keydown') onMouseDown(day);
      else onMouseUp(day);
    }
  };

  handleMouseEvent = event => {
    const offsetHeight = event.nativeEvent.composedPath()[2].offsetHeight
    const offsetWidth = event.nativeEvent.composedPath()[2].offsetWidth

    const { day, disabled, onPreviewChange, onMouseEnter, onMouseDown, onMouseUp, handlePredefinedRange } = this.props;

    if (event.type === 'mousedown' || event.type === 'mouseup') {
      // debugger;
      if (this.checkIfOneDayReservation(day)) {
        const reservation = this.checkIfOneDayReservation(day);

        if (reservation) {
          handlePredefinedRange(reservation);
          return;
        }
      } else if (this.checkIfBetween(day)) {
        const reservation = this.checkIfBetween(day);

        if (reservation) {
          handlePredefinedRange(reservation);
          return;
        }
      }

      if (this.betweenNumbers(event.nativeEvent.offsetY, 0, offsetHeight * 0.2)
        && this.betweenNumbers(event.nativeEvent.offsetX, offsetWidth * 0.7, offsetWidth) && !this.checkIfBetween(day)) {
        const reservation = this.checkCheckIn(day);

        if (reservation) {
          handlePredefinedRange(reservation);
          return;
        }
      }

      if (this.betweenNumbers(event.nativeEvent.offsetY, 0, offsetHeight)
        && this.betweenNumbers(event.nativeEvent.offsetX, 0, offsetWidth * 0.3) && !this.checkIfBetween(day)) {
        const reservation = this.checkCheckOut(day);

        if (reservation) {
          handlePredefinedRange(reservation);
          return;
        }
      }
    }

    const stateChanges = {};
    if (disabled) {
      onPreviewChange();
      return;
    }

    switch (event.type) {
      case 'mouseenter':
        onMouseEnter(day);
        onPreviewChange(day);
        stateChanges.hover = true;
        stateChanges.hoveredDay = day;
        break;
      case 'blur':
      case 'mouseleave':
        stateChanges.hover = false;
        break;
      case 'mousedown':
        stateChanges.active = true;
        onMouseDown(day);
        break;
      case 'mouseup':
        event.stopPropagation();
        stateChanges.active = false;
        onMouseUp(day);
        break;
      case 'focus':
        onPreviewChange(day);
        break;
      default:
        break;
    }
    if (Object.keys(stateChanges).length) {
      this.setState(stateChanges);
    }

  };
  getClassNames = () => {
    const {
      isPassive,
      isToday,
      isWeekend,
      isStartOfWeek,
      isEndOfWeek,
      isStartOfMonth,
      isEndOfMonth,
      disabled,
      styles,
    } = this.props;

    return classnames(styles.day, {
      [styles.dayPassive]: isPassive,
      [styles.dayDisabled]: disabled,
      [styles.dayToday]: isToday,
      [styles.dayWeekend]: isWeekend,
      [styles.dayStartOfWeek]: isStartOfWeek,
      [styles.dayEndOfWeek]: isEndOfWeek,
      [styles.dayStartOfMonth]: isStartOfMonth,
      [styles.dayEndOfMonth]: isEndOfMonth,
      [styles.dayHovered]: this.state.hover,
      [styles.dayActive]: this.state.active,
    });
  };
  renderPreviewPlaceholder = () => {
    const { preview, day, styles } = this.props;

    if (!preview) return null;
    if (!preview.startDate || !preview.endDate) return null;

    const startDate = preview.startDate ? endOfDay(preview.startDate) : null;
    const endDate = preview.endDate ? startOfDay(preview.endDate) : null;
    const isInRange =
      (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate));
    const isStartEdge = !isInRange && isSameDay(day, startDate);
    const isEndEdge = !isInRange && isSameDay(day, endDate);

    return (
      <span
        className={classnames({
          [styles.dayStartPreview]: isStartEdge,
          [styles.dayInPreview]: isInRange,
          [styles.dayEndPreview]: isEndEdge
        })}
        style={{ color: preview.color }}
      />
    );
  };
  renderSelectionPlaceholders = () => {
    const { styles, ranges, day, predefinedRanges } = this.props;

    if (this.props.displayMode === 'date') {
      let isSelected = isSameDay(this.props.day, this.props.date);
      return isSelected ? (
        <span className={styles.selected} style={{ color: this.props.color }} />
      ) : null;
    }

    const inRanges = ranges[0].startDate && ranges[0].endDate && ranges.reduce((result, range) => {
      let startDate = range.startDate;
      let endDate = range.endDate;
      if (startDate && endDate && isBefore(endDate, startDate)) {
        [startDate, endDate] = [endDate, startDate];
      }
      startDate = startDate ? endOfDay(startDate) : null;
      endDate = endDate ? startOfDay(endDate) : null;
      const isInRange =
        (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate));
      const isStartEdge = !isInRange && isSameDay(day, startDate);
      const isEndEdge = !isInRange && isSameDay(day, endDate);
      if (isInRange || isStartEdge || isEndEdge) {
        return [
          ...result,
          {
            isStartEdge,
            isEndEdge: isEndEdge,
            isInRange,
            ...range,
          },
        ];
      }
      return result;
    }, []);


    let inRangesPredefinedRanges;
    if (predefinedRanges) {
      inRangesPredefinedRanges = predefinedRanges.reduce((result, range) => {
        let startDate = range.startDate;
        // range.endDate.setDate(range.endDate.getDate() + 1)
        let endDate = range.endDate;
        // console.log('startDate', startDate)
        // console.log('endDate',  range.endDate)
        if (startDate && endDate && isBefore(endDate, startDate)) {
          [startDate, endDate] = [endDate, startDate];
        }
        startDate = startDate ? endOfDay(startDate) : null;
        endDate = endDate ? startOfDay(endDate) : null;
        const isInRange =
          (!startDate || isAfter(day, startDate)) && (!endDate || isBefore(day, endDate));
        const isStartEdge = !isInRange && isSameDay(day, startDate);
        const isEndEdge = !isInRange && isSameDay(day, endDate);
        if (isInRange || isStartEdge || isEndEdge) {
          return [
            ...result,
            {
              isStartEdge,
              isEndEdge: isEndEdge,
              isInRange,
              ...range,
            },
          ];
        }
        return result;
      }, []);
    }

    const generateKey = () => {
      return (Math.random() + 1).toString(36).substring(2);
    };

    if (inRangesPredefinedRanges) {
      const predefinedRanges = inRangesPredefinedRanges.map((range) => (
        <span
          key={generateKey()}
          className={classnames({
            [styles.startEdge]: range.isStartEdge,
            [styles.endEdge]: range.isEndEdge,
            [styles.inRange]: range.isInRange,
          }, styles.predefinedRanges)}
          style={{ color: range.color || this.props.color }}
        />
      ));

      const currentRange = inRanges && inRanges.map((range) => {
        return (<span
          key={generateKey()}
          className={classnames({
            [styles.startEdge]: range.isStartEdge,
            [styles.endEdge]: range.isEndEdge,
            [styles.inRange]: range.isInRange
          })}
          style={{ color: '#3D91FF' }}
        />
        )
      });

      if (currentRange && currentRange?.length > 0) {
        return [...predefinedRanges, ...currentRange];
      } else {
        return [...predefinedRanges];
      }

    } else {
      return inRanges.map((range, i) => (
        <span
          key={generateKey()}
          className={classnames({
            [styles.startEdge]: range.isStartEdge,
            [styles.endEdge]: range.isEndEdge,
            [styles.inRange]: range.isInRange,
          })}
          style={{ color: range.color || this.props.color }}
        />
      ));
    }
  };

  render() {
    const { dayContentRenderer } = this.props;
    

    return (
      <button
        type="button"
        onMouseEnter={this.handleMouseEvent}
        onMouseLeave={this.handleMouseEvent}
        onFocus={this.handleMouseEvent}
        onMouseDown={this.handleMouseEvent}
        onMouseUp={this.handleMouseEvent}
        onBlur={this.handleMouseEvent}
        onPauseCapture={this.handleMouseEvent}
        onKeyDown={this.handleKeyEvent}
        onKeyUp={this.handleKeyEvent}
        className={this.getClassNames(this.props.styles)}
        {...(this.props.disabled || this.props.isPassive ? { tabIndex: -1 } : {})}
        style={{ color: this.props.color}}>
        {this.renderSelectionPlaceholders()}
        {this.renderPreviewPlaceholder()}
        <span className={this.props.styles.dayNumber}>
          {
            dayContentRenderer?.(this.props.day) ||
            <span>{format(this.props.day, this.props.dayDisplayFormat)}</span>
          }
        </span>
      </button>
    );
  }
}

DayCell.defaultProps = {};

export const rangeShape = PropTypes.shape({
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  color: PropTypes.string,
  key: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  showDateDisplay: PropTypes.bool,
});

DayCell.propTypes = {
  day: PropTypes.object.isRequired,
  dayDisplayFormat: PropTypes.string,
  date: PropTypes.object,
  ranges: PropTypes.arrayOf(rangeShape),
  preview: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    color: PropTypes.string,
  }),
  onPreviewChange: PropTypes.func,
  previewColor: PropTypes.string,
  disabled: PropTypes.bool,
  isPassive: PropTypes.bool,
  isToday: PropTypes.bool,
  isWeekend: PropTypes.bool,
  isStartOfWeek: PropTypes.bool,
  isEndOfWeek: PropTypes.bool,
  isStartOfMonth: PropTypes.bool,
  isEndOfMonth: PropTypes.bool,
  color: PropTypes.string,
  displayMode: PropTypes.oneOf(['dateRange', 'date']),
  styles: PropTypes.object,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  dayContentRenderer: PropTypes.func,
};

export default DayCell;


 // if (event.nativeEvent.offsetX > 62 && event.nativeEvent.offsetX < 95
      //   && event.nativeEvent.offsetY > 0 && event.nativeEvent.offsetY < 16 && !this.checkIfBetween(day)) {
      //   const reservation = this.checkCheckIn(day);

      //   if (reservation) {
      //     handlePredefinedRange(reservation);
      //     return;
      //   }
      // }

      // // check if click was made on color line on check-out day
      // if (event.nativeEvent.offsetX > 0 && event.nativeEvent.offsetX < 36
      //   && event.nativeEvent.offsetY > 0 && event.nativeEvent.offsetY < 14 && !this.checkIfBetween(day)) {
      //   const reservation = this.checkCheckOut(day);

      //   if (reservation) {
      //     handlePredefinedRange(reservation);
      //     return;
      //   }
      // }