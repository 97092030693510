/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import { getPropertyById, getFinancialsByPropertyId } from "app/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";


function OwnerHeader({ propertyData, padding, incomes }) {
  const [profits, setProfits] = useState({
    montly_profit: 0,
    total_profit: 0,
    year_income: 0,
    occupancy_in_percent: 0,
  });
  const [property, setProperty] = useState();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let month = months[d.getMonth()];
  let year = d.getFullYear();
  useEffect(() => {
    const id = parseInt(pathname.split("/")[3]);
    localStorage.setItem("property_id", id);
    dispatch(getPropertyById(id));
    dispatch(getFinancialsByPropertyId(id));
  }, []);


  useEffect(() => {
    propertyData && setProperty(propertyData);
    incomes && setProfits(incomes);
  }, [propertyData, incomes]);
 
  return (
    <>
      <Container
        fluid
        className={
          padding !== ""
            ? "owner-header-wrap " + padding
            : "owner-header-wrap pb-3"
        }
      >
        {property  && Object.keys(property).length && (
          <Card className="owner-header">
            {!property.hoste_services && <Badge className="badge-sm" color="danger">
              {property.hoste_services ? "Active" : "Inactive"}
            </Badge>}
            <CardBody className="pl-0 pr-0 pt-3">
              <Row className="d-flex justify-content-start pl-3 pr-3 align-items-center">
                <Col className="entity-name">
                  <h5>{property?.entity_name}</h5>
                </Col>
                <Col>
                  <h6>ADDRESS</h6>
                  <p>
                    {property.street_address} <br /> {property.state},{" "}
                    {property.city}, {property.postal_code}, {property.country}
                  </p>
                </Col>
                <Col>
                  <h6 id="tooltip982655500">
                    {/* NET INCOME({month})    */}
                    {year} OCCUPANCY
                    <img
                      src="https://ownerportaldev.s3.amazonaws.com/Files/question-circle.svg"
                      style={{ width: "10px", marginLeft: "0.2rem" }}
                    ></img>
                  </h6>
                  <UncontrolledTooltip
                    // placement={"bottom"}
                    delay={0}
                    trigger="hover focus"
                    target="tooltip982655500"
                    style={{ maxWidth: "30rem" }}
                  >
                    This value represents the total number of guest booked days
                    divided by the number of days in the year minus any
                    homeowner reservations or other blocks. Note: This value
                    will change due to new reservations, cancellations, refunds,
                    or alterations.
                  </UncontrolledTooltip>
                  {/* <p>${profits.montly_profit.toFixed(0)}</p>
                  <p>night by guests {profits.total_year_persent}</p> */}
                  <p>{Math.round(profits.occupancy_in_percent)} %</p>
                </Col>
                <Col>
                  <h6 id="tooltip982655154">
                    {year} ESTIMATED NET INCOME
                    <img
                      src="https://ownerportaldev.s3.amazonaws.com/Files/question-circle.svg"
                      style={{ width: "10px", marginLeft: "0.2rem" }}
                    ></img>
                  </h6>
                  <UncontrolledTooltip
                    // placement={"bottom"}
                    delay={0}
                    trigger="hover focus"
                    target="tooltip982655154"
                    style={{ maxWidth: "30rem" }}
                  >
                    This value represents the estimated net income total of all
                    reservations that checked in for the current year. Note:
                    This value will change due to new reservations,
                    cancellations, refunds, or alterations.
                  </UncontrolledTooltip>
                  {/* <p>${profits.total_profit.toFixed(0)}</p> */}
                  <p>${profits.year_income.toFixed(0)}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  propertyData: state.property.property,
  incomes: state.financials.incomes?.property,
});

export default connect(mapStateToProps, {
  getPropertyById,
  getFinancialsByPropertyId,
})(OwnerHeader);
