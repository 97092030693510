/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useRef} from "react";
import {Card, Container, Row} from "reactstrap";
import useGeolocation from 'react-hook-geolocation'

const MapCustom = () => {
    const geolocation = useGeolocation()
    const mapRef = useRef(null);

    useEffect(() => {
        let google = window.google;
        let map = mapRef.current;

        const myLatlng = new google.maps.LatLng(geolocation.latitude, geolocation.longitude);
        const mapOptions = {
            zoom: 14,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
            styles: [
                {
                    featureType: "administrative",
                    elementType: "labels.text.fill",
                    stylers: [{color: "#444444"}],
                },
                {
                    featureType: "landscape",
                    elementType: "all",
                    stylers: [{color: "#f2f2f2"}],
                },
                {
                    featureType: "poi",
                    elementType: "all",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "road",
                    elementType: "all",
                    stylers: [{saturation: -100}, {lightness: 45}],
                },
                {
                    featureType: "road.highway",
                    elementType: "all",
                    stylers: [{visibility: "simplified"}],
                },
                {
                    featureType: "road.arterial",
                    elementType: "labels.icon",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "transit",
                    elementType: "all",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "water",
                    elementType: "all",
                    stylers: [{color: "#5e72e4"}, {visibility: "on"}],
                },
            ],
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Position",
        });

        const contentString =
            '<div className="info-window-content"><h2>Current Postion</h2>' +
            "<p>Position description</p></div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
        });
    }, []);
    return (
        <div
            style={{height: `600px`}}
            className="map-canvas"
            id="map-custom"
            ref={mapRef}
        />
    );
};

// const MapDefault = () => {
//   const mapRef = React.useRef(null);
//   React.useEffect(() => {
//     let google = window.google;
//     let map = mapRef.current;
//     let lat = "40.748817";
//     let lng = "-73.985428";
//     const myLatlng = new google.maps.LatLng(lat, lng);
//     const mapOptions = {
//       zoom: 13,
//       center: myLatlng,
//       scrollwheel: false,
//       zoomControl: true,
//     };

//     map = new google.maps.Map(map, mapOptions);

//     const marker = new google.maps.Marker({
//       position: myLatlng,
//       map: map,
//       animation: google.maps.Animation.DROP,
//       title: "Argon Dashboard PRO React!",
//     });

//     const contentString =
//       '<div className="info-window-content"><h2>Argon Dashboard PRO React</h2>' +
//       "<p>A premium Admin for Reactstrap, Bootstrap, React, and React Hooks.</p></div>";

//     const infowindow = new google.maps.InfoWindow({
//       content: contentString,
//     });

//     google.maps.event.addListener(marker, "click", function () {
//       infowindow.open(map, marker);
//     });
//   }, []);
//   return (
//     <div
//       style={{ height: `600px` }}
//       className="map-canvas"
//       id="map-default"
//       ref={mapRef}
//     />
//   );
// };

function Map() {
    return (
        <>
            <div className="dashboard-header">
            </div>
            <Container className="mt-6" fluid>
                <Row>
                    <div className="col">
                        <Card className="border-0">
                            <MapCustom/>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Map;
