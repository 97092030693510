import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import {
  updateMaintenanceBooking,
  getAllReservationsByPropertyId,
  createMaintenanceBooking,
} from "app/actions";
import { reservationConstants } from "app/constants";

import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import moment from "moment";
import classNames from "classnames";

import FormInput from "components/Forms/FormInput";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Row,
} from "reactstrap";

const MaintenanceForm = ({
  formData,
  setModal,
  isTheSameRange,
  reset,
  isReadonly,
  setIsReadonly,
  checkIfOverlapped,
  predefinedRanges,
  setLoading,
  setRanges,
  setFormData,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const propertyId = parseInt(pathname.split("/")[3]);

  const today = moment();
  const MaintenanceFormSchema = Yup.object().shape({
    startDate: Yup.date()
      .required("This field is required")
      .min(today, "You can’t book a date that is in the past"),
    endDate: Yup.date()
      .required("This field is required")
      .min(today, "You can’t book a date that is in the past")
      .when("startDate", (startDate, schema) => {
        return schema.test({
          test: function (endDate) {
            const { startDate } = this.parent;
            return moment(endDate).isSameOrAfter(startDate);
          },
          message: "Start date must be on or after the end date",
        });
      })
      .when("startDate", {
        is: (val) => val,
        then: Yup.date("Incorrect date format")
          .when("startDate", (startDate, schema) => {
            return schema.test({
              test: (endDate) => {
                return (
                  !!endDate &&
                  !checkIfOverlapped({ startDate, endDate }, predefinedRanges)
                    .length
                );
              },
              message: "That date range overlaps an existing reservation",
            });
          })
          .when("startDate", (startDate, schema) => {
            return schema.test({
              test: (endDate) => {
                return (
                  !!endDate &&
                  moment(endDate).diff(moment(startDate), "days") < 29
                );
              },
              message:
                "Please contact the Hoste team for bookings stays of 29 days or longer",
            });
          }),
      }),
    notes: Yup.string().required("This field is required"),
  });

  const isDisabled = (errors) => {
    return Object.values(errors).some((error) => !!error);
  };

  const onSubmit = (data) => {
    const reservation = {
      ...data,
      propertyId,
    };
    const status = reservationConstants.status.unavailable;

    dispatch(createMaintenanceBooking(reservation, status));
  };

  const onUpdate = (data) => {
    const reservation = {
      ...data,
      propertyId,
    };
    const status = reservationConstants.status.unavailable;

    dispatch(updateMaintenanceBooking(reservation, status));
  };

  return (
    <Card>
      {isTheSameRange && (
        <CardHeader className="pl-3 pr-1 pt-3 pb-3">
          <Row>
            <Col md="6">
              <h3>My Block</h3>
            </Col>
            <Col className="text-right">
              <Button
                outline
                className="edit-button"
                type="button"
                onClick={() => {
                  setIsReadonly(!isReadonly);
                  !isReadonly && dispatch(getAllReservationsByPropertyId);
                }}
              >
                {isReadonly ? "Edit" : "Back"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
      )}

      {isReadonly ? (
        <Form
          className="reservation-form pl-4 pt-2 pr-2"
          style={{ backgroundColor: "#f8f9fe" }}
        >
          <Row className="bg-custom">
            <Col md="6" className="pl-3 pr-1">
              <FormGroup>
                <Label for="startDate">Start Date</Label>
                <p className="mb-0 pt-1 mt-2">{formData.startDate}</p>
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label for="endDate">End Date</Label>
                <p className="mb-0 pt-1 mt-2">{formData.endDate}</p>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup className="mt--2">
                <Label for="notes">Type of maintenance</Label>
                <p className="mb-0 pt-1 mt-2">{formData.notes}</p>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      ) : (
        <Formik
          initialValues={formData}
          enableReinitialize
          validateOnMount
          validationSchema={MaintenanceFormSchema}
          onSubmit={async (values) => {
            setLoading(true);

            const isId = values.guestyReservationId;
            isId ? onUpdate(values) : onSubmit(values);

            reset();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <FormikForm
              onSubmit={handleSubmit}
              className="reservation-form pl-4 pt-2 pr-3"
              style={{ backgroundColor: "#f8f9fe" }}
            >
              <Row className="bg-custom">
                <Col md="6">
                  <FormInput
                    id="startDate-maintenance"
                    className="reservation-input"
                    label="Start Date"
                    placeholder="MM/DD/YYYY"
                    name="startDate"
                    type="date"
                    value={values.startDate}
                    onChange={(event) => {
                      if (event.target.value) {
                        const selectedStartDate = moment(
                          event.target.value
                        ).toDate();
                        const selectedEndDate = values.endDate
                          ? moment(values.endDate).toDate()
                          : null;

                        if (
                          !selectedEndDate ||
                          selectedStartDate <= selectedEndDate
                        ) {
                          setRanges([
                            {
                              startDate: selectedStartDate,
                              endDate: selectedEndDate,
                              key: "Owner Reservation",
                              color: "#3D91FF",
                            },
                          ]);

                          setFormData({
                            ...formData,
                            startDate:
                              moment(selectedStartDate).format("YYYY-MM-DD"),
                            endDate: selectedEndDate
                              ? moment(selectedEndDate).format("YYYY-MM-DD")
                              : "",
                          });
                        }
                      }

                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    invalid={errors.startDate || errors.endDate}
                  />
                </Col>

                <Col md="6">
                  <FormInput
                    id="endDate-maintenance"
                    className="reservation-input"
                    label="End Date"
                    placeholder="MM/DD/YYYY"
                    name="endDate"
                    type="date"
                    value={values.endDate}
                    onChange={(event) => {
                      if (event.target.value) {
                        const selectedEndDate = moment(
                          event.target.value
                        ).toDate();
                        const selectedStartDate = values.startDate
                          ? moment(values.startDate).toDate()
                          : null;

                        if (
                          !selectedStartDate ||
                          selectedStartDate <= selectedEndDate
                        ) {
                          setRanges([
                            {
                              startDate: selectedStartDate,
                              endDate: selectedEndDate,
                              key: "Owner Reservation",
                              color: "#3D91FF",
                            },
                          ]);

                          setFormData({
                            ...formData,
                            startDate: selectedStartDate
                              ? moment(selectedStartDate).format("YYYY-MM-DD")
                              : "",
                            endDate:
                              moment(selectedEndDate).format("YYYY-MM-DD"),
                          });
                        }
                      }

                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    invalid={errors.startDate || errors.endDate}
                  />
                </Col>

                {(errors.startDate ||
                  touched.startDate ||
                  errors.endDate ||
                  touched.endDate) && (
                  <FormText className="helper-message mt--3 mb-2 ml-3 mr-2">
                    {errors.endDate ? errors.endDate : errors.startDate}
                  </FormText>
                )}
              </Row>

              <Row>
                <Col md="12">
                  <FormInput
                    id="hostenote"
                    style={{ height: "7rem" }}
                    label="Describe the maintenance work below:"
                    name="notes"
                    placeholder="Examples:
                                            &#10;- Installing new kitchen lights
                                            &#10;- Renovating bathroom
                                            &#10;- Updating kitchen floors"
                    type="textarea"
                    rows={3}
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors.notes && touched.notes}
                  />

                  {errors.notes && touched.notes && (
                    <FormText className="helper-message mt--3 mb-2">
                      {errors.notes}
                    </FormText>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  {isTheSameRange ? (
                    <>
                      <Button
                        className={classNames("secondary-btn full-width")}
                        color="info"
                        type="submit"
                        disabled={isDisabled(errors)}
                      >
                        Update
                      </Button>

                      <Button
                        className="cancel-btn full-width text-center mt-3"
                        onClick={() => setModal(true)}
                      >
                        Cancel Reservation
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={classNames("secondary-btn full-width")}
                      color="info"
                      type="submit"
                      disabled={isDisabled(errors)}
                    >
                      {" "}
                      Reserve
                    </Button>
                  )}
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      )}
    </Card>
  );
};

const mapDispatchToProps = {
  updateMaintenanceBooking,
  createMaintenanceBooking,
  getAllReservationsByPropertyId,
};
export default connect(null, mapDispatchToProps)(MaintenanceForm);
