import { useEffect } from "react";

import {
    Col,
    FormGroup,
    Label,
    Input,
    FormText,
    Button
} from "reactstrap";
import { Formik, Form } from "formik";

import FormInput from "components/Forms/FormInput";
import CustomDropdown from "components/Forms/CustomDropdown";
import { DocumentFormSchema } from "validation/schemas/DocumentFormSchema";
import { ALL_DOC_TYPES_DICT, ALLOWED_OWNER_DOC_TYPES } from "app/constants";




export const UploadDocumentForm = ({ formData, handleSubmit, setIsOpen, isOpen, setFormData }) => {
    useEffect(() => {
        checkAllowedDocType(formData);
    }, []);

    const checkAllowedDocType = (data) => {
        const defaultType = ALL_DOC_TYPES_DICT.permit;

        if (data.type in ALLOWED_OWNER_DOC_TYPES) return;
        setFormData({...data, type: defaultType});
    };

    const onClose = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Formik
            initialValues={formData}
            enableReinitialize={true}
            validationSchema={DocumentFormSchema}
            onSubmit={async (values) => {
                handleSubmit(values);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
            }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="section-body">
                        <FormGroup row className="form-group-field">
                            <Label for="name" sm={4}>Name</Label>
                            <Col className="mt--4">
                                <FormInput
                                    id="name"
                                    name="name"
                                    placeholder="Name..."
                                    type="text"
                                    rows={3}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={errors.name && touched.name}
                                />

                                {errors.name && touched.name
                                    && <FormText className="helper-message mt--3 mb-2">{errors.name}</FormText>}

                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="type" sm={4}>Type</Label>
                            <Col>
                                <CustomDropdown
                                    id="type"
                                    name="type"
                                    styles="custom-dropdown"
                                    options={ALLOWED_OWNER_DOC_TYPES}
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={errors.type && touched.type}
                                />

                                {errors.type && touched.type
                                    && <FormText className="helper-message mt--3 mb-2">{errors.type}</FormText>}
                            </Col>
                        </FormGroup>

                        <FormGroup row className="form-group-field">
                            <Label for="hostenote" sm={4}>Notes</Label>
                            <Col>
                                <FormInput
                                    className="mt--4"
                                    id="hostenote"
                                    name="notes"
                                    placeholder="Leave a short description of the document..."
                                    type="textarea"
                                    rows={2}
                                    value={values.notes}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={errors.notes && touched.notes}
                                />

                                {errors.notes && touched.notes
                                    && <FormText className="helper-message mt--3 mb-2">{errors.notes}</FormText>}
                            </Col>
                        </FormGroup>

                        <FormGroup row className="form-group-field">
                            <Label for="file_link" sm={4}>Choose a file</Label>
                            <Col>
                                <Input
                                    id="file_link"
                                    name="file_link"
                                    type="file"
                                    className="file ml-2 mt-2"
                                    src={values.file_link}
                                    onChange={(e) => {
                                        let files = e.target.files[0];

                                        if (files) {
                                            let reader = new FileReader();
                                
                                            reader.readAsDataURL(files);
                                            let doc_ref = document.getElementById("file_link");
                                
                                            reader.onload = (e) => {
                                                doc_ref.src = e.target.result;
                                                setFieldValue("file_link", e.target.result);
                                            }
                                        }
                                        
                                    }}
                                    onBlur={handleBlur}
                                    invalid={errors.file_link}
                                />

                                {errors.file_link
                                    && <FormText className="helper-message">{errors.file_link}</FormText>}
                            </Col>
                        </FormGroup>
                    </div>

                    <div className="text-right mt-3">
                        <Button color="primary" type="submit">Upload</Button>
                        <Button color="default" type="button" onClick={onClose}>Close</Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

