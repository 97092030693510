export const documentConstants = {
  GETALL_DOCUMENTS: "GETALL_DOCUMENTS",
  GET_DOCUMENTS_BY_DATE: "GET_DOCUMENTS_BY_DATE",
  GET_SELECTED_DOCUMENT: "GET_SELECTED_DOCUMENT",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  SET_PROGRESS_BAR: "SET_PROGRESS_BAR",
  CLEAR_PROGRESS_BAR: "CLEAR_PROGRESS_BAR",
  CLEAR: "CLEAR",
  
  groupedOptions: [
    {
      group: "Document",
      header: "Document Name",
      accessor: "name",
      descriptionType: "string",
      isEmptyTitle: false,
      isShown: true,
      isSorted: false
    },
    {
      group: "Document",
      header: "Type",
      accessor: "type",
      descriptionType: "string",
      isEmptyTitle: false,
      isShown: true,
      isSorted: false
    },
    {
      group: "Document",
      header: "Notes",
      accessor: "notes",
      descriptionType: "string",
      isEmptyTitle: false,
      isShown: true,
      isSorted: false
    }
  ],

  
  
  ALL_TYPES: [
    "Monthly statements",
    "Annual statements",
    // "1099's",
    "Permit",
    "Insurance",
    "Home warranty",
    "Owner Agreement",
  ],
  ALLOWED_OWNER_TYPES: [
    "Permit",
    "Insurance",
    "Home warranty",
  ]
};


export const ALL_DOC_TYPES_DICT = {
  monthlyStatements: "Monthly statements",
  annualStatements: "Annual statements",
  // form1099: "1099's",
  permit: "Permit",
  insurance: "Insurance",
  warranty: "Home warranty",
  ownerAgreement: "Owner Agreement"
};

export const ALL_DOC_TYPES = [
  "Monthly statements",
  "Annual statements",
  // "1099's",
  "Permit",
  "Insurance",
  "Home warranty",
  "Owner Agreement",
];

export const ALLOWED_OWNER_DOC_TYPES = [
  "Permit",
  "Insurance",
  "Home warranty",
];

export const YEARLY_DOCS_TYPES = [
  "Annual statements",
  // "1099's",
  "Permit"
];



