import { propertyCareConstants, CSRFToken } from "app/constants";


export const getAllPropertyCares = (propertyId, page, sortId, finish, title) => {

    return async (dispatch) => {
        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };

            const response = await fetch(process.env.REACT_APP_API_URL + `/owners/owner/property/properties-care/${propertyId}/${page}/${sortId}/${finish}/${title}`, requestOptions);

            const propertyCares = await response.json()
            if (response.ok) {
                dispatch({
                    type: propertyCareConstants.GETALL_PROPERTY_CARES,
                    payload: propertyCares,
                });
            }
        } catch (error) {
            console.error(
                "There has been a problem with your fetch operation:",
                error.message
            );
        }
    };
};

export const getUrlPropertyCare = (url1) => {
    const { key } = JSON.parse(localStorage.getItem("hoste_user"));

    return async function (dispatch) {
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRFToken": CSRFToken,
                    Authorization: "Token " + key,
                },

                body: JSON.stringify({
                    report_url: url1
                }),
            };
            const response = await fetch(process.env.REACT_APP_API_URL + `/owners/owner/property/properties-care/getUrl/`, requestOptions);
            const url = await response.json();

            if (response.ok) {
                dispatch({
                    type: propertyCareConstants.GET_PROPERTY_CARE_URL,
                    payload: url,
                });
            }
        } catch (error) {
            console.error(
                "There has been a problem with your fetch operation:",
                error.message
            );
        }
    };
};

export const clearPropertyCareUrl = () => {
    return function (dispatch) {
        dispatch({
            type: propertyCareConstants.CLEAR_PROPERTY_CARE_URL
        });
    }
};