import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { sendEmail, resetPassword } from "app/actions";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required("This field is required")
    .email("Invalid email format")
    .max(50, "Max length: 50 symbols"),
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("This field is required")
    .min(8, "Your password must contain at least 8 characters")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password")], "Passwords don't match"),
});

const ResetPassword = ({ message }) => {
  const [detail, setDetail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [identity, setIdentity] = useState({});
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (message) {
      setDetail(message);
      setLoading(false);
      message === "Password has been reset with the new password." &&
        history.push("/login");
    }
  }, [message]);

  useEffect(() => {
    const splitted = pathname.split("/");

    if (splitted.length === 6) {
      const uid = splitted[3];
      const token = splitted[4];

      setIdentity({ uid, token });
    }
  }, [pathname]);

  if (Object.keys(identity).length > 0) {
    return (
      <Container className="reset-pass-wrap" fluid>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="px-lg-5 py-lg-4">
                <div className="text-center mb-4 mt-3 logo">
                  <img alt="" src={require("assets/img/main-logo.png").default} />
                </div>
                <p className="text-center mb-2">Enter new password</p>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-4">
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  enableReinitialize={true}
                  validationSchema={PasswordSchema}
                  onSubmit={(values) => {
                    setLoading(true);
                    dispatch(
                      resetPassword({
                        uid: identity.uid,
                        token: identity.token,
                        new_password1: values.password,
                        new_password2: values.confirmPassword,
                      })
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <span>
                        Please enter your new password twice co we can verify
                        you typed in correctly.
                      </span>
                      <FormGroup
                        className={classnames("mb-3 mt-2", {
                          focused: values.password,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="New Password"
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={errors.password && touched.password}
                            maxLength={51}
                          />
                        </InputGroup>

                        {errors.password && touched.password && (
                          <small style={{ color: "red" }}>
                            {errors.password}
                          </small>
                        )}
                      </FormGroup>

                      <FormGroup
                        className={classnames("mb-3", {
                          focused: values.confirmPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Confirm password"
                            type="password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              errors.confirmPassword && touched.confirmPassword
                            }
                            maxLength={51}
                          />
                        </InputGroup>

                        {errors.confirmPassword && touched.confirmPassword && (
                          <small style={{ color: "red" }}>
                            {errors.confirmPassword}
                          </small>
                        )}
                      </FormGroup>

                      <div className="text-center mt-3">
                        <Button
                          className="secondary-btn"
                          color="info"
                          type="submit"
                          disabled={errors.password || errors.confirmPassword}
                        >
                          {isLoading ? (
                            <Spinner color="primary" />
                          ) : (
                            "Change my password"
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="reset-pass-wrap" fluid>
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader className="px-lg-5 py-lg-4">
              <div className="text-center mb-4 mt-3 logo">
                <img alt="" src={require("assets/img/main-logo.png").default} />
              </div>
              <p className="text-center mb-2">
                {!message ? "Forgotten your password?" : "Password Reset"}
              </p>
            </CardHeader>
            {message ? (
              <CardBody className="px-lg-5 py-lg-4">
                <p className="text-left mb-3" style={{ fontSize: ".9rem" }}>
                  We've emailed you instructions for setting a new password, if
                  an account exists with the email you entered. You should
                  receive them shortly.
                </p>
                <p className="text-left" style={{ fontSize: ".9rem" }}>
                  If you don't receive an email, please make sure you've entered
                  the address you registered with, and check your spam folder.
                </p>
              </CardBody>
            ) : (
              <CardBody className="px-lg-5 py-lg-4">
                <Formik
                  initialValues={{ email: "" }}
                  enableReinitialize={true}
                  validationSchema={EmailSchema}
                  onSubmit={(values) => {
                    setLoading(true);
                    dispatch(sendEmail(values.email));
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: values.email,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="reset-pass-confirm-email"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={errors.email && touched.email}
                            maxLength={51}
                          />
                        </InputGroup>

                        {errors.email && touched.email && (
                          <small style={{ color: "red" }}>{errors.email}</small>
                        )}

                        <div className="text-left mt-2">
                          <small>
                            Enter your email address below, and we'll email
                            instructions for setting a new one.
                          </small>
                        </div>
                      </FormGroup>

                      <div className="text-center mt-3">
                        <Button
                          className="secondary-btn"
                          color="info"
                          type="submit"
                          disabled={errors.email && touched.email}
                        >
                          {isLoading ? <Spinner color="primary" /> : "Reset"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  message: state.alert.message,
});

export default connect(mapStateToProps, { sendEmail, resetPassword })(
  ResetPassword
);
