import { alertConstants, documentConstants } from "app/constants";
import { getRequestOptions } from "app/helpers";
import axios from "axios";

export const getAllDocuments = (propertyId, type) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      // `/owners/owner/property/${propertyId}/filtered-documents/${JSON.stringify(typefilter)}/`,

      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/documents/${propertyId}`,
        requestOptions
      );
      // const incomes_response = await fetch(process.env.REACT_APP_API_URL + `/owners/owner/property/${id}/annual-incomes/`, requestOptions);

      const documents = await response.json();
      // const incomes = await incomes_response.json();

      if (response.ok) {
        dispatch({
          type: documentConstants.GETALL_DOCUMENTS,
          payload: documents,
        });
        // dispatch({
        //   type: documentConstants.GET_INCOMES,
        //   payload: incomes
        // });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};


export const uploadDocument = (data) => {

  return async (dispatch) => {
    try {
      axios.request({
        method: "post",
        url: process.env.REACT_APP_API_URL + `/owners/owner/property/documents/${data.propertyId}/`,
        data: data,
        onUploadProgress: (progressEvent) => {
          if (progressEvent) {
            const percentage = Math.round((100 * progressEvent.loaded) / progressEvent.total);

            dispatch({
              type: documentConstants.SET_PROGRESS_BAR,
              payload: {
                isShown: true,
                percentage: percentage === 100 ? 90 : percentage,
                text: "Uploading 1 of 1 files.",
                color: "green",
              },
            });
          }
        }
      }).then((response) => {
        const document = response.data;
        debugger

        dispatch({
          type: documentConstants.UPLOAD_DOCUMENT,
          payload: document,
        });
        dispatch({
          type: documentConstants.SET_PROGRESS_BAR,
          payload: {
            isShown: true,
            percentage: 100,
            text: "Upload complete.",
            color: "green",
          },
        });
        setTimeout(() => {
          dispatch({ type: documentConstants.CLEAR_PROGRESS_BAR })
        }, 4000);
      }).catch((error) => {
        if (error.response) {
          dispatch({
            type: documentConstants.SET_PROGRESS_BAR,
            payload: {
              isShown: true,
              text: "Upload failed!",
              color: "red",
              percentage: 100,
            },
          });

          dispatch({
            type: documentConstants.CLEAR_PROGRESS_BAR,
          });
          dispatch({
            type: alertConstants.ERROR,
            message: error.response.data,
          });
          setTimeout(() => {
            dispatch({
              type: alertConstants.CLEAR,
            });
          }, 5000);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
};


export const getFilteredDocuments = (filters) => {
  return async (dispatch) => {
    try {
      const parameters = JSON.stringify(filters);
      const options = getRequestOptions("GET");

      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/${filters.propertyId}/documents/${parameters}`,
        options
      );
      const filteredDocs = await response.json();

      if (response.ok) {
        dispatch({
          type: documentConstants.GETALL_DOCUMENTS,
          payload: filteredDocs,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getSelectedDocument = (propertyId, selectedDocument) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
        body: JSON.stringify({ document: selectedDocument }),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/${propertyId}/annual-incomes/`,
        requestOptions
      );
      const document = await response.json();

      if (response.ok) {
        dispatch({
          type: documentConstants.GET_SELECTED_DOCUMENT,
          payload: document,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};
