import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

function TrafficCardSolo({
  title,
  value,
  subtitle,
  subvalue,
  uppercent,
  description,
  ico,
  bgcolor,
  img,
  subimg,
  name,
}) {
  return (
    <>
      <Card>
        <CardBody className="dashboard-header-card-body">
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
                id={name}
              >
                {title}
                {img}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{value}</span>
            </div>

            <UncontrolledTooltip
              //   placement={"bottom"}
              delay={0}
              trigger="hover focus"
              target="tooltip789789671"
              style={{ maxWidth: "30rem" }}
            >
              Your earnings (after management fees) from reservations that
              check-in this month for the filtered properties.
            </UncontrolledTooltip>
            <UncontrolledTooltip
              // placement={"bottom"}
              delay={0}
              trigger="hover focus"
              target="tooltip789789672"
              style={{ maxWidth: "30rem" }}
            >
              The occupancy percentage for the filtered properties. Homeowner
              reservations and blocks will reduce the number of available days
              and won’t count against the occupancy percentage.
            </UncontrolledTooltip>
            <UncontrolledTooltip
              // placement={"bottom"}
              delay={0}
              trigger="hover focus"
              target="tooltip789789673"
              style={{ maxWidth: "30rem" }}
            >
              The number of reservations guests have made this month for future dates.
            </UncontrolledTooltip>
            <UncontrolledTooltip
              // placement={"bottom"}
              delay={0}
              trigger="hover focus"
              target="tooltip789789674"
              style={{ maxWidth: "30rem" }}
            >
              The number of times Hoste has completed a property task at the filtered properties.
            </UncontrolledTooltip>

            <Col className="col-auto">
              <div
                id="dashboard-icon"
                className={
                  "icon-shape text-white rounded-circle shadow" + bgcolor
                }
              >
                <i className={ico} />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-sm">
            {uppercent ? (
              <span className="text-success mr-2">
                <i className="fa fa-arrow-up" /> {uppercent}
              </span>
            ) : (
              <span className="text-success mr-2">
                {/* <i className="fa fa-arrow-up" /> {uppercent} */}
              </span>
            )}
            <span className="text-nowrap">{description}</span>
          </p>
        </CardBody>
      </Card>
    </>
  );
}

TrafficCardSolo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  subtitle: PropTypes.string,
  subvalue: PropTypes.string,
  uppercent: PropTypes.string,
  description: PropTypes.string,
  ico: PropTypes.string,
  bgcolor: PropTypes.string,
};

export default TrafficCardSolo;
