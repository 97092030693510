/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/actions/auth.action";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Col
} from "reactstrap";
import { useEffect, useState } from "react";
import {
  getOwnerProfile,
  getUser,
  getDashboardData,
  startLoading,
  stopLoading
} from "app/actions";
import { useHistory } from "react-router-dom";
import routes from "routes";
import Sidebar from "components/Sidebar/Sidebar";
import { useMediaQuery } from "react-responsive";
import Multiselect from "multiselect-react-dropdown";



function AdminNavbar({ theme, brandText, ...props }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const propertiesSelector = useSelector(state => state.property.allProperties);
  const [properties, setProperties] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });
  const [photo, setPhoto] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.userData) {
      const { first_name, last_name } = props.userData;
      setName({ firstName: first_name, lastName: last_name });
    }
  }, [props.userData]);

  useEffect(() => {
    if (props.ownerProfile) {
      setPhoto(props.ownerProfile.picture);
    }
  }, [props.ownerProfile]);

  useEffect(() => {
    !props.userData.username && dispatch(getUser());
    !props.ownerProfile.primary_email && dispatch(getOwnerProfile());
  }, []);

  useEffect(() => {
    if (propertiesSelector && propertiesSelector?.length) {
      const ownerProperties = propertiesSelector.map(prop => {
        return {
          id: prop.id,
          group: 'Properties',
          key: prop.entity_name,
          isSelected: true
        }
      });
      const uniqueArray = getUniqueArray(ownerProperties);

      setProperties([{
        id: 0,
        group: 'Properties',
        key: 'All',
        isSelected: true
      }, ...uniqueArray]);
    }
  }, [propertiesSelector]);

  const getUniqueArray = (arr, key = "id") => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  };

  const checkAllOption = (list) => {
    return list.slice(1).every(prop => prop.isSelected === true);
  };

  const onChange = (_, selected, condition) => {
    dispatch(startLoading());

    let updatedPropertyList = [];

    if (selected.key === 'All') {
      updatedPropertyList = properties.map(col => ({
        ...col,
        isSelected: !properties[0].isSelected
      }));

    } else {
      updatedPropertyList = properties.map(col => {
        if (col.id === selected.id) {
          return {
            ...col,
            isSelected: condition.event === 'onSelect'
          };
        };
        return col;
      });
    }

    updatedPropertyList[0].isSelected = checkAllOption(updatedPropertyList);
    const uniqueArray = getUniqueArray(updatedPropertyList);
    setProperties(uniqueArray);

    const checkedProperties = uniqueArray.reduce(function (filtered, property) {
      if (property.isSelected && property.key !== "All") {
        var someNewValue = { id: property.id, name: property.key }
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);

    const idsList = checkedProperties.map((prop) => prop.id);
    dispatch(getDashboardData(idsList));
  };

  const redirect = () => {
    history.push("/profile");
  };

  const isDashboardPage = () => {
    const wordRegex = /\/dashboard/;
    const { pathname } = history.location;

    return !!pathname.match(wordRegex);
  };

  const renderFilter = () => {
    return (
      <Col>
        <Multiselect
          displayValue="key"
          groupBy="group"
          placeholder="Filter by property"
          options={properties}
          selectedValues={properties.filter(prop => prop.isSelected === true)}
          onSelect={(list, selected) => onChange(list, selected, { event: 'onSelect' })}
          onRemove={(list, selected) => onChange(list, selected, { event: 'onRemove' })}
          selectionLimit={-1}
          hideSelectedList
          showCheckbox
          showArrow
        />
      </Col>
    );
  };

  const toggleSidenav = () => {
    document.body.classList.remove("g-sidenav-hidden");
    document.body.classList.add("g-sidenav-pinned");
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand",
          // { "navbar-dark": theme === "dark" },
          // { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          {isTabletOrMobile && <Collapse navbar isOpen={isTabletOrMobile}>
            <Nav>
              <UncontrolledDropdown nav onClick={toggleSidenav}>
                <DropdownToggle tag="a">
                  <div id="close-icon" className={""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>

            <Collapse isOpen={isOpen} navbar>
              <Sidebar
                routes={routes ? routes : "owner"}
                routeshow={"owner"}
                toggleSidenav={toggleSidenav}
                // sidenavOpen={sidenavOpen}
                isOpen={isOpen}
                logo={{
                  innerLink: "/",
                  imgSrc: require("assets/img/main-logo.png").default,
                  imgAlt: "...",
                }}
              />
            </Collapse>
          </Collapse>}

          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center mr-auto" navbar></Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              {isDashboardPage() && renderFilter()}
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        // src={require("assets/img/theme/team-4.jpg").default}
                        // src={photo}
                        src={
                          photo
                            ? photo
                            : require("assets/img/Avatar.png").default
                        }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {name.firstName} {name.lastName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem onClick={() => redirect()}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  {/* <DropdownItem>
                    <i className="ni ni-single-02" />
                    <span><a target="_blank" href="https://testdomain7531.zendesk.com/hc/en-us">Support</a></span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={() => props.logout()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

const mapStateToProps = (state) => ({
  userAuth: state.authentication.user,
  userData: state.user.userData,
  ownerProfile: state.user.ownerProfile,
});
const mapDispatchToProps = {
  logout,
  getUser,
  getOwnerProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
