/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import {getOwnerProfiles} from "app/actions";
import {connect, useDispatch} from "react-redux";

function Owners({ownerProfiles}) {
    const [profiles, setProfiles] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOwnerProfiles());
    }, []);

    useEffect(() => {
        if (ownerProfiles && ownerProfiles.co_owners_data) {
            const currentUserId = ownerProfiles.user_id;

            ownerProfiles.co_owners_data.forEach((data) => {
                data.owners.map((owner) => {
                    if (owner.user_id !== currentUserId) {
                        setProfiles((prevProfiles) => [...prevProfiles, {...owner}]);
                    }
                });
            });
        }
    }, [ownerProfiles]);

    return (
        <>
            <div className="dashboard-header">
                <CardsHeader/>
            </div>
            <Container fluid>
                <Row>
                    {profiles.length > 0 ? (
                        profiles.map((profile, idx) => (
                            <Col xl="3" className="mt-5" key={idx}>
                                <Card className="card-profile">
                                    <Row className="justify-content-center">
                                        <Col className="order-lg-2" lg="3">
                                            <div className="card-profile-image">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img
                                                        alt="..."
                                                        className="rounded-circle"
                                                        src={require("assets/img/theme/team-4.jpg").default}
                                                    />
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardBody className="pt-8">
                                        <Row>
                                            <Col className="text-center">
                                                <h5>
                                                    {profile.first_name} {profile.last_name}
                                                </h5>
                                                {/* <p>
                          {profile.location.city},{" "}
                          {profile.location.country}
                        </p> */}
                                                {/* <p>Property id: {profile.property_id}</p> */}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <div className="mt-5">You have not co-owners yet...</div>
                    )}
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    ownerProfiles: state.user.ownerProfiles,
});

export default connect(mapStateToProps, {getOwnerProfiles})(Owners);
