import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Badge, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import Loader from "components/Loaders/Loader";
import {clearReservationState, getAllProperties, getOwnerProfile,} from "app/actions";
import {alertConstants} from "app/constants";


function Properties({allProperties}) {
    const properties = useSelector(state => state.property.allProperties);
    const owner = useSelector(state => state.user.ownerProfile);
    const [isLoading, setLoading] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: alertConstants.CLEAR,
        });
        console.log("properties", properties)
        !properties && dispatch(getAllProperties());
        dispatch(clearReservationState());
        !owner && dispatch(getOwnerProfile());

        localStorage.removeItem("property_id");
    }, []);

    useEffect(() => {
        if (properties) {
            setLoading(false);
        }
    }, [properties]);

    const moveToCalendar = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        history.push(`/owner/property-calendar/${id}`);
    };

    return (
        <>
            <div className="dashboard-header">
            </div>
            <Container fluid className="mt-4">
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody className="pl-0 pr-0 dashboard-table responsive">
                                <h3 className="mt-0 mb-3 pl-4 table-header">Select Property</h3>
                                <Table
                                    className="align-items-center"
                                    responsive
                                    hover
                                    striped
                                    bordered
                                >
                                    <thead className="thead-light">
                                    <tr>
                                        <th
                                            className="sort"
                                            data-sort="property"
                                            scope="col"
                                            data-label="property"
                                        >
                                            Property
                                        </th>
                                        <th
                                            className="sort"
                                            data-sort="address"
                                            scope="col"
                                            data-label="address"
                                        >
                                            Address
                                        </th>
                                        <th scope="col">
                                            Status
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {isLoading ? (
                                        <tr>
                                            <td></td>
                                            <td className="text-left">
                                                <Loader/>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {properties && properties.length !== 0 ? (
                                                properties.map((data, id) => (
                                                    <tr
                                                        key={id}
                                                        onClick={(e) => moveToCalendar(e, data.id)}
                                                        style={{cursor: "pointer"}}
                                                    >
                                                        <td data-label="property" scope="row">
                                                            <a href="#" className="property-link">{data.entity_name}</a>
                                                        </td>
                                                        <td data-label="address" scope="row">
                                                            <span
                                                                className="property-address-label">{data.street_address}</span>
                                                        </td>
                                                        <td>
                                                            <Badge color="" className="badge-dot mr-4">
                                                                <i className={data.hoste_services ? "bg-success" : "bg-danger"}/>
                                                                <span
                                                                    className="status">{data.hoste_services ? "Active" : "Inactive"}</span>
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <>
                                                    <td>You have not had any properties yet...</td>
                                                </>
                                            )}
                                        </>
                                    )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    allProperties: state.property.allProperties,
});

export default connect(mapStateToProps, {
    getAllProperties,
    clearReservationState,
})(Properties);
