import { propertyCareConstants } from "app/constants";

const initialState = {
    propertyCares: [],
    propertyCareUrl: '',
    pages: 1
};

export function propertyCare(state = initialState, action) {
    switch (action.type) {
        case propertyCareConstants.GET_PROPERTY_CARE_URL:
            return {
                ...state,
                propertyCareUrl: action.payload,
            };
        case propertyCareConstants.GETALL_PROPERTY_CARES:
            return {
                ...state,
                propertyCares: action.payload[0],
                pages: action.payload[1]
            };
        case propertyCareConstants.CLEAR_PROPERTY_CARE_URL:
            return {
                ...state,
                propertyCareUrl: '',
            };
        case propertyCareConstants.CLEAR:
            return {
                ...state,
                propertyCares: [],
                propertyCareUrl: ''
            };
        default:
            return state;
    }
}


