import * as Yup from "yup";

export const DocumentFormSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .required("This field is required")
        .max(50, "Max length: 50 symbols"),
    type: Yup.string()
        .default("Permit"),
    file_link: Yup.mixed()
        .required("This field is required")
});