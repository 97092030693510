import {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    Col,
    Collapse,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import {Calendar} from "primereact/calendar";

import {getAllProperties, getAllReservations, updateProperty} from "app/actions";
import {reservationConstants} from "app/constants";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import Loader from "components/Loaders/Loader";


const ReservationFeed = ({reservationsData, pagesData, allPropertiesData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [columns, setColums] = useState(reservationConstants.groupedOptions);
    const [properties, setProperties] = useState([]);
    const [storedProperties, setStoredProperties] = useState([]);
    const [report, setReport] = useState(null);

    const [range, setRange] = useState(null);
    const [reservations, setReservations] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [amountPage, setAmountPage] = useState([]);

    const [id, setId] = useState(1);
    const [finish, setFinish] = useState(1);
    const [title, setTitle] = useState(1);

    const listRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        !allPropertiesData?.length && dispatch(getAllProperties());

        const settings = JSON.parse(localStorage.getItem('hoste-financials-settings-report'));
        if (settings) {
            setId(settings.id);
            setTitle(settings.title);
            setColums(settings.columns);
            setStoredProperties(settings.properties);
        }

    }, []);

    useEffect(() => {
        if (properties.length > 1) {
            const updatedReport = {id, title, columns, properties, range};
            localStorage.setItem('hoste-financials-settings-report', JSON.stringify(updatedReport));
            setReport(updatedReport);
        }
    }, [id, title, properties, columns, range]);

    useEffect(() => {
        let formattedRange = {};

        if (range?.[0] === null && range?.[1] === null) return;

        if (!range) {
            formattedRange = {
                start: moment().startOf('month').format('YYYY-MM-DD'),
                end: moment().endOf('month').format('YYYY-MM-DD')
            };
        } else {
            formattedRange = {
                start: moment(range[0]).format('YYYY-MM-DD'),
                end: range[1]
                    ? moment(range[1]).format('YYYY-MM-DD')
                    : moment(range[0]).format('YYYY-MM-DD')
            };
        }


        const propertiesIds = properties
            .filter(item => item.isSelected === true && item.key !== 'All')
            .map(prop => prop.id)
            .toString();

        switch (propertiesIds?.length > 0) {
            case true: {
                dispatch(getAllReservations(
                    currentPage, id,
                    finish, title,
                    propertiesIds,
                    JSON.stringify(formattedRange)
                ));
                break;
            }

            case false: {
                setReservations([]);
                break;
            }
        }

    }, [currentPage, report]);

    useEffect(() => {
        if (reservationsData) {
            setReservations(reservationsData.map(item => flattenObj(item)));
            setIsLoading(false);
        }


        if (pagesData) {
            setAmountPage(Array.from({length: pagesData}, (_, i) => i + 1));
        }

    }, [reservationsData, pagesData]);

    useEffect(() => {
        if (allPropertiesData && allPropertiesData?.length) {
            const ownerProperties = allPropertiesData.map(prop => {
                return {
                    id: prop.id,
                    group: 'Properties',
                    key: prop.entity_name,
                    isSelected: prop.is_selected
                }
            });
            const uniqueArray = getUniqueArray(ownerProperties);
            const filteredArray = getFilteredArray(uniqueArray, storedProperties);

            setProperties([{
                id: 0,
                group: 'Properties',
                key: 'All',
                isSelected: isAllSelected(filteredArray)
            }, ...filteredArray]);

            setIsLoading(false);
        }
    }, [allPropertiesData, storedProperties]);

    const isAllSelected = (list) => {
        if (!list || !list.length) return false;

        return list.every(item => item.isSelected === true);
    };

    const getUniqueArray = (arr, key = "id") => {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    };

    const getFilteredArray = (list1, list2) => {
        if (!list1 || !list2 || !list1.length || !list2.length) return list1;

        return list1.map(item1 => {
            const storeditem = list2.find(item2 => item1.id === item2.id);

            if (!storeditem && item1.key !== "All") {
                return item1;
            }

            return storeditem;
        });
    };

    const flattenObj = (obj) => {
        // bring nested objects (except Date obj) to the first level for easy rendering and field access

        let result = {};

        for (const i in obj) {
            if ((typeof obj[i]) === 'object' && !Array.isArray(obj[i]) && !(obj[i] instanceof Date)) {
                const temp = flattenObj(obj[i]);
                for (const j in temp) {
                    result[i + '.' + j] = temp[j];
                }
            } else {
                result[i] = obj[i];
            }
        }
        return result;
    };

    const onChange = (_, selected, condition) => {
        switch (condition.type) {
            case 'column': {
                const updatedColumnsList = columns.map(col => {
                    if (col.accessor === selected.accessor) {
                        return {...col, isShown: condition.event === 'onSelect'}
                    }
                    return col;
                });

                setColums(updatedColumnsList);
                break;
            }

            case 'property': {
                let updatedPropertyList = [];

                if (selected.key === 'All') {
                    updatedPropertyList = properties.map(col => ({
                        ...col,
                        isSelected: !properties[0].isSelected
                    }));
                    // onSaveReport(updatedPropertyList);
                    setReservations([]);
                } else {
                    updatedPropertyList = properties.map(col => {
                        if (col.id === selected.id) {
                            // onSaveReport(col);
                            return {
                                ...col,
                                isSelected: condition.event === 'onSelect'
                            };
                        }

                        return col;
                    });
                }

                updatedPropertyList[0].isSelected = checkAllOption(updatedPropertyList);

                setProperties(getUniqueArray(updatedPropertyList));
                break;
            }

            default:
                break;
        }

    };

    const checkAllOption = (list) => {
        return list.slice(1).every(prop => prop.isSelected === true);
    };

    const onNext = (e) => {
        e.stopPropagation();
        setCurrentPage(currentPage + 1);
    };

    const onPrevious = (e) => {
        e.stopPropagation();
        setCurrentPage(currentPage - 1);
    };

    function onClickPage(e) {
        e.preventDefault();
        const a = Number(e.target.innerText);
        setCurrentPage(a);
    }

    function sortByDate() {
        setTitle(1);
        setFinish(!finish);
        setId(!id);
    }

    function sortByTitle() {
        setTitle(!title);
        setFinish(1);
    }

    function getHandler(column) {
        switch (column) {
            case 'startDate': {
                return sortByDate;
            }

            case 'platform': {
                return sortByTitle;
            }

            default:
                return undefined;
        }
    }

    function defineDataSort(column) {
        switch (column) {
            case 'startDate':
            case 'platform': {
                return column;
            }

            default:
                return undefined;
        }
    }

    function capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    }

    const generateKey = () => {
        return (Math.random() + 1).toString(36).substring(2);
    };

    return (
        <>
            <div className="dashboard-header">
            </div>
            <Container className="pt-4" fluid>
                <Card>
                    <CardBody className="pl-0 pr-0">
                        <Row className="d-flex align-items-baseline justify-content-between m-1 mb-3">
                            <Col>
                                <h3>
                                    <div
                                        className={
                                            "icon-shape text-white rounded-circle bg-traffic1 title-icon"
                                        }
                                    >
                                        <i className="ni ni-single-02"/>
                                    </div>
                                    Reservations
                                </h3>
                            </Col>
                            <Col>
                                <Calendar
                                    id="range"
                                    selectionMode="range"
                                    placeholder={!range ? `${moment().startOf('month').format('YYYY/MM/DD')} - ${moment().endOf('month').format('YYYY/MM/DD')}` : "Date range"}
                                    dateFormat="yy/mm/dd"
                                    name="date-range"
                                    className="date-range-calendar"
                                    value={range}
                                    showButtonBar={true}
                                    onChange={(e) => setRange(e.value)}
                                    readOnlyInput
                                    showIcon
                                />
                            </Col>
                            <Col>
                                <Multiselect
                                    displayValue="key"
                                    groupBy="group"
                                    placeholder="Filters"
                                    options={properties}
                                    selectedValues={properties.filter(prop => prop.isSelected === true)}
                                    onSelect={(list, selected) => onChange(list, selected, {
                                        event: 'onSelect',
                                        type: 'property'
                                    })}
                                    onRemove={(list, selected) => onChange(list, selected, {
                                        event: 'onRemove',
                                        type: 'property'
                                    })}
                                    selectionLimit={-1}
                                    hideSelectedList
                                    showCheckbox
                                    showArrow
                                />
                            </Col>
                            <Col className="mr-4">
                                <Multiselect
                                    displayValue="header"
                                    groupBy="group"
                                    placeholder="Columns"
                                    options={columns}
                                    selectedValues={columns.filter(col => col.isShown === true)}
                                    onSelect={(list, selected) => onChange(list, selected, {
                                        event: 'onSelect',
                                        type: 'column'
                                    })}
                                    onRemove={(list, selected) => onChange(list, selected, {
                                        event: 'onRemove',
                                        type: 'column'
                                    })}
                                    hideSelectedList
                                    showCheckbox
                                    showArrow
                                />

                            </Col>

                            {/* <MultiSelect
                                value={selectedCities1}
                                options={properties}
                                onChange={(e, list) => {
                                    console.log('e', e)
                                    console.log('list', list)
                                    setSelectedCities1(e.value)
                                }}
                                optionLabel="name"
                                placeholder="Properties"
                                fixedPlaceholder
                                filter
                            /> */}

                            {/* <Col>
                                <MultiSelect
                                    value={selectedCities1}
                                    options={columns}
                                    onChange={(e, list) => {
                                        console.log('e', e)
                                        console.log('list', list)
                                        setSelectedCities1(e.value)
                                    }}
                                    optionLabel="name"
                                    placeholder="Columns"
                                    className="columns-box"
                                    fixedPlaceholder
                                    filter
                                />
                            </Col>

                            <Col className="text-right">
                                <Button
                                    label="Submit"
                                    icon="pi pi-check"
                                    className="save-button"
                                    iconPos="right"
                                    loading={isSaving}
                                    onClick={onSaveReport}
                                />
                            </Col> */}
                        </Row>

                        {isLoading
                            ? <Row className="justify-content-center mt-7">
                                <Loader/>
                            </Row>
                            : <Collapse isOpen={!isLoading}><>
                                <div className="table-responsive" ref={listRef} style={{borderRadius: '.4rem'}}>
                                    <CardFooter>
                                        <nav aria-label="...">
                                            <Pagination
                                                className="pagination justify-content-end mb-0 pagi"
                                                listClassName="justify-content-end mb-0"
                                                onClick={(e) => onClickPage(e)}
                                            >

                                                <PaginationItem
                                                    style={{
                                                        visibility:
                                                            currentPage == "1" ? "hidden" : "visible",
                                                    }}
                                                >
                                                    <PaginationLink
                                                        href="#pablo"
                                                        onClick={(e) => onPrevious(e)}
                                                    >
                                                        <i className="fas fa-angle-left"/>
                                                        <span className="sr-only">Previous</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <>
                                                    {amountPage.map((numPage) => {
                                                        if (
                                                            (currentPage == 1 ? numPage <= currentPage + 4 : numPage <= currentPage + 2
                                                            || currentPage === 2 ? numPage <= currentPage + 3 : numPage <= currentPage + 2)
                                                            &&
                                                            (currentPage == pagesData ? numPage >= currentPage - 4 : numPage >= currentPage - 2
                                                            || currentPage == pagesData - 1 ? numPage >= currentPage - 3 : numPage >= currentPage - 2)
                                                        ) {
                                                            return (
                                                                <PaginationItem
                                                                    className={{active: numPage === currentPage}}
                                                                    style={{zIndex: 0}}
                                                                    key={generateKey()}
                                                                >
                                                                    <PaginationLink
                                                                        href="#pablo"
                                                                    >
                                                                        {numPage}
                                                                    </PaginationLink>
                                                                </PaginationItem>
                                                            );
                                                        }
                                                    })}
                                                </>
                                                <PaginationItem
                                                    style={{
                                                        visibility:
                                                            (currentPage == pagesData) || !reservations?.length ? "hidden" : "visible",
                                                    }}
                                                >
                                                    <PaginationLink
                                                        href="#pablo"
                                                        onClick={(e) => onNext(e)}
                                                    >
                                                        <i className="fas fa-angle-right"/>
                                                        <span className="sr-only">Next</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </nav>
                                    </CardFooter>
                                    <Table className="align-items-center table-flush" responsive hover>
                                        <thead className="thead-light">
                                        <tr>
                                            {columns.map(col => {
                                                if (col.isShown) {
                                                    return <th
                                                        scope="col"
                                                        key={generateKey()}
                                                        data-sort={defineDataSort(col.accessor)}
                                                        onClick={getHandler(col.accessor)}
                                                    >
                                                        {col.header}
                                                    </th>
                                                }
                                            })}
                                        </tr>
                                        </thead>

                                        <tbody className="list">
                                        <>
                                            {reservations && reservations.length ? (
                                                reservations.map((item) => (
                                                    <tr key={generateKey()}>
                                                        {columns.map(col => {
                                                            if (col.isShown) {
                                                                switch (col.accessor) {
                                                                    case 'startDate':
                                                                    case 'endDate':
                                                                    case 'confirmedAt':
                                                                        return <td>{moment(item[col.accessor]).format("YYYY-MM-DD")}</td>
                                                                    case 'status':
                                                                        return <td>
                                                                            <Badge color="" className="badge-dot mr-4">
                                                                                <i className="bg-success"/>
                                                                                <span
                                                                                    className="status">{capitalizeFirstLetter(item.status)}</span>
                                                                            </Badge>
                                                                        </td>
                                                                    default:
                                                                        return <td>{item[col.accessor] ? item[col.accessor] : 'No data'}</td>
                                                                }
                                                            }
                                                        })}
                                                    </tr>
                                                ))
                                            ) : (
                                                <td>You have not had any reservations yet...</td>
                                            )}
                                        </>
                                        </tbody>
                                    </Table>
                                </div>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0 pagi"
                                            listClassName="justify-content-end mb-0"
                                            onClick={(e) => onClickPage(e)}
                                        >
                                            <PaginationItem
                                                style={{
                                                    visibility:
                                                        currentPage == "1" ? "hidden" : "visible",
                                                }}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => onPrevious(e)}
                                                >
                                                    <i className="fas fa-angle-left"/>
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <>
                                                {amountPage.map((numPage) => {
                                                    if (
                                                        (currentPage == 1 ? numPage <= currentPage + 4 : numPage <= currentPage + 2
                                                        || currentPage == 2 ? numPage <= currentPage + 3 : numPage <= currentPage + 2)
                                                        &&
                                                        (currentPage == pagesData ? numPage >= currentPage - 4 : numPage >= currentPage - 2
                                                        || currentPage == pagesData - 1 ? numPage >= currentPage - 3 : numPage >= currentPage - 2)
                                                    ) {
                                                        return (
                                                            <PaginationItem
                                                                className={{active: numPage == currentPage}}
                                                                key={generateKey()}
                                                            >
                                                                <PaginationLink
                                                                    href="#pablo"
                                                                >
                                                                    {numPage}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                    }
                                                })}
                                            </>
                                            <PaginationItem
                                                style={{
                                                    visibility:
                                                        (currentPage == pagesData) || !reservations?.length ? "hidden" : "visible",
                                                }}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => onNext(e)}
                                                >
                                                    <i className="fas fa-angle-right"/>
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                </CardFooter>
                            </>
                            </Collapse>}
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

const mapStateToProps = (state) => ({
    pagesData: state.reservation.pages,
    reservationsData: state.reservation.reservations,
    allPropertiesData: state.property.allProperties,
});

const mapDispatchToProps = {
    getAllReservations,
    getAllProperties,
    updateProperty
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationFeed);