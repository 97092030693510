import { Component } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Col, Label, Row, Toast, ToastBody } from 'reactstrap';


export class ChangingProgressProvider extends Component {
    static defaultProps = {
        interval: 1000
    };

    state = {
        valuesIndex: 0
    };

    componentDidMount() {
        setInterval(() => {
            this.state.valuesIndex >= 90 ? this.setState({ valuesIndex: 5 }) : this.setState({
                valuesIndex: this.state.valuesIndex + 1
            });
        }, this.props.interval);
    }

    render() {
        return this.props.children(this.props.values[this.state.valuesIndex]);
    }
}

const ProgressBar = ({ data }) => {
    return (
        <div
            className="p-2 my-3"
            style={{
                width: '22%',
                position: 'fixed',
                left: 50,
                bottom: 0,
                zIndex: 9999,
                boxShadow: '0px 1px 8px 0px rgba(148,143,148,1)',
                backgroundColor: 'white'
            }}>
            <Toast>
                <ToastBody>
                    <Row>
                        <Col md='3' className='ml-3'>
                            <div style={{ width: 60, height: 60 }}>
                                <ChangingProgressProvider values={[0, 20, 40, 60, 80, 90]}>
                                    {percentage => (
                                        <CircularProgressbar
                                            value={percentage < 60 ? percentage : data.percentage}
                                            text={percentage < 60 ? `${percentage}%` : `${data.percentage}%`}
                                            styles={buildStyles({
                                                pathTransitionDuration: 0.1,
                                                textColor: "grey",
                                                pathColor: data.color
                                            })}
                                        />)}
                                </ChangingProgressProvider>
                            </div>
                        </Col>
                        <Col className='mt-3 text-left'>
                            <Label style={{ fontSize: '.9em', fontWeight: 'bold' }}>{data.text}</Label>
                        </Col>
                    </Row>
                </ToastBody>
            </Toast>
        </div>

    )
};

export default ProgressBar;