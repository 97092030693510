import { combineReducers } from "redux";
import { authentication } from "./auth.reducer";
import { alert } from "./alert.reducer";
import { onboarding, onboardingProperty } from "./onboarding.reducer";
import { user } from "./user.reducer";
import { property } from "./property.reducer";
import { document } from "./document.reducer";
import { reservation } from "./reservation.reducer";
import { propertyCare } from "./property-care.reducer";
import { financials } from "./financials.reducer";
import { loading } from "./loading.reducer";


const rootReducer = combineReducers({
  authentication,
  onboarding,
  alert,
  user,
  onboardingProperty,
  property,
  document,
  reservation,
  propertyCare,
  financials,
  loading
});

export default rootReducer;
