import { documentConstants } from 'app/constants/document.constants';

const initialState = {
    documents: [],
    document: {},
    progressBar: {}
};

export function document(state = initialState, action) {
    switch (action.type) {
        case documentConstants.GETALL_DOCUMENTS:
            return {
                ...state,
                documents: action.payload
            };
        case documentConstants.GET_SELECTED_DOCUMENT:
            return {
                ...state,
                document: action.payload
            };
        case documentConstants.GET_DOCUMENTS_BY_DATE:
            return {
                ...state,
                documents: action.payload
            };
        case documentConstants.UPLOAD_DOCUMENT:
            return {
                ...state,
                document: action.payload
            };
        case documentConstants.SET_PROGRESS_BAR:
            return {
                ...state,
                progressBar: action.payload
            };
        case documentConstants.CLEAR_PROGRESS_BAR:
            return {
                ...state,
                progressBar: { isShown: false, percentage: 0 }
            };
        case documentConstants.CLEAR:
            return {
                ...state,
                documents: [],
                document: {}
            };

        default:
            return state;
    }
}