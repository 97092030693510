import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import ReactTooltip from "react-tooltip";
import {Badge, Card, CardBody, CardHeader, Col, Collapse, Container, Row, UncontrolledTooltip,} from "reactstrap";
import FormOption from "components/Forms/FormOption.js";
import {getFormatedDate} from "libs/APIs.js";
import OwnerHeader from "components/Headers/OwnerHeader.js";
import OwnerSubNav from "components/Navbars/OwnerSubNav.js";
import {connect, useDispatch} from "react-redux";
import {useForm} from "app/hooks/useForm.hook";
import {
  clearCostOfSelectedDays,
  createMaintenanceBooking,
  createReservation,
  getAllReservationsByPropertyId,
  getCalendarByDateRange,
  getOwnerProfile,
  getReservationById,
  updateMaintenanceBooking,
  updateReservation,
} from "app/actions";
import {format} from "date-fns";
import {getReservationData} from "libs/APIs";
import {DateRangePicker} from "components/Calendars";
import {reservationConstants} from "app/constants/reservation.constants";
import Submitted from "components/Alerts/Submitted";
import Confirmation from "components/Modals/Confirmation";
import {useHistory} from "react-router";
import Loader from "components/Loaders/Loader";
import moment from "moment";
import GuestReservationForm from "components/Entities/reservation/guesty/GuestReservationForm";
import BlockReservationForm from "components/Entities/reservation/guesty/BlockReservationForm";
import StayForm from "components/Entities/reservation/owner/StayForm";
import MaintenanceForm from "components/Entities/reservation/owner/MaintenanceForm";
import {capitalizeFirstLetter, splitToDates} from "app/helpers";

function PropertyCalendar({
                              reservations,
                              reservation,
                              message,
                              type,
                              calendarData,
                              availabillitySettings,
                          }) {
    const propertyId = JSON.parse(localStorage.getItem("property_id"));
    const dispatch = useDispatch();
    const history = useHistory();

    const currentDate = moment().format("YYYY-MM-DD");

    const [isValidRange, setIsValidRange] = useState({
        maxNights: false,
        overlap: false,
    });
    const [isLoading, setLoading] = useState(true);
    const [isLoadingReservation, setIsLoadingReservation] = useState(false);
    const [modal, setModal] = useState(false);
    const [isTheSameRange, setIsTheSameRange] = useState(false);
    const [predefinedRanges, setPredefinedRanges] = useState([]);
    const [reservationType, setReservationType] = useState("");
    const [isReadonly, setIsReadonly] = useState(true);
    const [ranges, setRanges] = useState([
        {
            startDate: null,
            endDate: null,
            key: "Owner Reservation",
            color: "#3D91FF",
        },
    ]);

    const {formData, setFormData, resetForm} = useForm({
        revenue: 0,
        opportunityCost: 0,
        startDate: "",
        endDate: "",
        arrival: "",
        departure: "10:00 AM",
        reservationFor: "Myself",
        notes: "",
        nights_count: "",
        guestsCount: 1,
        cleaningCost: 0,
        guestId: "",
        status: "confirmed",
        reasonForReservation: "",
        guestyReservationId: "",
        type_of_maintenance: "",
        commission: "",
        netIncome: 0,
        platform: "",
        confirmedAt: "",
        confirmationCode: "",
        maxOccupancy: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
    });

    const [insights, setInsights] = useState({
        month: moment().month(),
        year: moment().year(),
        allBookedNights: null,
        occupancy: null,
        averageNightlyRate: null,
        estimatedNetIncome: null,
    });

    useEffect(() => {
        const selectedRange = {
            month: insights.month,
            year: insights.year,
        };

        predefinedRanges.length &&
        calculateInsights(predefinedRanges, selectedRange);
    }, [predefinedRanges, insights.month, insights.year]);

    useEffect(() => {
        const startOfMonth = moment({
            year: insights.year,
            month: insights.month,
            day: 1,
        }).format("YYYY-MM-DD");
        const endOfMonth = moment({year: insights.year, month: insights.month})
            .endOf("month")
            .format("YYYY-MM-DD");

        propertyId &&
        dispatch(
            getCalendarByDateRange(
                propertyId,
                {startDate: startOfMonth, endDate: endOfMonth},
                true
            )
        );
    }, [insights.month, insights.year, propertyId]);

    useEffect(() => {
        formData.guestyReservationId ? setIsReadonly(true) : setIsReadonly(false);
    }, [formData.guestyReservationId, formData.reasonForReservation]);

    useEffect(() => {
        setLoading(true);

        dispatch(clearCostOfSelectedDays());
        propertyId && dispatch(getAllReservationsByPropertyId(propertyId));
    }, [propertyId]);

    useEffect(() => {
        if (formData.startDate !== "" && formData.endDate !== "") {
            const numberOfDays = getNumberOfDays(
                formData.startDate,
                formData.endDate
            );

            !formData.guestyReservationId
                ? dispatch(getCalendarByDateRange(propertyId, formData))
                : dispatch(clearCostOfSelectedDays());

            setFormData({
                ...formData,
                nights_count: numberOfDays,
            });
        }
    }, [formData.startDate, formData.endDate]);

    useEffect(() => {
        if (Array.isArray(reservations) && reservations?.length) {
            !reservations.length && setLoading(false);

            let blocks = getReservationsByCalendarRules(
                availabillitySettings,
                reservations
            );
            blocks = mergeByDefaultReservations(blocks);
            setPredefinedRanges([...reservations, ...blocks]);
            setLoading(false);
        }
    }, [reservations, availabillitySettings]);

    useEffect(() => {
        if (reservation && Object.keys(reservation).length > 0) {
            setFormData({
                ...reservation,
                ...reservation.guest,
                cleaningCost: formData.cleaningCost,
                opportunityCost: calcOpportunityCost(calendarData),
                nights_count: getNumberOfDays(
                    moment(reservation.startDate),
                    moment(reservation.endDate)
                ),
                startDate: moment(reservation.startDate).format("YYYY-MM-DD"),
                endDate: moment(reservation.endDate).format("YYYY-MM-DD"),
            });
            setIsLoadingReservation(false);
        }
    }, [reservation]);

    useEffect(() => {
        if (calendarData && calendarData.length !== 0) {
            setFormData({
                ...formData,
                opportunityCost: calcOpportunityCost(calendarData),
            });
        }
    }, [calendarData]);

    useEffect(() => {
        message && setLoading(false);
        type === "alert-success" && history.push(`/confirmed-info/${propertyId}`);
    }, [message, type]);

    const getReservationsByCalendarRules = (calendar, reservations) => {
        // 'Advance Notice', 'By Default' types of block doesn't have id for detecting changes
        // That's why we dynamically pull these types of blocks when month changes
        if (!calendar.length) return [];

        return calendar.flatMap((date) => {
            return date.blockRefs
                .filter((blockRef) => {
                    if (
                        ["bd", "an"].includes(blockRef.type) &&
                        checkIfOverlapped(
                            {
                                startDate: moment(date.date, "YYYY-MM-DD"),
                                endDate: moment(date.date, "YYYY-MM-DD").add(1, "days"),
                            },
                            reservations
                        ).length === 0
                    ) {
                        return blockRef;
                    }
                })
                .map((blockRef) => {
                    return {
                        startDate: moment(date.date, "YYYY-MM-DD").toDate(),
                        endDate: moment(date.date, "YYYY-MM-DD").add(1, "days").toDate(),
                        status: "unavailable",
                        reasonForReservation: "Maintenance",
                        typeOfMaintenance:
                            blockRef.type === "bd" ? "By Default" : "Advance Notice",
                        color: "#9B9B9B",
                        guestyReservationId: "randomid",
                    };
                });
        });
    };

    function refreshPage() {
        window.location.reload(false);
    }

    const isBetween = (item, range, isSettings = false) => {
        if (
            moment(range.startDate).add(1, "day").isSame(range.endDate) &&
            moment(item).isSame(range.endDate)
        ) {
            return moment(item).isBetween(
                range.startDate,
                range.endDate,
                "days",
                "[]"
            );
        }
        return isSettings
            ? moment(item).isBetween(range.startDate, range.endDate, "days", "[)")
            : moment(item).isBetween(range.startDate, range.endDate, "days", "()");
    };

    function checkIfOverlapped(date, reservations) {
        if (date.startDate === "Invalid date" || !date.startDate) {
            date.startDate = date.endDate ? date.endDate : null;
        }

        if (date.endDate === "Invalid date" || !date.endDate) {
            date.endDate = date.startDate ? date.startDate : null;
        }

        const dates = splitToDates(date.startDate, date.endDate);

        if (predefinedRanges.length) {
            return dates.filter((item) => {
                return predefinedRanges.some((range) => {
                    return (
                        isBetween(item, range) &&
                        formData.guestyReservationId !== range.guestyReservationId
                    );
                });
            });
        } else {
            return dates.filter((item) => {
                return reservations.some((range) => {
                    return (
                        isBetween(item, range) &&
                        formData.guestyReservationId !== range.guestyReservationId
                    );
                });
            });
        }
    }

    const mergeByDefaultReservations = (blocks) => {
        let result = [];
        let currentCombinedReservation = null;

        blocks
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .forEach((block) => {
                if (!currentCombinedReservation) {
                    currentCombinedReservation = {
                        startDate: block.startDate,
                        endDate: block.endDate,
                        ...block,
                    };
                } else if (
                    moment(block.startDate) <=
                    moment(currentCombinedReservation.endDate).add(1, "days")
                ) {
                    // If the start date of the current block is within one day of the end date of the current combined reservation
                    currentCombinedReservation.endDate = block.endDate;
                } else {
                    result.push(currentCombinedReservation);
                    currentCombinedReservation = {
                        startDate: block.startDate,
                        endDate: block.endDate,
                        ...block,
                    };
                }
            });

        if (currentCombinedReservation) {
            result.push(currentCombinedReservation);
        }

        return result;
    };

    const getDaysInMonth = ({month, year}) => {
        return moment().set("month", month).set("year", year).daysInMonth();
    };

    const getEstimatedNetIncome = (list, {month, year}) => {
        let estimatedNetIncome = 0;

        list.forEach((item) => {
            const {startDate} = isBetweenMonth(item, month, year);
            if (!item.revenue || !startDate) return;

            estimatedNetIncome += +item.revenue;
        });

        return estimatedNetIncome ? estimatedNetIncome.toFixed(2) : 0;
    };

    const getAverageNightlyRate = (list, {month, year}) => {
        let nightlyRate = 0;
        const guestsBookedNights = getBookedNights(list, "guest", {month, year});

        list.forEach((item) => {
            if (!item.netIncome) return;

            const isBetween = isBetweenMonth(item, month, year);
            const {startDate, endDate} = checkReservationRange(item, {
                month,
                year,
            });
            const monthlyBookedNights = getNumberOfDays(startDate, endDate);
            const totalBookedNights = getNumberOfDays(item.startDate, item.endDate);

            if (!isBetween.startDate || !isBetween.endDate) {
                nightlyRate +=
                    (+item.netIncome / totalBookedNights) * monthlyBookedNights;
            } else {
                nightlyRate += +item.netIncome;
            }
        });

        return nightlyRate ? Math.round(nightlyRate / guestsBookedNights) : 0;
    };

    const checkReservationRange = (item, {month, year}) => {
        const {startDate, endDate} = isBetweenMonth(item, month, year);

        switch (true) {
            case startDate && endDate: {
                return {
                    startDate: item.startDate,
                    endDate: item.endDate,
                };
            }
            case startDate && !endDate: {
                return {
                    startDate: item.startDate,
                    endDate: moment()
                        .set("month", month)
                        .set("year", year)
                        .endOf("month")
                        .add(1, "days"),
                };
            }
            case !startDate && endDate: {
                return {
                    startDate: moment()
                        .set("month", month)
                        .set("year", year)
                        .startOf("month"),
                    endDate: item.endDate,
                };
            }

            default:
                break;
        }
    };

    const getBookedNights = (list, condition, dateRange) => {
        let filteredReservations = [];
        let uniqueReservations = new Set();
        let allBookedNights = 0;

        switch (condition) {
            case "guest": {
                filteredReservations = list.filter((res) => {
                    if (res.key === "guesty" && res.reasonForReservation === "Stay") {
                        return res;
                    }
                });
                break;
            }
            case "block": {
                filteredReservations = list.filter((res) => {
                    if (
                        res.reasonForReservation === "Maintenance" &&
                        res.key !== "Owner Reservation"
                    ) {
                        return res;
                    }
                });
                break;
            }
            case "owner": {
                filteredReservations = list.filter((res) => {
                    if (
                        res.key === "Owner Reservation" ||
                        res.platform === "owners platform"
                    ) {
                        return res;
                    }
                });
                break;
            }

            default: {
                filteredReservations = list;
                break;
            }
        }

        filteredReservations.forEach((item) => {
            const {startDate, endDate} = checkReservationRange(item, dateRange);
            const splittedDates = splitToDates(startDate, endDate).slice(0, -1);

            splittedDates.forEach((date) => {
                uniqueReservations.add(date);
            });
        });

        allBookedNights = uniqueReservations.size;
        return allBookedNights;
    };

    const getOccupancy = (list, selectedRange) => {
        const daysInMonth = getDaysInMonth(selectedRange);
        const guestBookings = getBookedNights(list, "guest", selectedRange);
        const blocks = getBookedNights(list, "block", selectedRange);
        const ownerBookings = getBookedNights(list, "owner", selectedRange);

        return guestBookings
            ? Math.abs(
                Math.round(
                    (guestBookings / (daysInMonth - blocks - ownerBookings)) * 100
                )
            )
            : 0;
    };

    const getUniqueReservations = (list, {month, year}) => {
        return list.filter((res) => {
            const isBetween = isBetweenMonth(res, month, year);
            if (isBetween.startDate || isBetween.endDate) {
                return res;
            }
        });
    };

    const checkBookingLength = (list, {month, year}) => {
        const longTermBooking = list.find((item) => {
            const {startDate, endDate, key, reasonForReservation} = item;
            const isGuestBooking =
                key === "guesty" && reasonForReservation === "Stay";

            if (
                moment()
                    .set("month", month)
                    .set("year", year)
                    .isBetween(startDate, endDate) &&
                isGuestBooking
            ) {
                return item;
            }
        });

        if (!longTermBooking) return;

        const {startDate, endDate} = longTermBooking;
        const monthlyBookedNights = getNumberOfDays(startDate, endDate);

        const longTermInsights = {
            allBookedNights: getDaysInMonth({month, year}),
            occupancy: 100,
            averageNightlyRate: Math.round(
                (+longTermBooking.netIncome / monthlyBookedNights) *
                getDaysInMonth({month, year})
            ),
            estimatedNetIncome: 0,
        };

        return longTermInsights;
    };

    function calculateInsights(reservations, selectedRange) {
        const uniqueReservations = getUniqueReservations(
            reservations,
            selectedRange
        );

        if (uniqueReservations.length === 0) {
            const isLongTermBooking = checkBookingLength(reservations, selectedRange);

            if (isLongTermBooking) {
                setInsights({...insights, ...isLongTermBooking});

                return;
            }

            resetInsights();
            return;
        }

        const estimatedNetIncome = getEstimatedNetIncome(
            uniqueReservations,
            selectedRange
        );
        const allBookedNights = getBookedNights(
            uniqueReservations,
            "guest",
            selectedRange
        );
        const averageNightlyRate = getAverageNightlyRate(
            uniqueReservations,
            selectedRange
        );
        const occupancy = getOccupancy(uniqueReservations, selectedRange);

        const values = {
            ...insights,
            allBookedNights,
            occupancy,
            averageNightlyRate,
            estimatedNetIncome,
        };

        setInsights(values);
        setLoading(false);
    }

    const resetInsights = () => {
        const values = {
            ...insights,
            allBookedNights: 0,
            occupancy: 0,
            averageNightlyRate: 0,
            estimatedNetIncome: 0,
        };

        setInsights(values);
    };

    const calcOpportunityCost = (calendar) => {
        return calendar.length === 1
            ? calendar[0].price
            : calendar.reduce((sum, item) => sum + item.price, 0);
    };

    function isBetweenMonth(date, month, year) {
        const isBetween = {
            startDate: moment(date.startDate).isBetween(
                moment().set("month", month).set("year", year).startOf("month"),
                moment().set("year", year).set("month", month).endOf("month"),
                undefined,
                "[]"
            ),
            endDate: moment(date.endDate).isBetween(
                moment().set("month", month).set("year", year).startOf("month"),
                moment().set("year", year).set("month", month).endOf("month"),
                undefined,
                "[]"
            ),
        };

        return isBetween;
    }

    function getNumberOfDays(start, end) {
        if (!start || !end) return;

        start = moment(start, "YYYY-MM-DD");
        end = moment(end, "YYYY-MM-DD");

        return Math.abs(end.diff(start, "days"));
    }

    const handlePredefinedRange = (range) => {
        if (!range) return;

        setReservationType("");
        setIsTheSameRange(true);
        setIsValidRange({...isValidRange, overlap: false});

        setFormData({
            ...range,
            ...range?.guest,
            cleaningCost: range.property?.cleaningFee,
            maxOccupancy: range.property?.maxOccupancy,
            opportunityCost: calcOpportunityCost(calendarData),
            nights_count: getNumberOfDays(
                moment(range.startDate),
                moment(range.endDate)
            ),
            startDate: moment(range.startDate).format("YYYY-MM-DD"),
            endDate: moment(range.endDate).format("YYYY-MM-DD"),
            arrival: range?.arrival,
        });
    };

    const handleSelect = (item, isChild = false) => {
        const startDate = moment(item.selection.startDate).format("YYYY-MM-DD");
        const endDate = moment(item.selection.endDate).format("YYYY-MM-DD");

        if (checkIfOverlapped({startDate, endDate})) {
            console.log("overrlaped");
            setIsTheSameRange(false);

            const overlappedDays = checkIfOverlapped({startDate, endDate});
            console.log("overlappedDays", overlappedDays);

            if (overlappedDays.length > 0) {
                checkStartDate({startDate, endDate})
                    ? moveSelectionIfRangesOverlap(item.selection.endDate)
                    : moveSelectionIfRangesOverlap(item.selection.startDate);

                return;
            }
        }

        setRanges([
            {
                ...item.selection,
                startDate: moment(item.selection.startDate).toDate(),
                endDate: moment(item.selection.endDate).toDate(),
                key: "Owner Reservation",
                color: "#3D91FF",
            },
        ]);

        console.log("not overrlaped");

        setFormData({
            ...formData,
            ...formData.guest,
            startDate: startDate,
            endDate: endDate,
            guestyReservationId: "",
            guestId: "",
            key: "Owner Reservation",
            reservationFor: "Myself",
            nights_count: getNumberOfDays(startDate, endDate),
        });
        setIsValidRange({...isValidRange, overlap: false});
    };

    function moveSelectionIfRangesOverlap(moveTo) {
        setRanges([
            {
                startDate: moment(moveTo).toDate(),
                endDate: moment(moveTo).toDate(),
                color: "#3D91FF",
                key: "Owner Reservation",
            },
        ]);
    }

    const checkStartDate = (selectedDate) => {
        return predefinedRanges.filter((reservation) => {
            if (
                moment(selectedDate.startDate).isSameOrAfter(reservation.startDate) &&
                moment(selectedDate.startDate).isBetween(
                    reservation.startDate,
                    reservation.endDate,
                    "days",
                    "()"
                )
            ) {
                return reservation;
            }
        })[0];
    };

    const customDayContent = (day) => {
        let selectedReservation = getReservationData(predefinedRanges, day);

        if (selectedReservation !== -1) {
            var {
                startDate,
                endDate,
                netIncome,
                status,
                guestsCount,
                reasonForReservation,
                notes,
                key,
                guest,
                typeOfMaintenance,
            } = selectedReservation;
        }

        return (
            <>
                {selectedReservation !== -1 ? (
                    <div data-tip data-for={"data-" + getFormatedDate(day, "ymd")}>
                        <span>{format(day, "d")}</span>
                        <ReactTooltip
                            id={"data-" + getFormatedDate(day, "ymd")}
                            place="top"
                            type="info"
                            effect="solid"
                        >
                            {reasonForReservation === "Maintenance" ? (
                                <div className="tooltip-wrap">
                                    <h3 className="pb-2 mt-0 border-bottom">Block</h3>
                                    <Row className="mt-3">
                                        <Col md="6">
                                            <label>Status</label>
                                            <p style={{color: "red", fontWeight: "bold"}}>
                                                unavailable
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label>Type</label>
                                            <p>{typeOfMaintenance ? typeOfMaintenance : notes}</p>
                                        </Col>
                                        <Col md="6">
                                            <label>Works start</label>
                                            <p>{moment(startDate).format("YYYY-MM-DD")}</p>
                                        </Col>
                                        <Col md="6">
                                            <label>Works end</label>
                                            <p>{moment(endDate).format("YYYY-MM-DD")}</p>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div className="tooltip-wrap">
                                    <label>Revenue for stay</label>
                                    <h3 className="pb-2 mt-0 border-bottom">${netIncome}</h3>
                                    <Row className="mt-3">
                                        <Col md="6">
                                            <label>Status</label>
                                            <p
                                                className={
                                                    status === reservationConstants.status.confirmed
                                                        ? "confirm"
                                                        : "in-progress"
                                                }
                                            >
                                                {status}
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <label>Guests</label>
                                            <p>{guestsCount ? guestsCount : "unknown"}</p>
                                        </Col>
                                        <Col md="6">
                                            <label>Check in</label>
                                            <p>{moment(startDate).format("YYYY-MM-DD")}</p>
                                        </Col>
                                        <Col md="6">
                                            <label>Check out</label>
                                            <p>{moment(endDate).format("YYYY-MM-DD")}</p>
                                        </Col>
                                        {guest && key === "guesty" && (
                                            <>
                                                <Col md="10">
                                                    <label>Guest Name</label>
                                                    <p>{capitalizeFirstLetter(guest.firstName)}</p>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </div>
                            )}
                        </ReactTooltip>
                    </div>
                ) : (
                    <div>
                        <span>{format(day, "d")}</span>
                    </div>
                )}
            </>
        );
    };

    const getForm = ({isReadonly}) => {
        switch (formData.key) {
            case "guesty":
                return formData.reasonForReservation === "Stay" ? (
                    <GuestReservationForm formData={formData}/>
                ) : (
                    <BlockReservationForm formData={formData}/>
                );
            case "Owner Reservation":
                switch (formData.reasonForReservation) {
                    case "Stay": {
                        return (
                            <StayForm
                                formData={formData}
                                setFormData={setFormData}
                                isTheSameRange={isTheSameRange}
                                setModal={setModal}
                                reset={reset}
                                isReadonly={isReadonly}
                                setIsReadonly={setIsReadonly}
                                checkIfOverlapped={checkIfOverlapped}
                                predefinedRanges={predefinedRanges}
                                setLoading={setLoading}
                                setRanges={setRanges}
                                getCalendarByDateRange={getCalendarByDateRange}
                                ranges={ranges}
                            />
                        );
                    }
                    case "Maintenance": {
                        return (
                            <MaintenanceForm
                                formData={formData}
                                setFormData={setFormData}
                                isTheSameRange={isTheSameRange}
                                setModal={setModal}
                                reset={reset}
                                isReadonly={isReadonly}
                                setIsReadonly={setIsReadonly}
                                checkIfOverlapped={checkIfOverlapped}
                                predefinedRanges={predefinedRanges}
                                setLoading={setLoading}
                                setRanges={setRanges}
                            />
                        );
                    }
                }
            default:
                break;
        }
    };

    const onCancel = () => {
        formData.reasonForReservation === "Stay"
            ? dispatch(
                updateReservation(
                    {
                        ...formData,
                        propertyId,
                    },
                    reservationConstants.status.canceled
                )
            )
            : dispatch(
                updateMaintenanceBooking(
                    {
                        ...formData,
                        propertyId,
                    },
                    reservationConstants.status.available
                )
            );

        reset();
        setInsights({
            ...insights,
            month: moment().month(),
            year: moment().year(),
        });
    };

    const reset = () => {
        dispatch(clearCostOfSelectedDays());

        setReservationType("");
        setLoading(true);
        setModal(false);

        resetForm();
    };

    return (
        <>
            <div className="dashboard-header">
                <OwnerHeader/>
            </div>
            <OwnerSubNav curPage="Calendar"/>
            <Container fluid className="calendar-wrap">
                <Row>
                    <Col xl="8" md="8">
                        <Card className="bg-default">
                            <CardBody>
                                {isLoading ? (
                                    <Row className="justify-content-center mt-6">
                                        <Loader/>
                                    </Row>
                                ) : (
                                    <>
                                        <Row className="property-insights">
                                            <div>
                                                <label>Booked Nights</label>
                                                <p>
                                                    {insights.allBookedNights
                                                        ? insights.allBookedNights
                                                        : 0}
                                                </p>
                                            </div>
                                            <div>
                                                <label>Occupancy</label>
                                                <p>{insights.occupancy ? insights.occupancy : 0}%</p>
                                            </div>
                                            <div>
                                                <label>Average Nightly Rate</label>
                                                <p>
                                                    $
                                                    {insights.averageNightlyRate
                                                        ? insights.averageNightlyRate
                                                        : 0}
                                                </p>
                                            </div>
                                            <div>
                                                <label>Estimated Net Income</label>
                                                <p>
                                                    $
                                                    {insights.estimatedNetIncome
                                                        ? insights.estimatedNetIncome
                                                        : 0}
                                                </p>
                                            </div>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <DateRangePicker
                                                    className="daterangepicker"
                                                    ranges={ranges}
                                                    months={1}
                                                    monthDisplayFormat={"MMMM yyyy"}
                                                    dateDisplayFormat={"yyyy-MM-d"}
                                                    direction="vertical"
                                                    fixedHeight={true}
                                                    onChange={handleSelect}
                                                    handlePredefinedRange={handlePredefinedRange}
                                                    handleMonthAndYear={(month, year) =>
                                                        setInsights({
                                                            ...insights,
                                                            month: month,
                                                            year: year,
                                                        })
                                                    }
                                                    dayContentRenderer={customDayContent}
                                                    showSelectionPreview={true}
                                                    showPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    predefinedRanges={predefinedRanges}
                                                    color="#3D91FF"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="d-flex pr-4 pt-2 justify-content-end badge-container">
                                            <div>
                                                <Badge color="" className="badge-dot">
                                                    <i className="bg-success"/>
                                                </Badge>
                                                <label>Guest Booking</label>
                                            </div>
                                            <div>
                                                <Badge color="" className="badge-dot pl-4">
                                                    <i className="bg-reserve"/>
                                                </Badge>
                                                <label>My Reservation</label>
                                            </div>
                                            <div>
                                                <Badge color="" className="badge-dot pl-4">
                                                    <i className="bg-block"/>
                                                </Badge>
                                                <label>Block</label>
                                            </div>
                                        </Row>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="4" md="4">
                        <Card>
                            <CardHeader className="pl-3 pr-3 pt-2 pb-2">
                                <Row>
                                    <Col md="6" className="pr-0">
                                        <h3 className="mb-0 mt-2 form-title">Owner Reservation</h3>
                                    </Col>
                                    <Col
                                        md="6"
                                        className="pl-0 text-right header-reservation-form"
                                    >
                                        <div className="total-label" id="tooltip-opportunity-cost">
                                            Total opportunity cost <br/>
                                            <img
                                                src="https://ownerportaldev.s3.amazonaws.com/Files/question-circle.svg"
                                                style={{
                                                    width: "10px",
                                                    marginLeft: "0.2rem",
                                                    position: "absolute",
                                                    top: "8px",
                                                }}
                                            />
                                            <UncontrolledTooltip
                                                placement={"bottom"}
                                                delay={0}
                                                trigger="hover focus"
                                                target="tooltip-opportunity-cost"
                                                style={{maxWidth: "20rem"}}
                                            >
                                                This value represents the estimated potential revenue of
                                                a guest booking that would be lost by blocking the
                                                selected dates. Note: This value is subject to change at
                                                any time due to pricing fluctuations and other factors.
                                            </UncontrolledTooltip>
                                            <span className="opportunity-cost">
                        $
                                                {formData.opportunityCost &&
                                                formData.startDate >= currentDate
                                                    ? formData.opportunityCost
                                                    : 0}
                      </span>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="bg-custom reservation-form">
                                <Row>
                                    <Col>
                                        <FormOption
                                            title="Reason For Reservation"
                                            name="reasonForReservation"
                                            type="radio"
                                            value={reservationType}
                                            onChange={({target}) => {
                                                setIsTheSameRange(false);
                                                setFormData({
                                                    ...formData,
                                                    startDate: isTheSameRange ? "" : formData.startDate,
                                                    endDate: isTheSameRange ? "" : formData.endDate,
                                                    notes: "",
                                                    arrival: "",
                                                    guestsCount: 1,
                                                    guestyReservationId: "",
                                                    guestId: "",
                                                    key: "Owner Reservation",
                                                    reasonForReservation: target.value,
                                                });
                                                setReservationType(target.value);
                                            }}
                                            required={true}
                                            options={[
                                                {label: "Book a stay", value: "Stay"},
                                                {
                                                    label: "Block for maintenance",
                                                    value: "Maintenance",
                                                },
                                            ]}
                                            direction="horizontal"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>

                            <Collapse
                                isOpen={!isTheSameRange && !!reservationType && !isLoading}
                            >
                                {getForm({isReadonly})}
                            </Collapse>

                            {message && (
                                <div className="text-center mb-3 mt-3">
                                    <Submitted bodyText={message} type={type}/>
                                </div>
                            )}

                            {modal && (
                                <Confirmation
                                    toggle={setModal}
                                    isOpen={modal}
                                    title="Confirm cancellation"
                                    handleConfirmation={onCancel}
                                    handleDisapproval={() => setModal(false)}
                                    body="Are you sure you want to cancel your current reservation? You will not be able to undo the request!"
                                />
                            )}
                        </Card>

                        {isTheSameRange && !reservationType && !isLoadingReservation && (
                            <Collapse isOpen={true}>{getForm({isReadonly})}</Collapse>
                        )}

                        {isLoadingReservation && isTheSameRange && !reservationType && (
                            <Row className="justify-content-center mt-6">
                                <Loader/>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.ownerProfile,
    reservations: state.reservation.reservations,
    reservation: state.reservation.reservation,
    calendarData: state.reservation.calendar,
    availabillitySettings: state.reservation.availabillitySettings,
    ownerData: state.user.ownerProfile,
    property: state.property.property,
    message: state.alert.message,
    type: state.alert.type,
});

export default connect(mapStateToProps, {
    createReservation,
    getAllReservationsByPropertyId,
    updateReservation,
    getOwnerProfile,
    getCalendarByDateRange,
    clearCostOfSelectedDays,
    updateMaintenanceBooking,
    createMaintenanceBooking,
    getReservationById,
})(PropertyCalendar);
