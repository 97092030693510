import { CardHeader, Card, Col, Form, Row, Label, FormGroup } from "reactstrap";


const BlockReservationForm = ({ formData }) => {
    const correctNotes = (notes) => {
        return notes.replace(/Guesty/g, '');
    };

    return (
        <Card>
            <CardHeader className="pl-3 pr-1 pt-3 pb-3">
                <Row>
                    <Col md="6" className="pr-0">
                        <h3 className="mb-0 mt-2">Calendar Block</h3>
                    </Col>
                </Row>
            </CardHeader>
            <Form className="reservation-form pl-4 pt-2 pr-2" style={{ backgroundColor: '#f8f9fe' }}>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="startDate">
                                Start Date
                            </Label>
                            {/* <Input
                                id="startDate"
                                label="Check In"
                                placeholder="MM/DD/YYYY"
                                name="startDate"
                                value={formData.startDate}
                                plaintext
                            /> */}
                            <p className="mb-0 pt-1 mt-2">{formData.startDate}</p>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="endDate">
                                End Date
                            </Label>
                            {/* <Input
                                id="endDate"
                                name="endDate"
                                value={formData.endDate}
                                plaintext
                            /> */}
                            <p className="mb-0 pt-1 mt-2">{formData.endDate}</p>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup className="mt--2">
                            <Label for="notes">
                                Type 
                            </Label>
                            {/* <FormInput
                                id="notes"
                                name="notes"
                                className="mt-3"
                                value={formData.notes}
                                rows={3}
                                readonly={true}
                            /> */}
                            <p className="mb-0 pt-1 mt-2">{correctNotes(formData.notes)}</p>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default BlockReservationForm;
