import { propertyConstants } from '../constants';


const initialState = {
    dashboard: null,
    allProperties: null,
    property: {},
    incomes: [],
    finances: {},
    propertyCares: [],
    propertyCareUrl: ''
};

export function property(state = initialState, action) {
    switch (action.type) {
        case propertyConstants.GET_DASHBOARD:
            return {
                ...state,
                dashboard: action.payload
            };
        case propertyConstants.GETALL_SUCCESS:
            return {
                ...state,
                allProperties: action.payload
            };
        case propertyConstants.GET_PROPERTY_BY_ID:
            return {
                ...state,
                property: action.payload
            };
        case propertyConstants.GET_INCOMES:
            return {
                ...state,
                incomes: action.payload
            };
        case propertyConstants.GET_FINANCES:
            return {
                ...state,
                finances: action.payload
            };
        case propertyConstants.UPDATE_PROPERTY_DETAILS:
            return {
                ...state,
                property: action.payload
            };
        case propertyConstants.GET_PROPERTY_CARE_URL:
            return {
                ...state,
                propertyCareUrl: action.payload,
            };
        case propertyConstants.GETALL_PROPERTY_CARES:
            return {
                ...state,
                propertyCares: action.payload,
            };
        case propertyConstants.CLEAR_PROPERTY_CARE_URL:
            return {
                ...state,
                propertyCareUrl: '',
            };
        case propertyConstants.CLEAR:
            return {
                ...state,
                allProperties: null,
                property: {}
            };
        default:
            return state;
    }
}


