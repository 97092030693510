export const globalConstants = {
  experienceList: [
    {
      label:
        "I’ve managed a short-term rental or long-term rental in the past.",
      value: "1",
    },
    {
      label:
        "I currently manage my own rentals, but I want to test a property with Hoste to see what short-term rentals are all about.",
      value: "2",
    },
    {
      label: "I’ve used a short-term rental management company in the past.",
      value: "3",
    },
    { label: "I’ve used long-term rental management in the past.", value: "4" },
    { label: "I’m brand new to any form of property management.", value: "5" },
    { label: "I am currently using Hoste!", value: "6" },
  ],

  MONTHS: [
    'January', 'February',
    'March', 'April',
    'May', 'June',
    'July', 'August',
    'September', 'October',
    'November', 'December'
  ],
  // TYPE_OF_TIN: [
  //   { label: 'Individual/Sole proprietor', value: 'Individual/Sole proprietor' , name: 'recipient_tin_type'},
  //   { label: 'C Corporation', value: 'C Corporation' , name: 'recipient_tin_type'},
  //   { label: 'S Corporation', value: 'S Corporation' , name: 'recipient_tin_type'},
  //   { label: 'Partnership', value: 'Partnership', name: 'recipient_tin_type' },
  //   { label: 'Trust/estate', value: 'Trust/estate' , name: 'recipient_tin_type'},
  //   { label: 'LLS (Single member)', value: 'LLS (Single member)' , name: 'recipient_tin_type'},
  //   { label: 'LLS (C Corporation)', value: 'LLS (C Corporation)', name: 'recipient_tin_type' },
  //   { label: 'LLS (S Corporation)', value: 'LLS (S Corporation)' , name: 'recipient_tin_type'},
  //   { label: 'LLS (Partnership)', value: 'LLS (Partnership)' , name: 'recipient_tin_type'},
  //   { label: 'Exempt payer', value: 'Exempt payer', name: 'recipient_tin_type' }
  // ],
  TYPE_OF_TIN: [
    'Individual/Sole proprietor',
    'C Corporation',
    'S Corporation',
    'Partnership',
    'Trust/estate',
    'LLS (Single member)',
    'LLS (C Corporation)',
    'LLS (S Corporation)',
    'LLS (Partnership)',
    'Exempt payer'
  ]
};

export const CSRFToken = 'ooDQJHYNBHse1PWG78BVBndaYuLAqYoTGF5DB6h2GVHl10irSEXVwe3r0Z6FWvXJ';
