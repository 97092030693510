import PropTypes from "prop-types";
import {Col, Container, Row} from "reactstrap";
import TrafficCardSolo from "components/Forms/TrafficCardSolo.js";
import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getAllProperties, getFinancials} from "app/actions";


const DashboardHeader = () => {
    const dashboardData = useSelector(state => state.property.dashboard);

    const isLoading = useSelector(state => state.loading.isLoading);
    const [currentMonthMetrics, setMetrics] = useState({
        netIncomes: 0,
        reservationsCount: 0,
        completedTasks: 0,
        occupancy: 0 + '%'
    });

    const month = new Date().getMonth();

    let monthNames = [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
    ];
    const firstImg = <img
        src="https://ownerportaldev.s3.amazonaws.com/Files/question-circle.svg"
        style={{width: "10px", marginLeft: "0.2rem"}}
     alt=""></img>

    useEffect(() => {
        if (dashboardData && Object.keys(dashboardData).length) {
            const {
                total_current_month_net_income,
                current_month_reservations_number,
                current_month_completed_properties_tasks,
                current_month_occupancy
            } = dashboardData;

            setMetrics({
                netIncomes: total_current_month_net_income,
                reservationsCount: current_month_reservations_number,
                completedTasks: current_month_completed_properties_tasks,
                occupancy: current_month_occupancy + '%'
            });
        }
    }, [dashboardData]);

    useEffect(() => {
        isLoading && invalidateValues();
    }, [isLoading]);

    const invalidateValues = () => {
        setMetrics({
            netIncomes: 0,
            reservationsCount: 0,
            completedTasks: 0,
            occupancy: 0 + '%'
        });
    };

    return (
        <>
            <div>
                <Container fluid>
                    <div className="header-body">
                        <Row className="header-body-row">
                            <Col>
                                <TrafficCardSolo name="tooltip789789671"
                                                 title={monthNames[month] + " Net Income"}
                                                 img={firstImg}
                                                 value={"$" + currentMonthMetrics.netIncomes.toLocaleString('en-US')}
                                                 ico="fas fa-comment-dollar"
                                                 bgcolor="bg-traffic1"
                                />
                            </Col>

                            <Col>
                                <TrafficCardSolo
                                    name="tooltip789789672"
                                    title={monthNames[month] + " Occupancy"}
                                    img={firstImg}
                                    value={currentMonthMetrics.occupancy}
                                    ico="fas fa-house-user"
                                    bgcolor="bg-traffic1"
                                />
                            </Col>
                            <Col>
                                <TrafficCardSolo
                                    name="tooltip789789673"
                                    title={monthNames[month] + " New Reservations"}
                                    img={firstImg}
                                    value={currentMonthMetrics.reservationsCount.toLocaleString('en-US')}
                                    ico="ni ni-single-02"
                                    bgcolor="bg-traffic1"
                                />
                            </Col>
                            <Col>
                                <TrafficCardSolo
                                    name="tooltip789789674"
                                    title={monthNames[month] + " Tasks Completed"}
                                    img={firstImg}
                                    value={currentMonthMetrics.completedTasks.toLocaleString('en-US')}
                                    ico="fas fa-broom"
                                    bgcolor="bg-traffic1"
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

DashboardHeader.propTypes = {
    padding: PropTypes.string,
};

const mapStateToProps = (state) => ({
    allProperties: state.property.allProperties,
    incomes: state.financials.incomes?.total,
});

export default connect(mapStateToProps, {
    getAllProperties,
    getFinancials,
})(DashboardHeader);
