const CustomDropdown = ({ options, styles, onChange, value, defaultValue, name }) => {
    const generateKey = () => {
        return (Math.random() + 1).toString(36).substring(2);
    };

    return (
        <span onChange={onChange} value={value}>
            <select className={styles} name={name} onChange={onChange} value={value}>
                {options.map((option, idx) =>
                    <option key={generateKey()} value={option === defaultValue ? '' : option}
                        defaultValue={idx === defaultValue && 'selected'}>{option}
                    </option>
                )}
            </select>
        </span>
    )
};

export default CustomDropdown;