import { useState } from "react";

export const useForm = (initialState = {}) => {
    const [formData, setFormData] = useState(initialState);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, [e.target.name]: { ...formData[e.target.name], [e.target.value]: e.target.checked } })
    };

    const handleSingleCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]:  e.target.checked } )
    };

    const resetForm = () => {
        setFormData(initialState);
    };

    return { formData, handleInputChange, handleCheckboxChange, handleSingleCheckbox, setFormData, resetForm };
}