import { FormGroup, InputGroup, Input } from "reactstrap";

function FormInput(props) {

  return (
    <>
        <label htmlFor={props.id}>{props.label}</label>
        <FormGroup>
            <InputGroup className="input-group-merge">
            <Input
                {...props} 
            />
            </InputGroup>
        </FormGroup>
    </>
  );
}

export default FormInput;
