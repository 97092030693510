import Chart from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { chartOptions, colors, parseOptions } from "variables/charts.js";
import moment from "moment";

const LineChart = () => {
    const dashboardData = useSelector(state => state.property.dashboard);
    const [chartData, setChartData] = useState(null);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(moment().month());
    const currentYear = moment().year();

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    useEffect(() => {
        var ctx = document.getElementById('chart-sales').getContext("2d")
        const gradient = ctx.createLinearGradient(0, 0, 0, 400)
        gradient.addColorStop(0, 'rgba(229, 239, 255, 1)')
        gradient.addColorStop(1, '#FFFFFF')
        if (dashboardData && Object.keys(dashboardData).length) {
            const data = Object.values(dashboardData.net_income_by_months);
            const startMonthIndex = Math.floor(selectedMonthIndex / 3) * 3;
            const endMonthIndex = Math.min(startMonthIndex + 2, 11);
            const maxYValueTotal = Math.ceil(Math.max(...data) / 1000) * 1000
            const maxYValue = Math.ceil(maxYValueTotal + maxYValueTotal / 20)

            let chartObject = {
                options: {
                    responsive: true,
                    aspectRatio: 1,
                    scales: {
                        yAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true,
                                max: maxYValue,
                                callback: function (value) {
                                    return '$' + value.toLocaleString('en-US')
                                }
                            }
                        }],
                        xAxes: [{
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                fontSize: 16,
                                fontStyle: 'bold',
                                fontColor: 'black'
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return '$' + value.toLocaleString('en-US');
                            }
                        }
                    }
                },
                data: {
                    labels: months.slice(startMonthIndex, endMonthIndex + 1),
                    datasets: [{
                        data: data.slice(startMonthIndex, endMonthIndex + 1),
                        pointHitRadius: 40,
                        pointHoverRadius: 5,
                        pointHoverBorderColor: '#5e72e4',
                        backgroundColor: '#5e72e4',
                    }],
                },
            };

            setChartData(chartObject);
        }
    }, [dashboardData, selectedMonthIndex]);

    useEffect(() => {
        if (chartData) {
            const newData = Object.values(dashboardData.net_income_by_months);
            const startMonthIndex = Math.floor(selectedMonthIndex / 3) * 3;
            const endMonthIndex = Math.min(startMonthIndex + 2, 11);

            const updatedData = newData.slice(startMonthIndex, endMonthIndex + 1);
            const updatedLabels = months.slice(startMonthIndex, endMonthIndex + 1);

            setChartData(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    labels: updatedLabels,
                    datasets: [{
                        ...prevState.data.datasets[0],
                        data: updatedData,
                    }],
                },
            }));
        }
    }, [selectedMonthIndex]);

    const handlePreviousMonths = () => {
        setSelectedMonthIndex(prevIndex => Math.max(0, prevIndex - 3));
    };

    const handleNextMonths = () => {
        setSelectedMonthIndex(prevIndex => Math.min(prevIndex + 3, months.length - 1))
    };

    const barLabelPlugin = {
        afterDatasetsDraw: function (chart) {
            const ctx = chart.ctx;
            chart.data.datasets.forEach((dataset, i) => {
                const meta = chart.getDatasetMeta(i);
                if (!meta.hidden) {
                    meta.data.forEach((bar, index) => {
                        const data = dataset.data[index];
                        ctx.fillStyle = 'black';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                        const fontSize = 16;
                        const fontStyle = 'bold';
                        const fontFamily = 'Open Sans, sans-serif';
                        ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
                        const label = '$' + data.toLocaleString('en-US');

                        const padding = 2;
                        let yPos = bar._model.y;

                        // Adjust y-position for zero values to display label above the bar
                        if (data !== 0) {
                            yPos = Math.min(yPos, chart.height - fontSize - padding);
                        }

                        ctx.fillText(label, bar._model.x, yPos);
                    });
                }
            });
        }
    };


    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <h5 className="h3 mb-0 text-center">{currentYear}</h5>
                            <h5 className="h3 mb-4 text-center">Earnings Overview</h5>
                            <div className="d-flex justify-content-between">
                                <Button onClick={handlePreviousMonths}
                                        disabled={selectedMonthIndex === 0}>&laquo; Prev</Button>
                                <Button onClick={handleNextMonths}
                                        disabled={selectedMonthIndex === months.length - 3}>Next &raquo;</Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Bar
                                    data={chartData?.data}
                                    height={null}
                                    options={chartData?.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                    plugins={[barLabelPlugin]}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default LineChart;
