import { Alert, FormText } from "reactstrap";

const Submitted = ({ bodyText, type }) => {
    return (
        <div>
            <Alert color="">
                <FormText
                    color={type === 'alert-success' ? '' : 'red'}
                    style={{ fontWeight: 'bold', fontSize: '0.90rem' }}
                >{bodyText}
                </FormText>
            </Alert>
        </div>
    )
}

export default Submitted;