import { financialsConstants } from 'app/constants/financials.constants';

const initialState = {
    incomes: {
        total: null,
        property: null,
        year_income: null,
        occupancy_in_percent:null
    }
};

export function financials(state = initialState, action) {
    switch (action.type) {
        case financialsConstants.GET_FINANCIALS:
            return {
                ...state,
                incomes: {
                    ...state,
                    total: action.payload
                }
            };
        case financialsConstants.GET_FINANCIALS_BY_PROPERTY:
                return {
                    ...state,
                    incomes: {
                        ...state,
                        property: action.payload
                    }
                };
        case financialsConstants.CLEAR:
            return {
                incomes: {
                    total: null,
                    property: null
                }
            };

        default:
            return state;
    }
}