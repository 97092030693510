import { FormGroup, Input, InputGroup, Label } from "reactstrap";


const Checkbox = ({ label, type = 'checkbox', options, onChange, name, value }) => {
    return (
        <>
            <label>{label}</label>
            <FormGroup className="ml-2 mt-3 ml-4">
                {
                    options.map(item => (
                        <InputGroup className="input-group-merge mt-2" key={item.label}>
                            <Label className="mr-2 ">
                                <Input
                                    type={type}
                                    name={name}
                                    value={value}
                                    checked={item.isChecked}
                                    onChange={onChange}
                                />
                                {item.label}
                            </Label>
                        </InputGroup>
                    ))
                }
            </FormGroup>
        </>
    );
}
export default Checkbox;
