/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import OnboardingNavbar from "components/Navbars/OnboardingNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import OnboardingHeader from "components/Headers/OnboardingHeader.js";
import GlobalBg from "components/Forms/GlobalBg.js";
import routes from "routes.js";
import { useRef, useState, useEffect } from "react";
import { getOwnerProfile, getAllProperties } from "app/actions";
import Widget from "./Widget";

function Onboarding({ user, properties, ownerProfile, property }) {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const mainContentRef = useRef(null);
  const history = useHistory();
  const [routeshow, setRouteshow] = useState("");

  useEffect(() => {
    if (!user) history.push("/login");
    // delete it where onboarding requiremenets is ready
    history.push("/owner/dashboard");

    // const unfinishedProperty = handleProperty(properties, "in progress");
    // const completedProperty = handleProperty(properties, "completed");

    // if (!unfinishedProperty && completedProperty) {
    //   history.push("/owner/dashboard");
    // } else if (unfinishedProperty) {
    //   setRouteshow("onboarding");

    //   if (unfinishedProperty) {
    //     localStorage.setItem("id_for_fetch_property", unfinishedProperty.id);

    //     dispatch({
    //       type: propertyConstants.GET_PROPERTY_BY_ID,
    //       payload: unfinishedProperty,
    //     });
    //   }
    // }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location, user, history, properties, ownerProfile, property]);

  useEffect(() => {
    // dispatch(getAllProperties());
    // dispatch(getOwnerProfile());
  }, []);

  useEffect(() => {
    if (property && Object.keys(property).length === 0) {
      if (ownerProfile && Object.keys(ownerProfile).length === 0) history.push("/onboarding/step11");
    }
  }, [property, ownerProfile]);

  function handleProperty(properties, status) {
    if (properties) {
      const unfinishedProperty = properties.filter(
        (property) => property.status === status
      )[0];
      return unfinishedProperty ? unfinishedProperty : null;
    }
    return null;
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/onboarding") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const getStep = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].step;
      }
    }
    return "1";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf("owner/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      {/* <Widget /> */}
      <Sidebar
        routes={routes ? routes : "onboarding"}
        routeshow={routeshow}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("assets/img/main-logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <div className="onboarding-wrap">
          <GlobalBg />
          <OnboardingNavbar
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
          />
          <OnboardingHeader
            name="Hello "
            step={parseInt(getStep(location.pathname))}
          >
            This is your owner page. Let's complete your profile first so that
            we can start helping you with your property.
          </OnboardingHeader>
        </div>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/onboarding/step11" />
        </Switch>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  ownerProfile: state.user.ownerProfile,
  properties: state.property.allProperties,
  property: state.property.property,
});

export default connect(mapStateToProps, { getOwnerProfile, getAllProperties })(
  Onboarding
);
