import { financialsConstants } from "app/constants";

export const getFinancials = () => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key
        }
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/financials/`,
        requestOptions
      );
      const financials = await response.json();

      if (response.ok) {
        dispatch({
          type: financialsConstants.GET_FINANCIALS,
          payload: financials
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const getFinancialsByPropertyId = (id) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key
        }
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/financials/${id}`,
        requestOptions
      );
      const financials = await response.json();

      if (response.ok) {
        dispatch({
          type: financialsConstants.GET_FINANCIALS_BY_PROPERTY,
          payload: financials
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};