import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Confirmation = ({ isOpen, title, body, handleDisapproval, handleConfirmation, toggle }) => {
    return (
        <div onClick={() => toggle(!isOpen)}>
            <Modal isOpen={isOpen} onClick={() => toggle(!isOpen)}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>{body}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleDisapproval}>No</Button>
                    <Button color="danger" onClick={handleConfirmation}>Yes</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
};

export default Confirmation;