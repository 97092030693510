export const reservationConstants = {
    CREATE_RESERVATION_SUCCESS: 'CREATE_RESERVATION_SUCCESS',
    GET_ALL_RESERVATIONS_BY_PROPERTY_ID: 'GET_ALL_RESERVATIONS_BY_PROPERTY_ID',
    GET_RESERVATION_BY_ID: 'GET_RESERVATION_BY_ID',
    GET_ALL_RESERVATIONS: 'GET_ALL_RESERVATIONS',
    UPDATE_RESERVATION: 'UPDATE_RESERVATION',
    CREATE_MAINTENANCE_RESERVATION: 'CREATE_MAINTENANCE_RESERVATION',
    RETREIVE_CALENDAR_FOR_SETTINGS: 'RETREIVE_CALENDAR_FOR_SETTINGS',
    RETREIVE_CALENDAR_FOR_COST: 'RETREIVE_CALENDAR_FOR_COST',
    CLEAR_CALENDAR: 'CLEAR_CALENDAR',
    CLEAR: 'CLEAR_RESERVATION',

    status: {
        confirmed: 'confirmed',
        progress: 'in progress',
        canceled: 'canceled',
        unavailable: 'unavailable',
        available: 'available'
    },

    groupedOptions: [
        {
            group: 'Reservation',
            header: 'Status',
            accessor: 'status',
            isShown: true
        },
        {
            group: 'Reservation',
            header: 'Property Nickname',
            accessor: 'property.entityName',
            isShown: false
        },
        {
            group: 'Reservation',
            header: 'Property Address',
            accessor: 'property.streetAddress',
            isShown: false
        },
        {
            group: 'Reservation',
            header: 'Guest Name',
            accessor: 'guest.firstName',
            isShown: true
        },
        {
            group: 'Reservation',
            header: 'Number of Guests',
            accessor: 'guestsCount',
            isShown: true
        },
        {
            group: 'Reservation',
            header: 'Reservation ID',
            accessor: 'confirmationCode',
            isShown: false
        },
        {
            group: 'Reservation',
            header: 'Platform',
            accessor: 'platform',
            isShown: true
        },
        {
            group: 'Reservation',
            header: 'Number of Nights',
            accessor: 'nightsCount',
            isShown: true,
        },
        {
            group: 'Reservation',
            header: 'Check-in Date',
            accessor: 'startDate',
            isShown: true
        },
        {
            group: 'Reservation',
            header: 'Check-out Date',
            accessor: 'endDate',
            isShown: true
        },
        {
            group: 'Reservation',
            header: 'Confirmation Date',
            accessor: 'confirmedAt',
            isShown: false
        },
        {
            group: 'Reservation',
            header: 'Revenue',
            accessor: 'netIncome',
            isShown: false
        },
        {
            group: 'Reservation',
            header: 'Management Fee',
            accessor: 'commission',
            isShown: false
        },
        {
            group: 'Reservation',
            header: 'Net Income',
            accessor: 'revenue',
            isShown: false
        },
    ],
};