export const propertyConstants = {
    GET_DASHBOARD: 'GET_DASHBOARD',
    GETALL_SUCCESS: 'GET_ALL_PROPERTIES',
    GET_PROPERTY_BY_ID: 'GET_PROPERTY_BY_ID',
    GET_INCOMES: 'GET_INCOMES',
    GET_FINANCES: 'GET_FINANCES',
    UPDATE_STATUS: 'UPDATE_STATUS',
    UPDATE_PROPERTY_DETAILS: 'UPDATE_PROPERTY_DETAILS',
    CLEAR: 'CLEAR',

    status: {
        completed: 'completed',
        progress: 'in progress'
    }
};

