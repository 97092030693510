import { Col, Form, Label, Row, FormGroup, Card, CardHeader } from "reactstrap";


const GuestyReservationForm = ({ formData }) => {

    function capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    };

    return (
        <>
            <Card>
                <CardHeader className="pl-3 pr-1 pt-3 pb-3">
                    <Row>
                        <Col md="6">
                            <h3 className="mb-0 mt-2">Guest Reservation</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <Form className="reservation-form pl-4 pt-2" style={{ backgroundColor: '#f7fafc' }}>
                    <FormGroup row>
                        <Label
                            for="firstName"
                            sm={6}
                        >
                            Guest Name
                        </Label>
                        <Col>
                        <p className="mb-0 pt-1 mt-2">{capitalizeFirstLetter(formData.guest?.firstName)}</p>
                        </Col>
                    </FormGroup>

                    <Row className="mt--2">
                        <Col md="6" className="pl-3 pr-1">
                            <Label for="nightsCount">
                                Number of Nights
                            </Label>
                            <p className="mb-0 pt-1 mt-2">{formData.nightsCount}</p>
                        </Col>

                        <Col md="6" className="pl-3 pr-1">
                            <Label for="nightsCount">
                                Number of Guests
                            </Label>
                            <p className="mb-0 pt-1 mt-2">{formData.guestsCount}</p>
                        </Col>
                    </Row>

                    <FormGroup row className="mt-3">
                        <Label
                            for="revenue"
                            sm={6}
                        >
                            Revenue
                        </Label>
                        <Col>
                            <p className="mb-0 pt-1 mt-2">${formData.netIncome ? formData.netIncome : 0}</p>
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mt--3">
                        <Label
                            for="managementFee"
                            sm={6}
                        >
                            Management Fee
                        </Label>
                        <Col>
                            <p className="mb-0 pt-1 mt-2">${formData.commission ? formData.commission : 0}</p>
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mt--3">
                        <Label
                            for="revenue"
                            sm={6}
                        >
                            Your Net Income
                        </Label>
                        <Col>
                            <p className="mb-0 pt-1 mt-2">${formData.revenue ? formData.revenue : 0}</p>
                        </Col>
                    </FormGroup>
                </Form>
            </Card>
        </>
    );
};

export default GuestyReservationForm;
