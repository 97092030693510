import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

function TrafficCards({
  title,
  value,
  subtitle,
  subvalue,
  uppercent,
  description,
  ico,
  bgcolor,
  img,
  subimg,
}) {
  return (
    <>
      <Card className="card-stats card-container">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
                // id="tooltip147147855"
              >
                {title}
                {img}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{value}</span>
            </div>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
                // id="tooltip147147856"
              >
                {subtitle}
                {subimg}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{subvalue}</span>
            </div>
            {/* <UncontrolledTooltip
              //   placement={"bottom"}
              delay={0}
              trigger="hover focus"
              target="tooltip147147855"
              style={{ maxWidth: "30rem" }}
            >
              This value represents the net income for all properties and all of
              the check-ins that you currently have for the month. Note: This is
              just an estimation until this month is finalized.
            </UncontrolledTooltip>
            <UncontrolledTooltip
              //   placement={"bottom"}
              delay={0}
              trigger="hover focus"
              target="tooltip147147856"
              style={{ maxWidth: "30rem" }}
            >
              This value represents the estimated net income total of all reservations that checked in for the current year. Note: This value will change due to new reservations, cancellations, refunds, or alterations.
            </UncontrolledTooltip> */}
            <Col className="col-auto">
              <div
                className={
                  "icon icon-shape text-white rounded-circle shadow " + bgcolor
                }
              >
                <i className={ico} />
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-sm">
            {uppercent ? (
              <span className="text-success mr-2">
                <i className="fa fa-arrow-up" /> {uppercent}
              </span>
            ) : (
              <span className="text-success mr-2">
                {/* <i className="fa fa-arrow-up" /> {uppercent} */}
              </span>
            )}
            <span className="text-nowrap">{description}</span>
          </p>
        </CardBody>
      </Card>
    </>
  );
}

TrafficCards.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  subtitle: PropTypes.string,
  subvalue: PropTypes.string,
  uppercent: PropTypes.string,
  description: PropTypes.string,
  ico: PropTypes.string,
  bgcolor: PropTypes.string,
};

export default TrafficCards;
