/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Elements from "views/pages/forms/Elements.js";
import Map from "views/pages/dashboards/Map.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/auth/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Properties from "views/pages/dashboards/Properties.js";
import Owners from "views/pages/dashboards/Owners.js";
import PropertyDetails from "views/pages/owners/PropertyDetails.js";
import PropertyDocuments from "views/pages/owners/PropertyDocuments.js";
import PropertyCalendar from "views/pages/owners/PropertyCalendar.js";
import PropertyCare from "views/pages/owners/PropertyCare.js";
import ConfirmedReservation from "layouts/ConfirmedReservation";
import ResetPassword from "layouts/ResetPassword";
import Terms from "layouts/Terms";
import PrivacyPolicy from "layouts/PrivacyPolicy";
import Widgets from "views/pages/Widgets";
// import Analytics from "views/pages/financials/Analytics";
import ReservationFeed from "views/pages/financials/ReservationFeed";
import LineChart from "components/Graphs/LineGraph";
import Dashboard from "views/pages/dashboards/Dashboard";
import ManagementTerms from "layouts/ManagementTerms";




const routes = [
  {
    collapse: true,
    name: "Properties",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/properties",
        name: "Properties",
        miniName: "D",
        component: Properties,
        layout: "/owner",
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        miniName: "A",
        component: Alternative,
        layout: "/owner",
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    show: "owner",
    component: Dashboard,    
    layout: "/owner",
  },
  {
    path: "/accounts/reset/:uid?/:token?",
    icon: "ni ni-tv-2",
    name: "reset-password",
    component: ResetPassword,
    layout: "/landing",
  },
  {
    path: "/terms-of-service",
    name: "Terms",
    icon: "ni ni-tv-2",
    component: Terms,
    layout: "/terms-of-service",
  },
  {
    path: "/terms",
    name: "ManagementTerms",
    icon: "ni ni-tv-2",
    component: ManagementTerms,
    layout: "/terms",
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    icon: "ni ni-tv-2",
    component: PrivacyPolicy,
    layout: "/privacy-policy",
  },
  {
    path: "/properties",
    icon: "fas fa-home",
    name: "Properties",
    show: "owner",
    component: Properties,
    layout: "/owner",
  },
  {
    path: "/property-details/:id",
    icon: "ni ni-tv-2",
    name: "Property Details",
    component: PropertyDetails,
    layout: "/owner",
  },
  {
    path: "/property-document/:id",
    icon: "ni ni-tv-2",
    name: "Property Documents",
    component: PropertyDocuments,
    layout: "/owner",
  },
  {
    path: "/property-calendar/:id",
    icon: "ni ni-tv-2",
    name: "Calendar",
    component: PropertyCalendar,
    layout: "/owner",
  },
  {
    path: "/property-care/:id",
    icon: "ni ni-tv-2",
    name: "Property care",
    component: PropertyCare,
    layout: "/owner",
  },
  {
    path: "/confirmed-info/:propertyId",
    icon: "ni ni-tv-2",
    name: "ConfirmedReservation",
    component: ConfirmedReservation,
    layout: "/confirmed-info",
  },  
  {
    path: "/financial/reservation-feed/",
    icon: "ni ni-single-02",
    name: "Reservations",
    show: "owner",
    component: ReservationFeed,
    layout: "/owner",
  },
  // {
  //   path: "/financial/analytics/",
  //   icon: "ni ni-chart-pie-35",
  //   name: "Analytics - Coming Soon!",
  //   show: "owner",
  //   component: Analytics,
  //   layout: "/owner",
  // },
  {
    path: "/map",
    name: "Map",
    icon: "ni ni-pin-3",
    component: Map,
  },
  {
    path: "/owners",
    icon: "ni ni-single-02",
    name: "Owner profiles",
    component: Owners,
  },
  // {
  //   path: "/add-property",
  //   name: "Property Info",
  //   icon: "ni ni-single-02",
  //   component: OwnerInfo,
  //   layout: "/onboarding",
  //   step: 2,
  // },
  // {
  //   path: "/step11",
  //   name: "Owner Profile",
  //   icon: "ni ni-single-02",
  //   component: OwnerProfile,
  //   layout: "/onboarding",
  //   show: "",
  //   step: 1,
  // },
  // {
  //   // path: "/step112",
  //   name: "Owner Profile",
  //   icon: "ni ni-single-02",
  //   component: OwnerProfileIfOwner,
  //   layout: "/profile",
  //   // show: "onboardingOwner",
  //   show: "onboarding",
  //   step: 0,
  // },

  // {
  //   path: "/step21",
  //   name: "Property Info",
  //   icon: "ni ni-single-02",
  //   component: OwnerInfo,
  //   layout: "/onboarding",
  //   step: 2,
  // },
  // {
  //   path: "/step22",
  //   name: "Pay & Taxes",
  //   icon: "ni ni-single-02",
  //   component: OwnerPayTaxes,
  //   layout: "/onboarding",
  //   step: 2,
  // },
  // {
  //   path: "/step23",
  //   name: "Contacts",
  //   icon: "ni ni-single-02",
  //   component: OwnerContacts,
  //   layout: "/onboarding",
  //   step: 2,
  // },
  // {
  //   path: "/step31",
  //   name: "Owner Property",
  //   icon: "ni ni-single-02",
  //   component: OwnerProperty,
  //   layout: "/onboarding",
  //   step: 3,
  // },
  // {
  //   path: "/step41",
  //   name: "Owner Done",
  //   icon: "ni ni-single-02",
  //   component: Blank,
  //   layout: "/onboarding",
  //   step: 4,
  // },
  // {
  //   collapse: true,
  //   name: "Financial",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/finance",
  //       name: "Reservation Feed",
  //       miniName: "RF",
  //       component: Elements,
  //       layout: "/owner",
  //     },
  //     {
  //       path: "/finance",
  //       name: "Analytics",
  //       miniName: "C",
  //       component: Components,
  //       layout: "/owner",
  //     }
  //   ],
  // },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/landing",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/owner",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/owner",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "/rtl",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Buttons,
        layout: "/owner",
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Cards,
        layout: "/owner",
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Grid,
        layout: "/owner",
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Notifications,
        layout: "/owner",
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Icons,
        layout: "/owner",
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Typography,
        layout: "/owner",
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => { },
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => { },
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => { },
            layout: "/",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Elements,
        layout: "/owner",
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/owner",
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/owner",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        miniName: "T",
        component: Tables,
        layout: "/owner",
      },
      {
        path: "/sortable",
        name: "Sortable",
        miniName: "S",
        component: Sortable,
        layout: "/owner",
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        miniName: "RBT",
        component: ReactBSTables,
        layout: "/owner",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "ni ni-archive-2 text-green",
    component: Widgets,
    layout: "/owner",
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-info",
    component: Charts,
    layout: "/owner",
  },
  {
    path: "/charts2",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-info",
    component: LineChart,
    layout: "/owner",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/owner",
  },
  {
    path: "/",
    name: "login",
    icon: "ni ni-shop text-white",
    component: Login,
    show: false,
    layout: "/landing",
  },
];

export default routes;
