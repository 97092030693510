import { onboardingConstants } from "../constants";

const initialState = {
  step: 1,
  substep: 1,
};

export function onboarding(state = initialState, action) {
  switch (action.type) {
    case onboardingConstants.ONBOARDING_UPDATE_STEPS:
      return {
        ...state,
        step: action.step,
        substep: action.substep,
      };
    default:
      return state;
  }
}

const initialStateProperty = {
  stateInfo: {}
};

export function onboardingProperty(state = initialStateProperty, action) {
  switch (action.type) {
    case onboardingConstants.ONBOARDING_ADD_PROPERTY_INFO:
      return {
        ...state,
        stateInfo: action.payload,
      };
    case onboardingConstants.CLEAR:
      return {
        ...state,
        stateInfo: {}
      };

    default:
      return state;
  }
}
