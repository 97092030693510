/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormText,
} from "reactstrap";
import FormInput from "components/Forms/FormInput.js";
import {
  createOwnerProfile,
  getOwnerProfile,
  getUser,
  changePassword
} from "app/actions";
import { connect, useDispatch } from "react-redux";
import { useForm } from "app/hooks/useForm.hook";
import Submitted from "components/Alerts/Submitted";
import Loader from "components/Loaders/Loader";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

Dropzone.autoDiscover = false;
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^\+\d{10,20}$/;

const ProfileFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .max(50, 'Max length: 50 symbols'),
  last_name: Yup.string()
    .required('This field is required')
    .max(50, 'Max length: 50 symbols'),
  primary_email: Yup.string()
    .required('This field is required')
    .email('Invalid email format'),
  secondary_email: Yup.string()
    .email('Invalid email format'),
  address: Yup.string()
    .max(256, 'Max length: 256 symbols'),
  phone: Yup.string()
  .required('This field is required')
  .matches(phoneRegExp, 'Phone number is not valid'),
  oldPassword: Yup.string(),
  password1: Yup.string()
    .when("oldPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required('This field is required')
        .min(8, 'Your password must contain at least 8 characters')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol')
        .when('oldPassword', (oldPassword, schema) => {
          return schema.test({
            test: password1 => !!password1 && oldPassword !== password1,
            message: "Password is the same as the old one."
          })
        }),
    }),
  confirmPassword: Yup.string()
    .when("password1", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required('This field is required')
        .oneOf([Yup.ref("password1")], "Passwords don't match")
    })
});

function OwnerProfileIfOwner({ owner, message, user, type, userData }) {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { formData, setFormData } = useForm({
    first_name: "",
    last_name: "",
    primary_email: "",
    secondary_email: "",
    address: "",
    picture: "",
    phone:""
  });

  useEffect(() => {
    if (owner) {
      setFormData(owner);
      setLoading(false);
    } else {
      dispatch(getOwnerProfile());
    };

    message && setLoading(false);
  }, [owner, message, type, user, userData]);

  useEffect(() => {
    if (!userData) {
      setLoading(true);
      dispatch(getUser());
    }
  }, []);

  const onChangePhoto = (e) => {
    let files = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(files);
    let img1 = document.getElementById("img1");

    reader.onload = (e) => {
      img1.src = e.target.result;
      formData.picture = e.target.result;
    };
  };

  return (
    <Container className="mt--6 owner-profile-form">
      <Row>
        <Col lg="8">
          <div className="card-wrapper mb-5">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <h3 className="mt-2">My Owner Profile</h3>
                  </Col>
                </Row>
                {message && (
                  <div className="text-center ml-10">
                    <Submitted bodyText={message} type={type} />
                  </div>
                )}
              </CardHeader>
              <CardBody className="bg-custom">
                {isLoading ? (
                  <Row className="justify-content-center">
                    <Loader />
                  </Row>
                ) : (
                  <Formik
                    initialValues={{
                      ...formData,
                      confirmPassword: '',
                      password1: '',
                      oldPassword: '',
                    }}
                    enableReinitialize={true}
                    validationSchema={ProfileFormSchema}
                    onSubmit={async (values) => {
                      setLoading(true);
                      if (values.oldPassword !== '') {
                        const response = await dispatch(changePassword({
                          email: formData.primary_email,
                          oldPassword: values.oldPassword,
                          newPassword: values.password1
                        }));
                        response.ok && dispatch(createOwnerProfile(values, formData.picture));
                      } else {
                        dispatch(createOwnerProfile(values, formData.picture));
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <h4>Name</h4>
                          </Col>
                        </Row>
                        <Row className="pl-3">
                          <Col md="6">
                            <FormInput
                              id="first_name"
                              label="First name"
                              placeholder="First name"
                              name="first_name"
                              type="text"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.first_name && touched.first_name}
                              maxLength={51}
                            />

                            {errors.first_name && touched.first_name
                              && <FormText className="helper-message mt--3 mb-2">{errors.first_name}</FormText>}
                          </Col>
                          <Col md="6">
                            <FormInput
                              id="last_name"
                              label="Last name"
                              placeholder="Last name"
                              name="last_name"
                              type="text"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.last_name && touched.last_name}
                              maxLength={51}
                            />

                            {errors.last_name && touched.last_name
                              && <FormText className="helper-message mt--3 mb-2">{errors.last_name}</FormText>}
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h4>Contact Information</h4>
                          </Col>
                        </Row>

                        <Row className="pl-3">
                        <Col  md="12">
                            <FormInput
                              id="phone"
                              label="Phone"
                              placeholder="+14155552671"
                              name="phone"
                              required
                              type="phone"
                              value={values.phone}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              invalid={errors.phone && touched.phone}
                              maxLength={20}
                            />

                            {errors.phone && touched.phone
                              && <FormText className="helper-message mt--3 mb-2">{errors.phone}</FormText>}
                          </Col>
                          </Row>
                          <Row className="pl-3">
                          <Col md="6">
                            <FormInput
                              id="primary-email"
                              label="Primary email address"
                              placeholder="Email address"
                              name="primary_email"
                              required
                              type="email"
                              value={values.primary_email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.primary_email && touched.primary_email}
                              maxLength={51}
                            />

                            {errors.primary_email && touched.primary_email
                              && <FormText className="helper-message mt--3 mb-2">{errors.primary_email}</FormText>}
                          </Col>
                          <Col md="6">
                            <FormInput
                              id="secondary-email"
                              label="Secondary email address"
                              placeholder="Email address"
                              name="secondary_email"
                              type="email"
                              value={values.secondary_email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.secondary_email && touched.secondary_email}
                              maxLength={51}
                            />

                            {errors.secondary_email && touched.secondary_email
                              && <FormText className="helper-message mt--3 mb-2">{errors.secondary_email}</FormText>}
                          </Col>
                          <Col md="12">
                            <FormInput
                              id="address"
                              label="Mailing address"
                              placeholder="Address"
                              name="address"
                              required
                              type="text"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.address && touched.address}
                              maxLength={257}
                            />

                            {errors.address && touched.address
                              && <FormText className="helper-message mt--3 mb-2">{errors.address}</FormText>}
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h4>Password change</h4>
                          </Col>
                        </Row>

                        <Row className="pl-3">
                          <p className="pb-3">Please enter your old password, for security’s sake,
                            and then enter your new password twice so we can verify you typed it in correctly.
                          </p>

                          <Col md="6">
                            <FormInput
                              id="oldPassword"
                              label="Old password"
                              name="oldPassword"
                              type="password"
                              value={values.oldPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.oldPassword && touched.oldPassword}
                              size="50"
                              placeholder="Old password"
                            />

                            {errors.oldPassword && touched.oldPassword
                              && <FormText className="helper-message mt--3 mb-2">{errors.oldPassword}</FormText>}
                          </Col>
                          <Col md="6">
                            <FormInput
                              id="password1"
                              label="New password"
                              name="password1"
                              type="password"
                              value={values.password1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.password1 && touched.password1}
                              size="50"
                              placeholder="New password"
                            />

                            {errors.password1 && touched.password1
                              && <FormText className="helper-message mt--3 mb-2">{errors.password1}</FormText>}
                          </Col>
                          <Col md="12">
                            <FormInput
                              id="confirmPassword"
                              label="New password confirmation"
                              name="confirmPassword"
                              type="password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.confirmPassword && touched.confirmPassword}
                              size="50"
                              placeholder="Password confirmation"
                            />

                            {errors.confirmPassword && touched.confirmPassword
                              && <FormText className="helper-message mt--3 mb-2">{errors.confirmPassword}</FormText>}
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Button
                              className="primary-btn mt-2"
                              color="info"
                              type="submit"
                              disabled={errors.address
                                || errors.secondary_email
                                || errors.primary_email
                                || errors.first_name
                                || errors.last_name
                                || errors.confirmPassword
                                || errors.password1}
                            >
                              Save Changes
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col lg="4">
          <div className="card-wrapper profile-photo-wrap">
            <Card>
              <CardBody className="bg-white">
                <Row className="justify-content-between align-items-center mt--6">
                  <Col md="3" className="text-center pr-0"></Col>
                  <Col
                    md="6"
                    className="text-center"
                    style={{ marginTop: "6rem" }}
                  >
                    <div className="fallback">
                      <img
                        src={
                          formData.picture
                            ? formData.picture
                            : require("assets/img/Avatar.png").default
                        }
                        id="img1"
                        className="dz-preview-img"
                        data-dz-thumbnail=""
                      />

                      <input
                        style={{ display: "none" }}
                        id="projectCoverUploads"
                        type="file"
                        onChange={onChangePhoto}
                        src={formData.picture}
                      />
                      <label for="projectCoverUploads">Edit photo</label>
                    </div>
                  </Col>
                  <Col md="3" className="text-center pl-0">
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  owner: state.user.ownerProfile,
  step: state.onboarding.step,
  substep: state.onboarding.substep,
  message: state.alert.message,
  type: state.alert.type,
  userData: state.user.userData,
});

const mapDispatchToProps = {
  createOwnerProfile,
  getOwnerProfile,
  changePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerProfileIfOwner);
