import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

const FormDropdown = (props) => {
    const generateKey = () => {
        return (Math.random() + 1).toString(36).substring(2);
    };

    return (
            <Dropdown {...props}>
                <DropdownToggle caret tag="span" style={{ cursor: 'pointer' }}>
                    {props.value ? props.value : props.defaultValue}
                </DropdownToggle>
                <DropdownMenu container="body" className="menu">
                    {props.options.map((option) =>
                        <DropdownItem key={generateKey()} value={option} onClick={props.onChange} name={props.name}>
                            {option}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
    )
};

export default FormDropdown;