/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {getFilteredDocuments, uploadDocument,} from "app/actions";
import {ALL_DOC_TYPES, ALL_DOC_TYPES_DICT, documentConstants, globalConstants} from "app/constants";

import {Button, Card, CardBody, Col, Collapse, Container, Row,} from "reactstrap";
import moment from "moment";

import OwnerHeader from "components/Headers/OwnerHeader.js";
import OwnerSubNav from "components/Navbars/OwnerSubNav.js";
import FormDropdown from "components/Forms/Dropdown";
import FormModal from "components/Modals/Modal";
import FormTable from "components/Tables/Table";
import {useForm} from "app/hooks/useForm.hook";
import {UploadDocumentForm} from "components/Documents";


function PropertyDocuments() {
    const documents = useSelector(state => state.document.documents);


    const document = useSelector(state => state.document.document);

    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const id = parseInt(pathname.split("/")[3]);

    const [isLoading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentNumber, setDocumentNumber] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState({
        month: false,
        year: false,
        type: false,
    });
    const [filters, setFilters] = useState({
        propertyId: id,
        type: {
            value: ALL_DOC_TYPES_DICT.monthlyStatements
        },
        month: {
            value: documents.length > 0 ? documents[0].month : null,
            isShown: true,
        },
        year: {
            // value: moment().format("YYYY"),
            // value: null,
            value: documents.length > 0 ? documents[0].year : null,
            isShown: true,
        }
    });
    const {formData, setFormData, resetForm} = useForm({
        name: "",
        type: ALL_DOC_TYPES_DICT.monthlyStatements,
        date: null,
        notes: "",
        file_link: "",
        propertyId: id
    });

    useEffect(() => {
        documents && setLoading(false);
    }, [documents, documentNumber]);

    useEffect(() => {
        if (filters.propertyId) {
            setLoading(true);
            dispatch(getFilteredDocuments(filters));

        }
    }, [filters]);

    useEffect(() => {
        if (Object.keys(document).length) {
            setLoading(true);
            dispatch(getFilteredDocuments(filters));
        }


    }, [document]);

    useEffect(() => {
        switch (filters.type.value) {
            case ALL_DOC_TYPES_DICT.permit:
            // case ALL_DOC_TYPES_DICT.form1099:
            case ALL_DOC_TYPES_DICT.annualStatements:
                setFilters({
                    ...filters,
                    year: {...filters.year, isShown: true},
                    month: {...filters.month, isShown: false}
                });
                break;

            case ALL_DOC_TYPES_DICT.monthlyStatements:
                setFilters({
                    ...filters,
                    year: {...filters.year, isShown: true},
                    month: {...filters.month, isShown: true}
                });
                break;
            default:
                setFilters({
                    ...filters,
                    year: {...filters.year, isShown: false},
                    month: {...filters.month, isShown: false}
                });
                break;
        }
    }, [filters.type.value]);


    const getYearsList = () => {
        return Array.from(new Array(20), (_, index) =>
            Math.abs(index - new Date().getFullYear())
        );
    };

    const handleSelectChange = (event) => {
        const {name, value} = event.target;

        setFilters({
            ...filters,
            [name]: {...filters[name], value}
        });
    };

    const onUpload = (data) => {
        dispatch(uploadDocument({...data, propertyId: formData.propertyId}));
        setIsModalOpen(false);
        resetForm();
    };

    function openInNewTab(url) {
        window.open(url, '_blank');
    }

    const onClickHandler = (idx) => {
        setDocumentNumber(idx);
    };

    return (
        <div>
            <div className="dashboard-header">
                <OwnerHeader/>
            </div>
            <OwnerSubNav curPage="Documents"/>
            <Container fluid className="document-form">
                <Row>
                    <Col xl="7">
                        <Card className="bg-default">
                            <CardBody className="pl-0 pr-0">
                                <Row xs={2}>
                                    <Col>
                                        <h3 className="mt-1 pl-4">
                                            <div
                                                className={
                                                    "icon-shape text-white rounded-circle bg-traffic1 title-icon"
                                                }
                                            >
                                                <i className={"ni ni-folder-17"}/>
                                            </div>
                                            All Documents
                                        </h3>
                                    </Col>

                                    <Col className="text-right">
                                        <Button
                                            className="secondary-btn sm-size mr-4"
                                            color="info"
                                            type="button"
                                            onClick={() => setIsModalOpen(!isModalOpen)}
                                        >
                                            <i className={"fas fa-upload mr-1"}/>
                                            Upload
                                        </Button>
                                    </Col>
                                </Row>

                                <Row className="d-flex mt-3 pl-3 dropdown-container">
                                    <Col className="d-flex justify-content-start">
                                        <Collapse isOpen={filters.year.isShown}>
                                            <FormDropdown
                                                className="document-dropdown"
                                                options={getYearsList()}
                                                value={filters.year.value}
                                                onChange={handleSelectChange}
                                                defaultValue={documents.length > 0 ? documents[0].year : moment().format("YYYY")}
                                                isOpen={isDropdownOpen.year}
                                                toggle={() =>
                                                    setIsDropdownOpen({
                                                        ...isDropdownOpen,
                                                        year: !isDropdownOpen.year,
                                                    })
                                                }
                                                direction="end"
                                                name="year"
                                            />
                                        </Collapse>

                                        <Collapse isOpen={filters.month.isShown}>
                                            <FormDropdown
                                                className="document-dropdown ml-2"
                                                options={globalConstants.MONTHS}
                                                value={filters.month.value}
                                                onChange={handleSelectChange}
                                                defaultValue={documents.length > 0 ? documents[0].month : moment().format("MMMM")}
                                                isOpen={isDropdownOpen.month}
                                                toggle={() =>
                                                    setIsDropdownOpen({
                                                        ...isDropdownOpen,
                                                        month: !isDropdownOpen.month,
                                                    })
                                                }
                                                direction="end"
                                                name="month"
                                            />
                                        </Collapse>
                                    </Col>

                                    <Row className="pl-3 mr-4">
                                        <Col>
                                            <FormDropdown
                                                className="document-dropdown"
                                                options={ALL_DOC_TYPES}
                                                value={filters.type.value}
                                                onChange={handleSelectChange}
                                                defaultValue={ALL_DOC_TYPES_DICT.monthlyStatements}
                                                isOpen={isDropdownOpen.type}
                                                toggle={() =>
                                                    setIsDropdownOpen({
                                                        ...isDropdownOpen,
                                                        type: !isDropdownOpen.type,
                                                    })
                                                }
                                                direction="end"
                                                name="type"
                                            />
                                        </Col>
                                    </Row>
                                </Row>

                                <div className="table-responsive mt-2">
                                    <FormTable
                                        columns={documentConstants.groupedOptions}
                                        data={documents}
                                        onClickHandler={onClickHandler}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="5" onClick={() => openInNewTab(documents[documentNumber]?.file)}>
                        <div className="pdf-wrapper">
                            {documents && documents[documentNumber]?.file && (
                                <object data={documents[documentNumber]?.file} type="application/pdf" width="100%"
                                        height="700px">
                                </object>
                            )}
                        </div>
                    </Col>

                    <FormModal title="Upload document" isOpen={isModalOpen}>
                        <UploadDocumentForm
                            formData={formData}
                            setFormData={setFormData}
                            handleSubmit={onUpload}
                            setIsOpen={setIsModalOpen}
                            isOpen={isModalOpen}
                        />
                    </FormModal>
                </Row>
            </Container>
        </div>
    );
}

export default PropertyDocuments;
