/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useHistory } from "react-router-dom";
import AdminFooter from "components/Footers/AdminFooter";

function ManagementTerms(props) {
  const history = useHistory();
  const redirect = () => {
    history.push("/");
  };

  return (
    <>
      <div
        style={{
          boxSizing: "border-box",
          backgroundColor: "white",
          color: "#747a87",
          lineHeight: "30px",
          fontSize: "18px",
        }}
      >
        <img
          style={{ marginLeft: "45%" }}
          width={"189"}
          src={require("assets/img/main-logo.png").default}
          onClick={() => redirect()}
        ></img>
        <h1 style={{ textAlign: "center" }}>
          SHORT-TERM PROPERTY MANAGEMENT AGREEMENT - TERMS
        </h1>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="halfBlock">
            <div className="block5">1.</div>
            <div className="block90">
              {/* <div> */}
              <span className="boldStyle"> TERMS. </span>
              <span>
                This document of terms and conditions (“
                <span className="boldStyle">Terms</span>”) is incorporated by
                reference into the Short-Term Property Management Agreement (“
                <span className="boldStyle">Agreement</span>”) between Owner
                (defined therein) and HOSTE, LLC. (“
                <span className="boldStyle">Manager</span>”). The Agreement and
                these Terms form the entire agreement for Manager’s provision of
                Services (described herein) to Owner for the Property (described
                in the Agreement). No prior or contemporaneous negotiations,
                agreements, requests, correspondence, conversations, prior
                course of dealing or usage of trade shall be deemed in any way
                to affect the specific terms hereof. Each Agreement together
                with these Terms shall be binding on Owner even if not signed by
                Owner. All capitalized terms not otherwise defined in this
                document shall have the meanings set forth in such Agreement.
              </span>
              {/* </div> */}
            </div>
            {/* <div> */}
            <div className="block5">2.</div>
            <div className="block90">
              <span className="boldStyle"> SERVICES. </span>

              {/* </div> */}
              <div>
                <div className="block5">(a)</div>
                <div className="block90">
                  <span>
                    {" "}
                    Owner hereby hires Manager for the Term to serve as the
                    exclusive property manager and exclusively perform the
                    following services to Owner in connection with the licensing
                    of the Property for short-term stays by guests (“{" "}
                    <span className="boldStyle">Short-Term Stays</span>”) in
                    consideration for the Owner’s payment of the Management Fee
                    (as described herein) and any other fees or costs described
                    in these Terms or in the Agreement to Manager (the “{" "}
                    <span className="boldStyle">Services</span>”): (i) market
                    the Property on popular online short-term stay/vacation
                    websites chosen by Manager which may include, without
                    limitation, Airbnb, VRBO, HomeAway, TripAdvisor, Flipkey,
                    and Booking.com (“
                    <span className="boldStyle">3P Stay Sites</span>”); (ii)
                    write and post listing information, content and photos of
                    the Property on 3P Stay Sites including professional
                    photography; (iii) respond to reservation inquiries
                    regarding Property, (iv) process reservations,
                    cancellations, and handle any disputes with guests in
                    connection with the Property, (v) accept or reject potential
                    guest reservations, post reviews, and manage the calendar
                    for available dates at the Property, (vi) schedule
                    housekeeping/cleaning and laundry services between guest
                    reservations, (vii) keep customary perishable items stocked
                    at the Property for guest reservations, such as toilet paper
                    and paper towels; (viii) administer guest check-in and
                    check-out services; (ix) generate and deliver statements and
                    disbursements to Owner, in accordance with these Terms, (xi)
                    use reasonable efforts coordinate with third-party providers
                    of Additional Services or provide Additional Services
                    described in and pursuant to{" "}
                    <span className="spanUnderline">Section 2(b)</span>;
                    provided that Manager shall not be responsible for or liable
                    for latent defects at the Property, structural, foundation,
                    or other similar defects or issues, damage or issues
                    concerning the roof, plumbing, electrical, boiler,
                    appliances, hot tub, or any similar matters, it being
                    understood that Manager shall only be responsible for
                    coordinating minor repairs or improvements required to place
                    the Property in good and habitable condition suitable for
                    guest use, and subject to the additional costs associated
                    with such Additional Services. Owner shall be solely
                    responsible for such service providers and all payments or
                    costs associated therewith unless otherwise expressly set
                    forth herein.
                  </span>
                </div>
                <div>
                  <div className="block5">(b)</div>
                  <div className="block90">
                    <span>
                      Owner may also elect to have Manager perform or hire
                      third-party service providers to perform certain services
                      at the Property that are not included in the Services (“{" "}
                      <span className="boldStyle">Additional Services</span>
                      ”), each such Additional Service to be charged to Owner at
                      the then-current rates charged by Manager for each
                      Additional Service. These rates for Additional Services
                      will be subject to change at Manager’s sole discretion.
                      Examples of Additional Services include: (i) lawn care,
                      including fall cleanup and tree/bush trimming, (ii)
                      sprinkler turn-on, repairs, and winterization, (iii) snow
                      removal, (iv) regular pest control treatment, (v) yearly
                      chimney inspection, and (vi) yearly heater and AC
                      inspections; (vii) repairs (i.e., plumbing, electrical,
                      walls, paint). Please contact Manager to obtain the latest
                      rates for such Additional Services and indicate your
                      desire to obtain them.
                    </span>
                  </div>
                </div>
                <div>
                  <div className="block5">(c)</div>
                  <div className="block90">
                    <span>
                      In consideration for Manager serving as the exclusive
                      provider of the Services for the Property during the Term,
                      Owner will not directly or hire any other party to
                      advertise, market, list, rent, lease or license the
                      Property in any manner for Short Term Stays or for any
                      medium or long term rental, nor shall Owner directly,
                      other than pursuant to the Agreement and these Terms,
                      market, list, rent, lease or license the Property in any
                      manner.
                    </span>
                  </div>
                </div>
                <div>
                  <div className="block5">(d)</div>
                  <div className="block90">
                    <span>
                      Owner hereby names Manager as its agent and lawful
                      attorney-in-fact during the Term solely to negotiate,
                      execute, sign, amend, acknowledge or comply with or
                      enforce any lease, license, rental or services agreement
                      in connection with the Property for Short Term Stays
                      (collectively, “Guest Agreements”), and to take any action
                      that Manager deems necessary or appropriate to take with
                      respect to the Property to facilitate Short Term Stays or
                      Manager’s performance of Services or Additional Services
                      as described herein.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="block5">3.</div>
            <div className="block90">
              {/* <div> */}
              <span className="boldStyle"> MARKETING. </span>
              <span>
                In its performance of the Services, Manager, in its discretion,
                will market and promote the Property for Short Term Stays
                utilizing various 3P Stay Sites. All costs to utilize 3P Stay
                Sites shall deemed an Additional Fee described in{" "}
                <span className="spanUnderline">Section 6(b)</span> and paid
                pursuant to <span className="spanUnderline">Section 7</span>.
                Special advertising engagements or materials outside of the 3P
                Stay Sites requested by Owner are Additional Services and the
                costs thereof shall be invoiced to and paid by Owner.
              </span>
            </div>
          </div>

          {/* DIV 2 */}
          <div className="halfBlock">
            <div className="block5">4.</div>
            <div className="block90">
              {/* <div> */}
              <span className="boldStyle">
                {" "}
                OWNER REPRESENTATIONS AND WARRANTIES.{" "}
              </span>
              <span>
                Owner hereby represents and warrants to Manager the following:
              </span>
              <div>
                <div className="block5">(a)</div>
                <div className="block90">
                  <span>
                    Owner is either an entity in good standing or a natural
                    person at least eighteen years of age and duly authorized to
                    enter into this Agreement;
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(b)</div>
                <div className="block90">
                  <span>
                    Owner is the fee simple owner of the Property and any
                    improvements or structures existing thereon, and has all
                    right, title, and interest in all of the personal property,
                    fixtures, and movable items located at or on the Property
                    that are necessary for guests to license, use and enjoy the
                    Property during each respective Short Term Stay during the
                    Term;
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(c)</div>
                <div className="block90">
                  <span>
                    all information related to the Property provided by Owner is
                    true and accurate in all respects; and
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(d)</div>
                <div className="block90">
                  <span>
                    this Agreement does not conflict with any other agreement to
                    which Owner is a party, or any condition, covenant,
                    restriction, law, regulation, ordinance (including zoning
                    ordinances or short-term rental prohibitions) affecting the
                    Property. Owner understands that it is Owner’s sole
                    responsibility to ensure that the Property is, at all times,
                    in full compliance with any applicable laws, regulations,
                    ordinances, rules, or other governmental impositions or
                    other private restrictions applicable to the Property.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(e)</div>
                <div className="block90">
                  <span>
                    under no circumstances shall Owner visit the Property or
                    directly contact any guest during any period when a guest
                    has booked the Property without the prior written consent of
                    Manager.
                  </span>
                </div>
              </div>
            </div>
            <div className="block5">5.</div>
            <div className="block90">
              {/* <div> */}
              <span className="boldStyle"> FEES.</span>
              <span>
                {" "}
                As compensation for Services provided, Owner shall pay to
                Manager the following Fees:
              </span>
              <div>
                <div className="block5">(a)</div>
                <div className="block90">
                  <span className="spanUnderline">Management Fee.</span>
                  <div>
                    <div className="block5">(i)</div>
                    <div className="block90">
                      <span>
                        For each reservation booked or arranged by Manager (or
                        by Owner) during the Term, Owner shall pay the
                        Management Fee which is the percentage (stated in the
                        Agreement) multiplied by the Nightly Rate (described in
                        the Agreement) multiplied by the number of nights of
                        such reservation (“Stay Night(s)”) for each Unit
                        (described below) comprising the Property. The Nightly
                        Rate and the minimum duration of the Short Term Stay for
                        each Property shall be determined by Manager, in its
                        sole discretion, and may vary based on a variety of
                        factors. Payment of the Management Fee shall be due to
                        Manager and immediately earned with each night’s stay
                        during the Short Term Stay.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="block5">(ii)</div>
                    <div className="block90">
                      <span>
                        In the event that the Property is comprised of more than
                        one physical property or unit (“Unit”), Owner
                        acknowledges and agrees that the Management Fee rate
                        applies to and is based on Manager serving as the
                        exclusive manager for all such Units. If during the
                        Term, Owner removes any of the Units from the Property
                        that Manager is managing hereunder, the Management Fee
                        and the term of this Agreement will be increased by an
                        amount determined by Manager in order to account for any
                        previously-granted discount or special rate provided
                        which was based upon the original number of Units
                        managed by Manager.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block5">(b)</div>
                <div className="block90">
                  <span className="spanUnderline">
                    Existing Reservation Management Fee
                  </span>
                  <span>
                    {" "}
                    In the event that Owner desires Manager to provide Services
                    for reservations at the Property which were booked prior to
                    the Term, then in such instances Owner shall pay to Manager
                    the Existing Reservation Management Fee described on the
                    Agreement.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(c)</div>
                <div className="block90">
                  <span className="spanUnderline">Onboarding Fee.</span>
                  <span>
                    {" "}
                    Owner shall pay to Manager the one-time Onboarding Fee for
                    the establishment of Owner’s account with Manager, and to
                    cover costs associated with an initial deep cleaning,
                    linens, photography/marketing expenses, and other similar
                    costs and expenses. This Set-up Fee shall be payable prior
                    to the marketing of the Property by Manager.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(d)</div>
                <div className="block90">
                  <span className="spanUnderline">Stocking Fee.</span>
                  <span>
                    {" "}
                    Owner shall be responsible for payment of the Stocking Fee
                    which shall reflect a reasonable and customary amount
                    required for the monthly operation of the Property as a
                    Short Term Stay. Manager shall be permitted to change the
                    Stocking Fee from time to time, in its discretion, provided
                    that Manager shall provide to Owner notice of any change in
                    the Monthly Supply Fee not less than thirty (30) days prior
                    to such revised fee going into effect. The Stocking Fee
                    shall, without limitation, cover expenses for items such as
                    toilet paper, paper towels, trash bags, soap, coffee, coffee
                    filters, etc. In the event that the Property remains under
                    25% occupancy for any month during the Term, no Supply Fee
                    shall be charged by Manager. Manager shall be responsible
                    for providing in Property the necessary amount of linens,
                    excluding blankets, pillows, and comforters.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(e)</div>
                <div className="block90">
                  <span className="spanUnderline">
                    Cancellation/Refund Fees.
                  </span>
                  <span>
                    {" "}
                    Manager shall establish guest cancellation and refund
                    policies in its sole discretion, and shall determine the
                    method and timing of collection of payments from guests. In
                    the event that the Property is, at any time, unsuitable for
                    guest stays in Manager’s sole discretion, Manager may cancel
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "gray", width: "100%", height: "3px" }}
      ></div>
      {/* PAGE2 */}
      <div
        style={{
          boxSizing: "border-box",
          backgroundColor: "white",
          color: "#747a87",
          lineHeight: "30px",
          fontSize: "18px",
        }}
      >
        <div className="halfBlock">
          <div>
            <div className="block10"></div>
            <div className="block10"></div>
            <div className="block80">
              reservations, move guests to other properties, issue refunds or
              credits to guests, or take any other action it deems necessary.
              Owner understands and acknowledges that Manager is seeking to
              maximize guest satisfaction and Property ratings for the benefit
              of Owner in making such decisions. Manager shall only be
              responsible to Owner for amounts not refunded to guests for
              canceled guest visits, in Manager’s discretion, less payment of
              the Management Fee, which will be due and payable to Manager in
              any event.
            </div>
          </div>
          <div>
            <div
              style={{
                width: "15%",
                display: "inline-block",
                verticalAlign: "top",
                paddingRight: "2%",
              }}
            ></div>

            <div
              style={{
                maxWidth: "5%",
                display: "inline-block",
                paddingRight: "2%",
                verticalAlign: "top",
              }}
            >
              (f)
            </div>

            <div className="block80">
              <span className="spanUnderline">Deep Clean Fee. </span>

              <span>
                {" "}
                Manager may, not more frequently than two times per calendar
                year, arrange for the deep cleaning of the Property at any time
                in its discretion, and Owner shall pay the cost of such deep
                cleaning.
              </span>
            </div>
          </div>
          <div className="block5">6.</div>
          <div className="block90">
            <div>
              <span className="boldStyle"> ADDITIONAL SERVICES/COSTS. </span>
            </div>
            <div className="block5">(a)</div>
            <div className="block90">
              <span className="spanUnderline">Ancillary Services. </span>
              <span>
                Manager may, at any time, sell or market ancillary services,
                experiences, amenities, or products to any guest at the Property
                (“ <span className="boldStyle">Ancillary Services</span>
                ”). The marketing and charging of fees for Ancillary Services
                (“A
                <span className="boldStyle">ncillary Service Fees</span>
                ”) is at the sole discretion of Manager, and all such fees shall
                be treated as Additional Fees described below.
              </span>
            </div>
            <div className="block5">(b)</div>
            <div className="block90">
              <span className="spanUnderline">Additional Fees. </span>
              <span>
                In addition to the Fees described in{" "}
                <span className="spanUnderline">Section 5</span>, a reservation
                may incur additional fees payable to 3P Stay Sites, other
                third-party service providers and/or Manager (depending on the
                service) for a reservation which may include (at Manager’s sole
                discretion), without limitation, (1) a unit-cleaning fee
                determined by Manager based on the size on the Property, (2)
                early check-in and late check-out fees, (3) hot tub use fee, as
                applicable, (4) booking fees and damage waiver fees, and (5) pet
                fees, if permissible at the Property (the foregoing, as well as
                any additional fees in Manager’s sole discretion, collectively,
                “<span className="boldStyle">Additional Fees</span>”). Manager
                in its discretion shall (i) separately charge guests directly
                for some or all of the Additional Fees and/or (ii) adjust the
                amount of the Nightly Rate to account for such Additional Fees.
                In either case, the amounts collected by Manager for the
                Additional Fees shall be the sole property of Manager and
                Manager shall in turn pay Additional Fees of such 3P Stay Sites
                or other third-parties for such services provided for each
                reservation,
              </span>
            </div>
          </div>
          <div className="block5">7.</div>
          <div className="block90">
            <div>
              <span className="boldStyle"> MONTHLY STATEMENTS/PAYMENTS. </span>
            </div>
            <div className="block5">(a)</div>
            <div className="block90">
              <span className="spanUnderline">Monthly Statement. </span>
              <span>
                Manager shall send to Owner, within 15 days after the end of
                each calendar month during the Term, a statement (“
                <span className="boldStyle">Monthly Statement</span>”) listing
                for all respective reservations during such month (i) all
                revenue collected by Manager generated from the Nightly Rates
                (net of tax withholding pursuant to{" "}
                <span className="spanUnderline">Section 12</span>) payable to
                Owner (net of any platform/3P Stay Site marketing fees, and not
                including any fees charged by the Manager directly to the guest
                and collected directly by the Manager) (collectively “
                <span className="boldStyle">Revenue</span>”); (ii) all Fees
                payable to Manager as described in{" "}
                <span className="spanUnderline">Section 5</span> as well as any
                other Property-related expenditures made/costs incurred for the
                applicable calendar month and any other associated fees or costs
                (collectively, “<span className="boldStyle">Expenses</span>”).
                The Monthly Statement shall state the difference between Revenue
                and Expenses (“
                <span className="boldStyle">Net Difference</span>”).
              </span>
            </div>
            <div className="block5">(b)</div>
            <div className="block90">
              <span className="spanUnderline">Disbursement/Payment. </span>
              <span>
                Within 15 days of date of the Monthly Statement, Manager shall
                pay Owner the Net Difference if such amount is positive. In such
                case the Expenses payable by Owner to Manager hereunder shall be
                retained by Manager and offset against the Revenues received. If
                the Net Difference is negative, then Manager shall retain any
                Revenue and Owner shall pay to Manager the Expenses (equal to
                the Net Difference expressed as a positive number).
              </span>
            </div>
          </div>
          <div className="block5">8.</div>
          <div className="block90">
            <div>
              <span className="boldStyle"> DISCLAIMER OF GUARANTEES. </span>
              <span>
                Owner understands and agrees that Manager has made no and
                disclaims any warranties or guarantees (written or verbal) (i)
                that Manager will achieve any particular occupancy rate or
                income levels for or from the Property for all or any part of
                the Term; or (ii) that the Services performed for the Short Term
                Stays will incur any particular amount of expenses or generate
                any particular tax or other benefits.
              </span>
            </div>
          </div>
          <div className="block5">9.</div>
          <div className="block90">
            <div>
              <span className="boldStyle">DAMAGE WAIVERS. </span>
              <span>
                Manager shall offer a short-term damage waiver protection
                program for damage or loss occurring during guest stays at the
                Property (“
                <span className="boldStyle">Short Term Coverage</span>”), and
                may charge guests at the Property amounts as it deems necessary
                or appropriate to cover any Short Term Coverage deductible for
                damage caused by guests at the Property. The limits of such
                Short Term Coverage coverage shall be in amounts as Manager
                shall determine. Alternatively, Manager may require a security
                deposit from any guest, in its discretion. In either case, the
                Property will be inspected for damage as soon as possible after
                the guest vacates the Property. Manager may retain any fee from
                the guest paid for Short Term Coverage, or any portion of the
                deposit, as applicable, in Manager’s sole discretion, to account
                for damage or loss at the Property for which Manager is required
                to expend funds. Manager shall not be responsible for missing
                items or damage or repairs at the Property, provided that it
                shall make claims to the{" "}
                <span className="boldStyle">Short Term Coverage</span> carrier
                as necessary for any such instances. Any damage or loss in
                excess of Manager’s short-term stay damage coverage policy shall
                be the sole responsibility of Owner, and Manager does not
                guarantee that any particular loss, damage, or claim will be
                covered by such coverage. Owner understands and acknowledges
                that Manager utilizes
              </span>
            </div>
          </div>
        </div>
        <div className="halfBlock">
          <div className="block10"></div>
          <div className="block90">
            third-party short-term stay damage protection coverage and that
            coverage decisions are made by such coverage provider. Manager will
            use its best efforts to obtain payment for all covered losses under
            such policy. It is understood and agreed between the Parties that it
            is not the responsibility of Manager to make a complete inventory
            and inspection of each item upon each vacation of the Property by
            the guest, but rather generally inspect for major item loss and
            damage. Owner agrees to hold Manager harmless of any liability of
            cost of damages or theft caused by guests or others to person or
            property, and Owner shall bear sole responsibility for any damage to
            the Property or any person or loss suffered by any party at or on
            the Property. In the event Manager, or any affiliate, member,
            manager, employee, or agent of Manager, or any guest or other person
            at the Property, sustains any loss or damage by reason of any act or
            omission by any guest at the Property, or as a result of any
            condition at the Property, or anything else related to the services
            provided by Manager in connection with this Agreement or otherwise,
            Owner will fully indemnify Manager for any such loss or damage,
            including reasonable attorneys' fees incurred in connection
            therewith, which indemnity obligation of Owner shall survive the
            expiration or earlier termination of this Agreement.
          </div>
          <div className="block5">10.</div>
          <div className="block90">
            <span className="boldStyle">COSTS.</span>
            <div>
              <div className="block5">(a)</div>
              <div className="block90">
                Owner shall: (i) supply and maintain the furnishings, fixtures,
                appliances, and other personal property located at the Property
                and all monetary amounts to keep the Property in good order and
                repair, (ii) pay for all the normal operational costs of the
                Property, including all utilities, pest control, pool/spa
                service, trash collection, lawn care, property taxes and
                assessments, mortgage/loan payments, HOA fees and any other
                expense normally associated with owning a home, and (iii) pay
                for maintenance and replacements of any fixtures, appliances,
                personal property or other items located at the Property due to
                normal wear and tear and minor loss, or the operational life of
                such property, consistent with short-term stay industry
                standards.
              </div>
            </div>
            <div>
              <div className="block5">(b)</div>
              <div className="block90">
                Manager may (i) deduct any expenses incurred by Manager from any
                amount due to Owner in connection with Owner’s failure to
                maintain the Property in the accordance with the standards set
                forth in this Agreement, or (ii) terminate this Agreement, or
                terminate or suspend temporarily the ability of Owner to license
                or allow guest stays at the Property or offer the Property for
                use by any third party, until such conditions are remedied to
                Manager’s satisfaction. To the extent the funds in Owner account
                will not cover such expenses in any given month, Owner agrees to
                promptly reimburse Manager for the full amount of such expenses
                upon written demand.
              </div>
            </div>
            <div>
              <div className="block5">(c)</div>
              <div className="block90">
                Owner represents and warrants that it shall ensure, at its sole
                cost and expense, that the Property shall at all times comply
                with all laws, rules, regulations, ordinances, and other legal
                requirements as the same may be amended from time to time,
                including without limitation any disability laws, federal
                housing non-discrimination laws or regulations, and city zoning
                or short-term rental ordinances.
              </div>
            </div>
          </div>
          <div className="block5">11.</div>
          <div className="block90">
            <span className="boldStyle">REPAIRS/REFUNDS.</span>
            <span>
              Owner shall be solely responsible for all major
              repairs/replacements at the Property, and agrees to undertake such
              repairs or replacements as are required to maintain the Property
              in good and marketable first-class condition during the term of
              this Agreement. Manager shall contact Owner for approval prior to
              contracting for any repairs or replacements which Manager
              determines will likely cost in excess of $200 (“
              <span className="boldStyle">Major Repairs</span>“) unless such
              repairs or replacements are required to mitigate an emergency
              situation or correct any condition which poses danger to life or
              property or for the preservation of the Property and/or the safety
              or wellbeing of the persons occupying the Property. Owner hereby
              accepts and authorizes all repairs other than Major Repairs, to be
              coordinated by Manager without notice, at Owner’s sole cost and
              expense. Manager shall be entitled to coordinate any repairs,
              including Major Repairs, through a third party service provider of
              Manager’s choice, or directly by an employee or agent Manager, and
              Manager may bill back to Owner the cost of any such repairs,
              whether performed by a third party or internally (including a
              commercially reasonable markup for coordinating and managing
              services provided by third parties). Manager is fully authorized
              to make any rebates/refunds to guests as Manager deems
              appropriate, in its sole discretion. Manager is authorized to, and
              shall use commercially reasonable efforts to, correct any such
              problems promptly, at Owner’s sole expense. In the event
              maintenance and repairs are necessary during months when no
              revenue is received for the Property, Owner shall directly pay for
              all such required repairs/replacements if requested by Manager. If
              Owner has a washer and dryer on the Property, Owner hereby
              authorizes Manager to use them to wash and dry guest linens and
              other household mats, towels, or the like, between each guest
              stay. Manager will not be responsible for any wear and tear or
              damage to the washer and dryer resulting from such use.
            </span>
          </div>
          <div className="block5">12.</div>
          <div className="block90">
            <span className="boldStyle">TAXES.</span>
            <span>
              Owner shall be solely responsible for payment of all real estate,
              personal property, and other property taxes. Manager shall collect
              from guests and directly pay all sales and use tax and transient
              rental/use tax due on Night Rates revenue to the appropriate
              governmental entities. Owner understands and agrees that Manager
              will not withhold from payments made under this Agreement any
              funds for income tax, unemployment insurance, social security or
              any other withholding pursuant to any law or requirement of any
              government body, and all of such payments that may be required by
              law are the sole responsibility of Owner, other than as set forth
              herein. The Parties agree that
            </span>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "gray", width: "100%", height: "3px" }}
      ></div>
      <div
        style={{
          boxSizing: "border-box",
          backgroundColor: "white",
          color: "#747a87",
          lineHeight: "30px",
          fontSize: "18px",
        }}
      >
        <div className="halfBlock">
          <div className="block10"></div>
          <div className="block90">
            Owner will file returns with all appropriate government agencies
            detailing the payments made hereunder.
          </div>
          <div className="block5">13.</div>
          <div className="block90">
            <span className="boldStyle">USE BY OWNER. </span>
            <span>
              Owner and Owner’s family and guests may use the Property, provided
              that (i) Owner shall obtain Manager’s prior written consent in the
              event that Owner wishes to use the Property for its personal use
              or the use of its friends, family or guests, or wishes to block
              off the Property prohibiting guests from booking the Property for
              a consecutive period of thirty (30) days or more; (ii) the
              Property is not booked at such time; (iii) Owner timely requests
              and obtains confirmation of a reservation for the period in
              question through Manager and Owner and its family and personal
              guests must follow the reservation process and all other
              procedures applicable to all other guests; (iv) Owner’s account
              will be charged the appropriate cleaning fee and other applicable
              fees normally charged to other guests unless prior payment
              arrangements are made with Manager; (v). Owner assumes all
              liabilities associated with personal use of the Property by Owner
              or Owner’s family or personal guests; and (vi) Owner and Owner’s
              family and personal guests agree to observe the standard check-in
              and check-out procedures unless alternate arrangements are made
              prior to arrival through Manager. Owner agrees to hold Manager
              harmless of liability of cost of damages or loss by its or its
              family’s / guests’ use of the Property, and Owner shall bear sole
              responsibility for any damage to the Property or loss suffered by
              any party at or on the Property.
            </span>
          </div>
          <div className="block5">14.</div>
          <div className="block90">
            <span className="boldStyle">DOOR LOCKS AND CAMERA. </span>
            <span>
              Manager requires that the Property have coded door lock or a
              lockbox with a key inside installed at Owner’s expense. If the
              Property does not have a lockbox or coded door box, Manager shall
              be permitted to install one and bill the cost of the box and
              installation to Owner. Prior to any bookings are accepted at the
              Property, Owner shall disclose to Manager the existence of and
              location of all cameras at the Property, indoor and outdoor. Owner
              understands, and represents and warrants, that no cameras shall be
              placed on the interior of the Property and under no circumstances
              shall any such cameras be operational at any time during a guest
              stay.
            </span>
          </div>
          <div className="block5">15.</div>
          <div className="block90">
            <span className="boldStyle">INTELLECTUAL AND MEDIA PROPERTY. </span>
            <span>
              Owner reserves all of its owned and licensed intellectual property
              rights in online listings and media created by Manager, (including
              listing photos and videos taken by Manager), descriptive listing
              text written by Manager, branding, logos, the Hoste website and
              all features, design, technology, materials or content posted by
              Manager or existing on such website, Manager’s social medial
              platforms or on any third-party site or app, or other materials in
              connection with Manager and Manager’s use in connection with its
              provision of the Services (“
              <span className="boldStyle">IP Rights</span>”), and such IP Rights
              shall not be transferred, licensed, assigned or otherwise be
              considered the property of Owner. Owner does hereby fully and
              immediately grant and assign to Manager, its successors and
              assigns, all right, title, and interest, without reservation, in
              and to the IP Rights. Except as expressly authorized by Manager,
              Owner may not sell, license, modify, distribute, copy, transmit,
              display, publish, adapt, edit or create derivative works from such
              material.
            </span>
          </div>
          <div className="block5">16.</div>
          <div className="block90">
            <span className="boldStyle">
              LOSS LIABILITY / INDEMNIFICATION.{" "}
            </span>
            <div>
              <div className="block5">(a)</div>
              <div className="block90">
                Manager shall not be liable for loss of Owner’s personal
                property, fixtures, equipment, appliances, or other property
                located at the Property, or any losses resulting from theft,
                dishonored or un-collectible checks, bank failure, declined
                credit card authorizations, wind, storms, accidents or other
                causes or events beyond Manager’s control. Manager shall not be
                required to initiate legal actions or retain an attorney for the
                purpose of collection of rents, collection of damages, eviction
                of guests or other persons. Collection fees and legal fees and
                the institution of any legal proceedings in connection therewith
                are the responsibility of Owner. Owner agrees that Manager shall
                not be liable for any claim for loss, accidents, injuries or
                illness that occur to any person or property while at the
                Property.
              </div>
            </div>
            <div>
              <div className="block5">(b)</div>
              <div className="block90">
                Owner understands and acknowledges that Manager utilizes
                third-party guest verification services and that it relies on
                such services in accordance with general industry standards.
                Manager will use commercially reasonable efforts to ensure the
                quality of guest utilizing the Property, but Manager is not
                responsible for verifying the identity of guests who book the
                Property.
              </div>
            </div>
            <div>
              <div className="block5">(c)</div>
              <div className="block90">
                Owner agrees that it, along with all guests, and others whom
                they invite to use the Property, are expressly assuming the risk
                of any harm or loss arising from their use of the Property.
              </div>
            </div>
            <div>
              <div className="block5">(d)</div>
              <div className="block90">
                Owner shall indemnify and hold harmless Manager or
                representatives of Manager from any such claim or liability,
                damages, injuries, costs and expenses related to acts, events or
                omissions occurring in, on or about the Property, or arising out
                of, or in any way related to, Manager’s or any guest’s use or
                time at the Property, any acts or omissions of a guest or breach
                of any term of the license or use agreement by any guest, or any
                work, activity or thing done, permitted or suffered by any guest
                or Manager in, on or about the Property
              </div>
            </div>
            <div>
              <div className="block5">(e)</div>
              <div className="block90">
                MANAGER SHALL PERFORM THE SERVICES ON AN “AS-IS” BASIS AND
                EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, MANAGER
                DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES INCLUDING, WITHOUT
                LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR
              </div>
            </div>
          </div>
        </div>
        <div className="halfBlock">
          {/* <div className="block10"></div> */}
          <div
            style={{
              width: "20%",
              display: "inline-block",
              verticalAlign: "top",
              paddingRight: "2%",
            }}
          ></div>

          <div className="block80">
            PURPOSE AND NONINFRINGEMENT OF THIRD PARTY INTELLECTUAL PROPERTY
            RIGHTS.
          </div>
          <div>
            <div
              style={{
                width: "15%",
                display: "inline-block",
                verticalAlign: "top",
                paddingRight: "2%",
              }}
            ></div>
            <div
              style={{
                maxWidth: "5%",
                display: "inline-block",
                paddingRight: "2%",
                verticalAlign: "top",
              }}
            >
              (f)
            </div>
            <div className="block80">
              IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER, OR TO ANY
              OTHER PERSON OR ENTITY, FOR ANY INDIRECT, CONSEQUENTIAL,
              INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES WHATSOEVER
              (INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, LOST PROFITS,
              DAMAGE TO GOODWILL OR REPUTATION AND/OR DEGRADATION IN VALUE OF
              BRANDS, TRADEMARKS, TRADENAMES, SERVICE NAMES OR SERVICE MARKS),
              WHETHER ARISING OUT OF BREACH OF CONTRACT, WARRANTY, TORT
              (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, FAILURE TO WARN OR
              STRICT LIABILITY), CONTRIBUTION, INDEMNITY, SUBROGATION, OR
              OTHERWISE. IN NO EVENT SHALL MANAGER BE LIABLE TO OWNER, OR TO
              OWNER’S OFFICERS, EMPLOYEES, REPRESENTATIVES OR INSURERS, OR TO
              ANY THIRD PARTY (COLLECTIVELY, “
              <span className="boldStyle">OTHER PARTIES</span>”), FOR DAMAGES
              CAUSED BY THE ACTS OR OMISSIONS OF OWNER OR OTHER PARTIES.
            </div>
          </div>
          <div className="block5">17.</div>
          <div className="block90">
            <span className="boldStyle">LIABILITY INSURANCE. </span>
            <span>
              {" "}
              Owner is required to carry homeowners’ insurance and an umbrella
              policy extending liability coverage to $1,000,000. Owner
              acknowledges that a Short Term Stay may be prohibited by standard
              homeowners’ insurance policies and accordingly Owner shall ensure
              that the home is insured with insurance coverage for short-term
              stay properties, either as an endorsement or separate policy.
              Manager shall be named as an additional named insured under all
              such liability policies, and Owner shall provide to Manager proof
              of any required insurance upon request.
            </span>
          </div>
          <div className="block5">18.</div>
          <div className="block90">
            <span className="boldStyle">SALE OF PROPERTY. </span>
            <span>
              {" "}
              During the Term, Owner agrees to notify Manager in writing not
              less than sixty (60) days prior to the date on which the Property
              will be placed upon the open market for sale or at such time Owner
              is considering marketing the Property for sale. Owner agrees that
              Property will only be shown when vacant or as coordinated through
              Manager. Manager may at its sole discretion immediately terminate
              this agreement without notice and transfer any pending
              reservations, if in the opinion of Manager, short term stay
              operations of the Property cannot be properly undertaken with sale
              and showings without any adverse effect on the quiet enjoyment of
              guests. Manager may, at Manager’s discretion, limit reservations
              for the Property as it may determine so as not to encumber the
              sale of the Property or risk cancellation of a guest’s planned
              stay at the Property
            </span>
          </div>
          <div className="block5">19.</div>
          <div className="block90">
            <span className="boldStyle">TERMINATION OF AGREEMENT. </span>
            <span>Owner is advised and agrees to the following:</span>

            <div>
              <div className="block5">(a)</div>
              <div className="block90">
                Owner may terminate this Agreement upon 60 days’ prior written
                notice to Manager at any time; provided that, once notice is
                given, Manager shall cease marketing the Property and
                reservations will not be accepted. The notice period will be
                used to finalize the management services which will include, but
                are not limited to, the transfer of any reservations or
                advertising, final account statements and final payments.
              </div>
            </div>
            <div>
              <div className="block5">(b)</div>
              <div className="block90">
                Manager may terminate this Agreement immediately upon written
                notice to Owner, for convenience or cause or any or no reason,
                and without penalty, or if Owner fails to comply with any
                provision of this Agreement or any term or condition of any
                rules or regulations Manager may adopt as applicable to this
                Property from time to time. Manager’s sole determination of
                non-compliance shall be final and binding on both Manager and
                Owner. In the event of any such termination, Owner will
                reimburse Manager for any costs or expenses that are the
                responsibility of Owner hereunder notwithstanding any
                termination.
              </div>
            </div>
            <div>
              <div className="block5">(c)</div>
              <div className="block90">
                Any termination by either Party will not affect any reservation
                obligation or other obligation entered into under the terms of
                this Agreement prior to the date of termination. Owner must
                honor all reservations and/or guests in place at time of receipt
                of written notice of termination or which are in place prior to
                the effective date of termination. Owner understands and agrees
                that Manager has incurred time and costs associated with each
                reservation in place at the time of termination and agrees to
                immediately pay to Manager the normal management commissions
                and/or fees for each reservation in place upon request of
                Manager. Manager will work with Owner or alternate agency, for a
                period of 30 days from notice of termination, to reasonably
                assist with the transfer of the current reservations to Owner or
                alternate agency. Owner understands and agrees that no refund is
                due by Manager for a commission or fee paid to Manager at the
                time of cancellation for any reservation that chooses not
                transfer and/or cancels at the time of notice of transfer or at
                a later date. Manager may also transfer any reservations at the
                Property to another property of its choosing, in its sole
                discretion, to minimize any disruption to a guest in connection
                with the termination of this Agreement.
              </div>
            </div>
            <div>
              <div className="block5">(d)</div>
              <div className="block90">
                In the event that Owner terminates the Agreement or these Terms
                prior to the end of the Term for any reason, then Owner shall be
                required to pay to Manager of an amount equal to (a) $250, times
                (b) the number of months (including any partial months)
                remaining
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "gray", width: "100%", height: "3px" }}
        ></div>
        <div
          style={{
            boxSizing: "border-box",
            backgroundColor: "white",
            color: "#747a87",
            lineHeight: "30px",
            fontSize: "18px",
          }}
        >
          <div className="halfBlock">
            {/* <div className="block10"></div> */}
            <div
              style={{
                width: "20%",
                display: "inline-block",
                verticalAlign: "top",
                paddingRight: "2%",
              }}
            ></div>
            <div className="block80">
              in the Term. Such amount shall be due immediately upon
              termination, and at Manager’s election, may be withheld from any
              amounts due and payable to Owner following such termination.
            </div>

            <div className="block5">20.</div>
            <div className="block90">
              <span className="boldStyle">NOTIFICATIONS. </span>
              <span>
                Any notices to be given by either Party to the other shall be
                via email with a confirmation of receipt by the emails provided
                by both parties.
              </span>
            </div>
            <div className="block5">21.</div>
            <div className="block90">
              <span className="boldStyle">MEDIATION / ARBITRATION. </span>
              <span>
                The Parties will attempt to resolve any dispute arising out of
                or relating to the Agreement or these Terms through friendly
                negotiations amongst the parties. If the matter is not resolved
                by negotiation, the parties will resolve the dispute using the
                below Alternative Dispute Resolution (ADR) procedure: Any
                controversies or disputes arising out of or relating to the
                Agreement or these Terms will be submitted to mediation in
                accordance with any statutory rules of mediation for the State
                of Colorado. If mediation is not successful in resolving the
                entire dispute or is unavailable, then Owner and Manager agree
                that any dispute, controversy or claim arising out of or
                relating in any way to the Agreement including without
                limitation any dispute concerning the construction, validity,
                interpretation, enforceability or breach of the Agreement, shall
                be exclusively resolved by binding arbitration upon a Party’s
                submission of the dispute to arbitration. In the event of a
                dispute, controversy or claim arising out of or relating in any
                way to the Agreement, the complaining Party shall notify the
                other Party in writing thereof. Within thirty (30) days of such
                notice, Owner and Manager shall meet at an agreed location in El
                Paso County, Colorado, to attempt to resolve the dispute in good
                faith. Should the dispute not be resolved within thirty (30)
                days after such notice, the complaining Party shall seek
                remedies exclusively through arbitration. The demand for
                arbitration shall be made within a reasonable time after the
                claim, dispute or other matter in question has arisen, and in no
                event shall it be made after two years from when the aggrieved
                Party knew or should have known of the controversy, claim,
                dispute or breach. Any arbitration decision or award may be
                entered as a judgment in the District Court of El Paso County,
                Colorado, and Owner hereby consents to the jurisdiction and
                venue of the El Paso County District Court for this purpose
              </span>
            </div>
            <div className="block5">22.</div>
            <div className="block90">
              <span className="boldStyle">FAIR HOUSING. </span>
              <span>
                {" "}
                Manager is committed to following the letter and spirit of the
                Federal Fair Housing law and all applicable State and local
                laws, regulations, and ordinances, by respecting the diversity
                and differences within our customer base by providing equal
                professional service to all, without regard to race, color,
                religion, sex, handicap, familial status, national origin or
                other protected status. Manager is committed to keeping informed
                about fair housing laws and practices, and will not tolerate
                non-compliance. Manager will additionally inform its clients and
                customers about their rights and responsibilities under the fair
                housing laws. No guest will be denied a stay or otherwise
                discouraged from booking the Property because of his/her status
                under these laws, and Owner acknowledges and agrees to all of
                the provisions of this Section.
              </span>
            </div>
            <div className="block5">23.</div>
            <div className="block90">
              <span className="boldStyle">AMENDMENT. </span>
              <span>
                {" "}
                Manager may modify this Agreement at any time in accordance with
                this provision. If Manager makes modifications to this
                Agreement, Owner will be provided the updated copy of the
                Agreement by email or other permitted method within a reasonable
                time before the date the changes become effective. If Owner
                desires not to enter into the revised Agreement, it shall
                provide Manager with notice of termination prior to the
                effective date of the revised Agreement and terminate this
                Agreement in accordance with the termination rights granted to
                Owner hereunder. If Owner does not terminate the Agreement
                before the date the modifications become effective, Owner’s
                continued or use of Manager’s services pursuant to this
                Agreement will constitute acceptance of the revised Agreement.
              </span>
            </div>
            <div className="block5">24.</div>
            <div className="block90">
              <span className="boldStyle">MISCELLANEOUS. </span>

              <div>
                <div className="block5">(a)</div>
                <div className="block90">
                  <span>
                    Failure of Manager to enforce any provision of this
                    Agreement will not be deemed a waiver.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(b)</div>
                <div className="block90">
                  <span>
                    This Agreement shall be governed by and interpreted in
                    accordance with the laws of the State of Colorado.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(c)</div>
                <div className="block90">
                  <span>
                    In any action or proceeding involving a dispute between
                    Owner and Manager arising from this Agreement, the
                    prevailing Party will be entitled to reasonable attorney
                    fees and costs incurred.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(d)</div>
                <div className="block90">
                  <span>
                    This Agreement shall in no way be construed to constitute
                    Manager as a partner, joint venturer, owner, employee,
                    member, officer, or manager of Owner, and no action of
                    Manager shall be considered an action or representation of
                    Owner, directly or by implication, as a partner, joint
                    venturer, owner, member, officer, or manager of Owner.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(e)</div>
                <div className="block90">
                  <span>
                    Owner may not assign this Agreement without the prior
                    written consent of Manager, provided that Manager may assign
                    this Agreement in its sole discretion.
                  </span>
                </div>
              </div>
              <div>
                <div className="block5">(f)</div>
                <div className="block90">
                  <span>
                    Each of the parties shall be excused for the period of any
                    delay in the performance of any obligation hereunder when
                    such delay is occasioned by causes beyond its control,
                    including but not limited to work stoppages, boycotts,
                    slowdowns or strikes; shortages of materials, equipment,
                    labor or energy; unusual weather conditions; acts or
                    omissions of governmental or political bodies, or the
                    occurrence of a pandemic, epidemic or prevalent disease or
                    illness with an actual threat to human life, including,
                    without limitation, coronavirus, Severe Acute Respiratory
                    Syndrome (SARS), or avian influenza which results in a
                    quarantine or similar measure taken in
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="halfBlock">
            <div
              style={{
                width: "20%",
                display: "inline-block",
                verticalAlign: "top",
                paddingRight: "2%",
              }}
            ></div>
            <div className="block80">
              relation thereto by any governmental agency or authority to
              prevent the spread of any communicable disease.
            </div>
            <div style={{ marginLeft: "10%" }}>
              <div className="block5">(e)</div>
              <div className="block90">
                <span>
                  Owner may not assign this Agreement without the prior written
                  consent of Manager, provided that Manager may assign this
                  Agreement in its sole discretion.
                </span>
              </div>

              <div className="block5">(g)</div>
              <div className="block90">
                <span>
                  This Agreement constitutes the entire agreement between the
                  parties and may be modified only in writing. If you signed a
                  prior version of this Agreement, this Agreement will govern
                  any bookings made on or after the date of this Agreement.
                </span>
              </div>

              <div className="block5">(h)</div>
              <div className="block90">
                <span>
                  Each section, subsection or paragraph of this Agreement shall
                  be deemed severable. If for any reason any portion of this
                  Agreement is unenforceable, that portion shall not affect the
                  applicability or validity of any other portion of this
                  Agreement.
                </span>
              </div>
              <div className="block5">(i)</div>
              <div className="block90">
                <span>
                  This Agreement and any modifications, including photocopies or
                  facsimiles, may be signed in one or more counterparts, each of
                  which will be deemed an original and all of which taken
                  together will constitute one and the same instrument.
                </span>
              </div>
              <div className="block5">(j)</div>
              <div className="block90">
                <span>
                  If Owner is more than one person or entity, or if the Property
                  is owned by more than one person or entity, then all such
                  persons and entities are jointly and severally liable under
                  this Agreement and Owner hereby represents and warrants that
                  it is duly authorized to represent or act on behalf of all
                  persons or entities that comprise Owner or that own the
                  Property and enter into and execute this Agreement.
                </span>
              </div>
              <div className="block5">(k)</div>
              <div className="block90">
                <span>
                  This Agreement may be executed in counterparts and electronic
                  signatures, or by electronic acknowledgement (by check box,
                  manual typing or drawing of Owner’s signature, digital
                  signing, or other similar method) shall have the force and
                  effect of actual ones.
                </span>
              </div>
              <div className="block5">(l)</div>
              <div className="block90">
                <span>
                  This Agreement shall not be deemed accepted by Manager unless
                  signed or acknowledged by Manager.
                </span>
              </div>
              <div className="block5">(m)</div>
              <div className="block90">
                <span>
                  This Agreement is not prepared on a form approved or
                  sanctioned by the Colorado Real Estate Commission. Owner is
                  hereby advised to consult with an attorney prior to signing
                  this Agreement.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminFooter />
    </>
  );
}

export default ManagementTerms;
