import { userConstants } from "../constants";

const initialState = {
  userData: {},
  ownerProfile: {},
  ownerProfiles: [],
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case userConstants.UPDATE_USER:
      return {
        ...state,
        userData: action.payload,
      };
    case userConstants.CREATE_OWNER_PROFILE:
      return {
        ...state,
        ownerProfile: action.payload,
      };
    case userConstants.FETCH_OWNER_PROFILE:
      return {
        ...state,
        ownerProfile: action.payload,
      };
    case userConstants.FETCH_OWNER_PROFILES:
      return {
        ...state,
        ownerProfiles: action.payload,
      };
    case userConstants.CLEAR:
      return {
        ...state,
        userData: {},
        ownerProfile: {},
      };
    default:
      return state;
  }
}
