/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { generateKey } from "app/helpers";
import { useEffect, useState } from "react";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { forwardRef } from 'react';
import {
    Card,
    CardHeader,
    Container,
    Row,
    ListGroupItem,
    ListGroup,
    CardBody
} from "reactstrap";



const QuickPropertyAccessWidget = forwardRef((props, ref) => {
    const dashboardData = useSelector(state => state.property.dashboard);
    const [propetiesNetIncome, setPropertiesData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        // if (dashboardData && dashboardData.property_net_incomes_for_current_month) {
        //     const propertiesData = Object.values(dashboardData.property_net_incomes_for_current_month);
        //     setPropertiesData(propertiesData);
        // };
        if (dashboardData && dashboardData.quick_property_access_table) {
            const propertiesData = Object.values(dashboardData.quick_property_access_table);
            setPropertiesData(propertiesData);
        };
    }, [dashboardData]);

    const onClickHandle = (property_id) => {
        history.push(`/owner/property-details/${property_id}`);
    };

    const renderProperty = (property) => {
        const active = property.property__hoste_services;

        return (
            <li key={generateKey()}
                onClick={() => onClickHandle(property.property)}>
                <Row className="align-items-center">
                    <div className="col">
                        <h5 className="mb-0">
                            <a href="">{property.property__entity_name}</a>
                        </h5>
                    </div>
                    <div className="col">
                        <small>Earnings This Month:</small>
                        <h5 className="mb-0"> ${property.current_month_net_income.toLocaleString('en-US')}</h5>
                    </div>
                    <div className="col text-center">
                        <small>Status:</small>

                        <h5 className="mb-0">
                            <span className={active ? "text-success mr-1" : "text-danger mr-1"}>●</span>
                            {property.property__hoste_services ? "Active" : "Inactive"}
                        </h5>
                    </div>
                </Row>
            </li>
        );
    };


    return (
        <div className="dashboard-property-table">
            <div className="property-header">
                <h3>Properties</h3>
            </div>
            <div>
                <ul className="list" flush>
                    {propetiesNetIncome.length
                        ? propetiesNetIncome.map(property => renderProperty(property))
                        : <li className="pl-3">No items</li>}
                </ul>
            </div>
        </div>
    )
});

export default QuickPropertyAccessWidget;




// const rowEvents = {
//     onClick: (e, row) => {
//         e.preventDefault();
//         e.stopPropagation();
//         e.nativeEvent.stopImmediatePropagation();
//         history.push(`/owner/property-details/${row.property}`);
//     }
// };
// const pagination = paginationFactory({
//     page: 1,
//     sizePerPage: 4,
//     hideSizePerPage: true,
// });
// const formatWithIcon = (isActive) => {
//     return (
//         <Badge color="" className="badge-dot mr-4">
//             <i className={isActive ? "bg-success" : "bg-danger"} />
//             <span className="status">{isActive ? "Active" : "Inactive"}</span>
//         </Badge>
//     )
// };

// const formatWithSymbol = (cell) => {
//     return '$' + cell
// };


// <Container fluid>
//     <Row>
//         <Col>
//             <Card>
//                 <CardHeader>
//                     <h3 className="mb-0">Properties</h3>
//                 </CardHeader>
//                 <ToolkitProvider
//                     data={propetiesNetIncome}
//                     keyField="name"
//                     columns={[
//                         {
//                             dataField: "property__entity_name",
//                             text: "Property Name:",
//                             sort: true,
//                         },
//                         {
//                             dataField: "current_month_net_income",
//                             text: "Earnings This Month:",
//                             sort: true,
//                             formatter: formatWithSymbol
//                         },
//                         {
//                             dataField: "property__hoste_services",
//                             text: "Status:",
//                             sort: false,
//                             formatter: formatWithIcon,
//                         },
//                     ]}
//                 >
//                     {(props) => (
//                         <div className="table-responsive text-center property-access-table">
//                             <BootstrapTable
//                                 {...props.baseProps}
//                                 hover
//                                 bootstrap4
//                                 striped
//                                 pagination={pagination}
//                                 bordered={false}
//                                 rowEvents={rowEvents}
//                             />
//                         </div>
//                     )}
//                 </ToolkitProvider>
//             </Card>
//         </Col>
//     </Row>
// </Container>