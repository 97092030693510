// APIs 

// get Date with MM/DD/YYYY
export function getFormatedDate(date, format = 'ymd') {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    if (format === 'ymd') {
        return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,].join('-');
    } else {
        return [(mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
        date.getFullYear()].join('/');
    }
}

// check selected date range is valid 
export function checkValidDateRange(destRange, sDate, eDate) {
    if (destRange.length < 2) return 0;
    var startDate = getFormatedDate(new Date(sDate), 'ymd');
    var endDate = getFormatedDate(new Date(eDate), 'ymd');
    var idx = 1;
    var tmpStartDate, tmpEndDate;
    for (idx = 1; idx < destRange.length; idx++) {
        tmpStartDate = getFormatedDate(new Date(destRange[idx].startDate), 'ymd');
        tmpEndDate = getFormatedDate(new Date(destRange[idx].endDate), 'ymd');
        if (startDate >= tmpStartDate && endDate <= tmpEndDate)
            return idx;
        if ((startDate <= tmpStartDate && endDate >= tmpStartDate) ||
            (startDate <= tmpEndDate && endDate >= tmpEndDate) ||
            (startDate > tmpStartDate && endDate < tmpEndDate)) {
            return -1;
        }
    }
    return 0;
}

// find their data
export function getReservationData(destRange, date) {
    if (destRange.length < 2) return -1;

    let selectedDate = getFormatedDate(new Date(date), 'ymd');

    for (let idx = 0; idx < destRange.length; idx++) {
        const tmpStartDate = getFormatedDate(new Date(destRange[idx].startDate), 'ymd');
        const tmpEndDate = getFormatedDate(new Date(destRange[idx].endDate), 'ymd');

        if (selectedDate >= tmpStartDate && selectedDate <= tmpEndDate) return destRange[idx];
    }
    return -1;
}