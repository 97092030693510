import { user } from "app/reducers/user.reducer";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Zendesk from "react-zendesk";
import { getUser } from "app/actions";
import { ZendeskAPI } from "react-zendesk";

const ZENDESK_KEY = "968ff6db-eb70-48ef-ade5-2e05575c677f";
// "lXBVEMF73r45ta40GCSQkTAeZ3MtwjlTM6B1oiUP";
// window.zE.identify({
//   name: 'Kos',
//   email: 'john@example.com'
// })
const setting = {
  color: {
    theme: "#ce372f",
  },
  webWidget: {
    contactOptions: {
      enabled: true,
      contactButton: { "*": "Get get" },
      chatLabelOnline: { "*": "Live Chat" },
      chatLabelOffline: { "*": "Live Chat is unavailable" },
    },
    helpCenter: {
      chatButton: {
        "*": "Chat with us now",
      },
      searchPlaceholder: {
        "*": "Search in our Help Center",
      },
      title: {
        "*": "Hoste Help Center",
      },
      filter: {
        section: "Homeowner Portal",
      },
      // filter: {
      //   category: '*'
      // }
      // setSuggestions:{search:"AS"}
    },
    // identify: {
    //   name: user.first_name,
    //   email: user.email,
    // },
    // prefill: {
    //   name: {
    //     value: user.first_name,
    //     readOnly: true, // optional
    //   },
    //   email: {
    //     value: user.email,
    //     readOnly: true, // optional
    //   },
    // },
    // answerBot: {
    //   title: {
    //     '*': 'Hoste '
    //   }},
    contactForm: {
      subject: true,
      // fields: [
      //   // { email: user.first_name, prefill: { '*': 'My text' } }
      //   // { id: 'description', prefill: { '*': 'Write message here' } }
      // ],
    },

    // chat: {
    //   prechatForm: {
    //     greeting: {
    //       '*': 'Please fill out the form below to chat with us',
    //       fr: "S'il vous plaît remplir le formulaire ci-dessous pour discuter avec nous"
    //     },
    //     departmentLabel: {
    //       '*': 'Select a department',
    //       fr: "S'il vous plaît remplir le formulaire ci-dessous pour discuter avec nous"
    //     }
    //   }
    // }

    //   chat: {
    //     profileCard: {
    //       avatar: true,
    //       rating: false,
    //       title: true,
    //     },
    //   },
  },
};

// window.zESettings = {
//   webWidget: {
//     helpCenter: {
//       chatButton: {
//         // fr: 'Discutez avec une personne',
//         '*': 'Chat with a person now'
//       }
//     }
//   }
// };
// let style = document.createElement("style");
// // console.log(style)
// style.innerHTML = `
//     /* hides the zendesk branding */
//     /* inspect the link using developer tools to get the correct class */
//     .kErscO {
//       visibility: hidden !important;
//     }
//     /* hides the 'Top Suggestions' title */
//     /* inspect the title using developer tools to get the correct class */
//     .bVgOrZ {
//       display: none !important;
//     }
//     `;
const handleLoadEvent = () => {
  // window.zE('webWidget:on', 'open', function() {
  //   // creates any CSS to be added to the Web Widget
  //   var style = document.createElement('style');
  //   console.log(style)
  //     style.innerHTML = `
  //       /* hides the zendesk branding */
  //       /* inspect the link using developer tools to get the correct class */
  //       .kkUEer {
  //         visibility: hidden !important;
  //       }
  //       /* hides the 'Top Suggestions' title */
  //       /* inspect the title using developer tools to get the correct class */
  //       .bVgOrZ {
  //         display: none !important;
  //       }
  //       `;
  //   // appends the CSS in the web widget
  //   // parent.
  //   window.document.getElementById('webWidget').contentDocument.head.appendChild(style);
  // });
  // window.zE('webWidget:on', 'open',function(user){
  // window.zE('webWidget', 'prefill', {
  //   name: {
  //     value: user.first_name,
  //     //  user.first_name,
  //     readOnly: true // optional
  //   },
  //   email: {
  //     value: "063@ukr.net",
  //     // user.email,
  //     readOnly: true // optional
  //   },
  //   // phone: {
  //   //   value: '61431909749',
  //   //   readOnly: true // optional
  //   // }
  // })}
  // )
  // if (user){
  // window.zE('webWidget', 'identify', {
  //   name:"ryfyt",
  //   //  user.first_name,
  //   email:"063@ukr.net"
  //   //  user.email
  // });
  // window.zE('webWidget', 'prefill', {
  //   name: {
  //     value: "ryfyt",
  //     //  user.first_name,
  //     readOnly: true // optional
  //   },
  //   email: {
  //     value: "063@ukr.net",
  //     // user.email,
  //     readOnly: true // optional
  //   },
  //   // phone: {
  //   //   value: '61431909749',
  //   //   readOnly: true // optional
  //   // }
  // });
  // }
  window.zE("webWidget", "helpCenter:setSuggestions", {
    search: "Hoste Departments",
    // search:"Second article",
    url: "https://iamhoste.zendesk.com/",
    // search: "First article"
    // search: "Fourth article"
    // "Hoste Academy",
  });
};

function Widget({ user }) {
  const dispatch = useDispatch();
  
  useEffect(() => {
    !user && dispatch(getUser());
    ZendeskAPI("webWidget", "helpCenter:setSuggestions", {
      search: "Homeowner Portal",
      url: true,        
    });
  }, []);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {      
      ZendeskAPI("webWidget", "prefill", {
        name: {
          value: user.first_name + " " + user.last_name,         
          readOnly: true, // optional
        },
        email: {
          value: user.email,         
          readOnly: true, // optional
        },
        // phone: {
        //   value: '61431909749',
        //   readOnly: true // optional
        // }
      });

      ZendeskAPI("webWidget", "identify", {
        name: user.first_name + " " + user.last_name,       
        email: user.email,       
      });
     
    }
  }, [user]);

  // return
  // if(a && Object.keys(a).length > 0){
  // if (user) {
    return (
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        // style={{display:"none"}}
        //
        // console.log(user)
        // handleLoadEvent(user)
        onLoaded={handleLoadEvent}       
      />
    );
  }
//   else{
//     return null
//   }
// }
// function Widget({ user }) {
//   useEffect(() => {
//     console.log(user)
//     if (user) {
//       const script = document.createElement("script");
//       script.id = "ze-snippet"

// b4d38197-9aac-4889-b6fc-c54574d71367
// 2dVzsYKyC7nQgl6sN1oRjXgchUI0sMYROUOeJpY3
//     //   // https://static.zdassets.com/ekr/snippet.js?key=968ff6db-eb70-48ef-ade5-2e05575c677f - Hoste
//     //   // https://static.zdassets.com/ekr/snippet.js?key=b4d38197-9aac-4889-b6fc-c54574d71367 - my
//       script.src = "https://static.zdassets.com/ekr/snippet.js?key=968ff6db-eb70-48ef-ade5-2e05575c677f";

//       script.async = true;

//       document.body.appendChild(script);
//     }

//     window.zESettings = {
//       webWidget: {
//         helpCenter: {
//           chatButton: {
//             fr: 'Discutez avec une personne',
//             '*': 'Chat with a person now'
//           }
//         }
//       }
//     };
//     // console.log('window.zESettings', window.zESettings);
//   }, [user]);

//   return <div>

//   </div>

// }

const mapStateToProps = (state) => ({
  user: state.user.userData,
});

export default connect(mapStateToProps, { getUser })(Widget);
