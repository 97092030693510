/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { login } from "app/actions";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText,
} from "reactstrap";
import { color } from "@mui/system";


function Login(props) {
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [show, setShow] = useState(false);
  const {
    user,
    login,
    step,
    substep,
    message
  } = props;
  const history = useHistory();

  useEffect(() => {
    if (user) {
      // history.push('/onboarding/step' + step + '' + substep);
      history.push("/owner/dashboard");
    }
  }, [user, history, step, substep]);

  const loginSubmit = (e) => {
    e.preventDefault()
    login(email.toLowerCase(), password);
  };

  const showHide = ()=> {
    setShow(!show)

  }

  return (
    <>    
      <Container className="login-wrap">
        <Row className="justify-content-end">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="px-lg-5 py-lg-4">
                <div className="text-center mb-4 mt-3 logo">
                  <img
                    alt=""
                    src={
                      require("assets/img/main-logo.png").default
                    }
                  />
                </div>
                <p className="text-center mb-3">
                  Want to create a Hoste owner account?
                </p>
                <div className="text-center mb-2">
                  <Button className="primary-btn" color="info" type="button">
                    Request Owner Account
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-4">
                <Form onSubmit={loginSubmit}>
                  <p className="text-center mb-3 mt-3">
                    Sign in to my owner account
                  </p>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: email,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        required
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: password,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type= {show ? "text":"password"}
                        value={password}
                        required
                        onChange={(e) => setpassword(e.target.value)}
                      />
                      <InputGroupText>
                      <i className="ni ni-ui-04" onClick={showHide} style={ show?{margin:'10% 0', cursor:'pointer',color:"#4A90E2"}:{margin:'10% 0', cursor:'pointer'}}/>
                      </InputGroupText>
                      {/* <i onClick={clickHandler} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i> */}
                    </InputGroup>
                  </FormGroup>
                  {
                    message && <div className="text-center mb-3 mt-3"><FormText className="helper-message">{message}</FormText></div>
                  }
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center mt-3">
                    <Button className="secondary-btn" color="info" type="submit">
                      Sign In
                    </Button>
                  </div>
                  <div className="text-center mt-2">
                    <a href="/accounts/reset/">
                      <small>Reset Password</small>
                    </a>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  step: state.onboarding.step,
  substep: state.onboarding.substep,
  message: state.alert.message,
});

const mapDispatchToProps = {
  login
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
