/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import moment from "moment";
import { useEffect } from "react";

function AdminFooter() {
  const currentYear = moment().year();
  const hostname = window.location.hostname;
  let protocol = "http";

  useEffect(() => {
    if (hostname === "owner.hoste.com") {
      protocol = "https";
    }
  }, [hostname]);

  return (
    <>
      <footer>
        <div className="text-muted">
          <span className="font-weight-bold">{`© ${currentYear} Hoste LLC`}</span>
        </div>
        <div style={{ display: "flex", direction: "row", gap: "1rem" }}>
          <div>
            <a
              href={`${protocol}://${hostname}/terms-of-service/`}
              target="_blank"
              className="text-muted"
              style={{ cursor: "pointer" }}
            >
              Terms of Service
            </a>
          </div>
          <div>
            <a
              href={`${protocol}://${hostname}/privacy-policy/`}
              target="_blank"
              className="text-muted"
            >
              Privacy Policy
            </a>
          </div>
          <div>
            <a
              href={`${protocol}://${hostname}/terms/`}
              className="text-muted"
              target="_blank"
            >
              Management Terms
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default AdminFooter;
