import { loadingConstants } from '../constants';

const initialState = {
    isLoading: true
};


export const loading = (state = initialState, action) => {
    switch (action.type) {
        case loadingConstants.START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case loadingConstants.STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};