import PropTypes from "prop-types";
import classnames from "classnames";

function FormOption({
  title,
  name,
  type,
  options,
  value,
  onChange,
  subtitle,
  direction,
  disabled,
  onBlur,
  defaultValue=""
}) {
  return (
    <>
      {title !== '' ? (<label className="mb-3 custom-conrtol-title">{title}</label>) : null}
      {!!subtitle !== false ? (<p className="mb-3">{subtitle}</p>) : null}
      {options.map((opt, idx) => (
        <div className={classnames("custom-control",
          { "custom-radio mb-2": type === 'radio' },
          { "custom-checkbox mb-3": type === 'checkbox' },
          { "inline-block mr-5": direction === 'horizontal' })} key={name + idx}>
          <input
            className="custom-control-input"
            id={name + idx}
            name={name}
            type={type}
            value={opt.value}
            checked={opt.value === value || opt.value === defaultValue}
            onChange={onChange}
            disabled={disabled}
            onBlur={onBlur}
          />
          <label
            className="custom-control-label"
            htmlFor={name + idx}
          >
            {opt.label}
          </label>
        </div>
      ))}
    </>
  );
}

FormOption.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.any,
  value: PropTypes.string,
  setValue: PropTypes.func,
  subtitle: PropTypes.string,
  direction: PropTypes.string,
};

export default FormOption;
