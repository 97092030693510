/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
import OwnerHeader from "components/Headers/OwnerHeader.js";
import OwnerSubNav from "components/Navbars/OwnerSubNav.js";
import {getAllDocuments, getPropertyById, updatePropertyInfo,} from "../../../app/actions";
import {connect, useDispatch} from "react-redux";
import {useLocation} from "react-router";
import FormOption from "components/Forms/FormOption";
import {useForm} from "app/hooks/useForm.hook";
import Submitted from "components/Alerts/Submitted";
import Loader from "components/Loaders/Loader";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "components/Forms/CustomCheckbox";


function PropertyDetails({property, documentsData, message, type}) {
    const [isOpen, setIsOpen] = useState(false);
    const {formData, handleInputChange, setFormData, handleSingleCheckbox} =
        useForm({
            street_address: "",
            city: "",
            ownership: "",
            primary_email: "",
            phone: "",
            secondary_email: "",
            secondary_phone: "",
            secondary_contact_first_name: "",
            secondary_contact_last_name: "",
            third_contact_first_name: "",
            third_contact_last_name: "",
            initial_signing_date: "",
            management_fee: "",
            reservations_before_man_fee: [],
            stocking_fee: "",
            dba: "",
            third_phone: "",
            third_email: "",
            bank_name: "",
            routing: "",
            account: "",
            business_individual: "",
            company_structure: "",
            recipient_tin_type: "",
            recipient_tin: "",
            pet_friendly: "",
            bedroom: "",
            bathroom: "",
            max_occupancy: "",
            parking: "",
            garage: "",
            cameras: "",
            type_ac: "",
            pest_control: "",
            fireplace_inspections: "",
            trash_company: "",
            trash_bins: "",
            trash_day: "",
            // trash_pickup: "",
            bear_proof_bins: "",
            recycling: "",
            internet_company: "",
            wifi_network: "",
            wifi_password: "",
            cable_tv: "",
            snow_removal: "",
            lawn_care: "",
            sprinkles: "",
            status: "",
            ac_furnace_inspections: "",
            electronic_delivery: true,
            account_manager: "",
        });
    const [errors, setErrors] = useState({
        phone: false,
        secondary_phone: false,
        third_phone: false,
    });
    const [isLoading, setLoading] = useState(true);
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const emailStar = '*'
    useEffect(() => {
        const propertyId = parseInt(pathname.split("/")[3]);
        localStorage.setItem("property_id", propertyId);
        dispatch(getPropertyById(propertyId));
    }, []);

    useEffect(() => {
    }, [formData.electronic_delivery]);

    useEffect(() => {
        if (property) {
            setFormData({
                ...property,
                account: secureFormat(property.account),
                routing: secureFormat(property.routing),
            });
            setLoading(false);
        }
    }, [property, documentsData]);

    const toggle = () => setIsOpen(!isOpen);

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    function secureFormat(number) {
        if (!number) return "";
        let string = String(number);
        let sliced = string.slice(-4);
        let mask = String(sliced).padStart(string.length, "*");

        return mask;
    }

    function secureEmail(number) {
        let string = String(number);
        let sliced = string.slice(0, 5);
        let mask = String(sliced).padEnd(string.length, "*");

        return mask;
    }

    const onBlur = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: secureFormat(event.target.value),
        });
    };

    const onPhoneChange = (e) => {
        handleInputChange(e);

        switch (e.target.value.length) {
            case 0:
            case 10: {
                setErrors({
                    [e.target.name]: false,
                });
                break;
            }
            default: {
                setErrors({
                    [e.target.name]: true,
                });
                break;
            }
        }
    };

    useEffect(() => {
        message && setLoading(false);
    }, [message, type]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        scrollTop();
        dispatch(updatePropertyInfo(formData));
    };

    return (
        <>
            <div className="dashboard-header">
                <OwnerHeader/>
            </div>
            <OwnerSubNav curPage="Property Info"/>
            <Container fluid className="property-details-form">
                <Row>
                    <Col xl="9">
                        <Card className="bg-default">
                            <CardBody className="pl-0 pr-0">
                                <Row className="ml-0 mr-0 border-bottom">
                                    <Col md="4">
                                        <h3 className="mt-1 pl-2 pb-2">
                                            <div
                                                className={
                                                    "icon-shape text-white rounded-circle bg-traffic1 title-icon"
                                                }
                                            >
                                                <i className={"fas fa-home"}/>
                                            </div>
                                            Property Information
                                        </h3>
                                    </Col>

                                    {/* <Col>
                    <Badge color="success">{formData.status}</Badge>
                  </Col> */}

                                    <Col className="text-right">
                                        <Button
                                            className="primary-btn"
                                            color="info"
                                            type="submit"
                                            form="property-details"
                                        >
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="7" className="text-right ml-9">
                                        {message && (
                                            <div className="text-center mb-3 mt-3 ml-5">
                                                <Submitted bodyText={message} type={type}/>
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                                {isLoading ? (
                                    <Row className="justify-content-center">
                                        <Loader/>
                                    </Row>
                                ) : (
                                    <Form
                                        className="pl-4 p-4"
                                        onSubmit={handleSubmit}
                                        id="property-details"
                                        encType="multipart/form-data"
                                    >
                                        <Row>
                                            <Col>
                                                <h4 style={{fontWeight: "bold"}}>
                                                    Property Information
                                                </h4>
                                            </Col>
                                        </Row>

                                        <div className="section-body">
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="street_address" sm={4}>
                                                            Address
                                                        </Label>

                                                        <Col sm={10}>
                                                            <Input
                                                                id="street_address"
                                                                name="street_address"
                                                                plaintext
                                                                value={formData.street_address}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="city" sm={4}>
                                                            City
                                                        </Label>

                                                        <Col sm={10}>
                                                            <Input
                                                                id="city"
                                                                name="city"
                                                                plaintext
                                                                value={formData.city}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <hr className="horizontal-line"/>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="bedrooms" sm={4}>
                                                            Bedrooms
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                id="bedrooms"
                                                                name="bedroom"
                                                                plaintext
                                                                value={
                                                                    formData.bedroom
                                                                        ? formData.bedroom
                                                                        : "No data"
                                                                }
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="bathrooms" sm={4}>
                                                            Bathrooms
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                id="bathrooms"
                                                                name="bathroom"
                                                                plaintext
                                                                value={
                                                                    formData.bathroom
                                                                        ? formData.bathroom
                                                                        : "No data"
                                                                }
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="max-occupancy" sm={6}>
                                                            Max Occupancy
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                id="max-occupancy"
                                                                name="max_occupancy"
                                                                plaintext
                                                                value={
                                                                    formData.max_occupancy
                                                                        ? formData.max_occupancy
                                                                        : "No data"
                                                                }
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label id="tooltip9826567" for="parking" sm={4}>
                                                            Parking
                                                        </Label>
                                                        <UncontrolledTooltip
                                                            // placement={"bottom"}
                                                            delay={0}
                                                            trigger="hover focus"
                                                            target="tooltip9826567"
                                                            style={{maxWidth: "30rem"}}
                                                        >
                                                            This is where guests will park at the property
                                                        </UncontrolledTooltip>
                                                        <Col sm={10}>
                                                            {/* <Input
                                id="parking"
                                name="parking"
                                playnarea
                                // type="text"
                                // rows = "2"
                                // plaintext
                                
                                value={formData.parking ? formData.parking : 'No data'}
                              /> */}
                                                            {/* form-control-plaintext */}
                                                            <p
                                                                className=" form-control-plaintext "
                                                                style={{
                                                                    color: "#525f7f",
                                                                    fontFamily: "inherit",
                                                                    fontWeight: "inherit",
                                                                }}
                                                            >
                                                                {formData.parking
                                                                    ? formData.parking
                                                                    : "No data"}
                                                            </p>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="parking" sm={4}>
                                                            Hoste Account Manager
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                id="parking"
                                                                name="parking"
                                                                plaintext
                                                                value={
                                                                    formData.account_manager
                                                                        ? formData.account_manager
                                                                        : "No data"
                                                                }
                                                            />
                                                            {/* <p>{formData.account_manager ? formData.account_manager : 'No data'}</p> */}
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <hr className="horizontal-line"/>
                                                </Col>
                                            </Row>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip9826676575" for="pet_friendly" sm={6}>
                                                    Pet Friendly
                                                </Label>
                                                <UncontrolledTooltip
                                                    // placement={"bottom"}
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip9826676575"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    We only accept dogs as pets. If you would like to
                                                    change this information, contact your account manager.
                                                </UncontrolledTooltip>
                                                <Col sm={6}>
                                                    <Input
                                                        id="pet_friendly"
                                                        name="pet_friendly"
                                                        plaintext
                                                        value={
                                                            formData.pet_friendly == "True" ? "Yes" : "No"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip982656464564" for="garage" sm={6}>
                                                    Garage
                                                </Label>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip982656464564"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This will indicate if the garage is available to
                                                    guests and how they will access it.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    {/* <Input
                            id="garage"
                            name="garage"
                            plaintext
                            addon
                            value={formData.garage ? formData.garage : 'No option selected'}
                          >
                          </Input> */}

                                                    <p
                                                        className=" form-control-plaintext "
                                                        style={{
                                                            color: "#525f7f",
                                                            fontFamily: "inherit",
                                                            fontWeight: "inherit",
                                                        }}
                                                    >
                                                        {formData.garage
                                                            ? formData.garage
                                                            : "No option selected"}
                                                    </p>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip982652234411" for="cameras" sm={6}>
                                                    Security Cameras
                                                </Label>
                                                <UncontrolledTooltip
                                                    // placement={"bottom"}
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip982652234411"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This indicates the location of security cameras on the
                                                    property. If you would like to add or remove one,
                                                    contact your owner account manager. It`s required by
                                                    law and booking platforms to report the location of
                                                    cameras to guests.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="cameras"
                                                        name="cameras"
                                                        plaintext
                                                        value={
                                                            formData.cameras
                                                                ? formData.cameras
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip9826544444" for="pest_control" sm={6}>
                                                    Quarterly Pest Control
                                                </Label>
                                                <UncontrolledTooltip
                                                    // placement={"bottom"}
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip9826544444"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This indicates whether or not you`re signed up to have
                                                    us coordinate periodic pest control with a
                                                    professional third-party company. If Owner is
                                                    selected, this means you are responsible for this
                                                    service at your property. This service is an extra
                                                    cost if Hoste coordinates. Contact your owner account
                                                    manager if you would like to change this service.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="pest_control"
                                                        name="pest_control"
                                                        plaintext
                                                        value={
                                                            formData.pest_control
                                                                ? formData.pest_control
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label
                                                    id="tooltip982651212121"
                                                    for="snow_removal"
                                                    sm={6}
                                                >
                                                    Snow Removal
                                                </Label>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip982651212121"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This indicates whether or not your signed up to have
                                                    us coordinate snow removal with a professional
                                                    third-party company. If Owner is selected, this means
                                                    you are responsible for this service at your property.
                                                    This service is an extra cost if Hoste coordinates.
                                                    Contact your owner account manager if you would like
                                                    to change this service.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="snow_removal"
                                                        name="snow_removal"
                                                        plaintext
                                                        value={
                                                            formData.snow_removal
                                                                ? formData.snow_removal
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip98263232323" for="lawn_care" sm={6}>
                                                    Lawn Care
                                                </Label>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip98263232323"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This indicates whether or not your signed up to have
                                                    us coordinate snow removal with a professional
                                                    third-party company. If Owner is selected, this means
                                                    you are responsible for this service at your property.
                                                    This service is an extra cost if Hoste coordinates.
                                                    Contact your owner account manager if you would like
                                                    to change this service.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="lawn_care"
                                                        name="lawn_care"
                                                        plaintext
                                                        value={
                                                            formData.lawn_care
                                                                ? formData.lawn_care
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip982639088889" for="sprinkles" sm={6}>
                                                    Seasonal Sprinkler Turn On and Winterization
                                                </Label>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip982639088889"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This indicates whether or not your signed up to have
                                                    us coordinate to have a professional third-party
                                                    company turn on and winterize sprinklers at the
                                                    property during applicable seasons. If Owner is
                                                    selected, this means you are responsible for this
                                                    service at your property. This service is an extra
                                                    cost if Hoste coordinates. Contact your owner account
                                                    manager if you would like to change this service.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="sprinkles"
                                                        name="sprinkles"
                                                        plaintext
                                                        value={
                                                            formData.sprinkles
                                                                ? formData.sprinkles
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label for="ac_furnace_inspections" sm={6}>
                                                    Yearly Central AC and/or Furnace Inspections
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="ac_furnace_inspections"
                                                        name="ac_furnace_inspections"
                                                        plaintext
                                                        value={
                                                            formData.ac_furnace_inspections
                                                                ? formData.ac_furnace_inspections
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label id="tooltip98263987665675" for="type_ac" sm={6}>
                                                    Type of AC
                                                </Label>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip98263987665675"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    We require some form of AC at each property because
                                                    lack of AC tends to be our biggest source of bad
                                                    reviews and complaints.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="type_ac"
                                                        name="type_ac"
                                                        plaintext
                                                        value={
                                                            formData.type_ac
                                                                ? formData.type_ac
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label
                                                    id="tooltip98263982323567"
                                                    for="fireplace_inspections"
                                                    sm={6}
                                                >
                                                    Yearly Fireplace Inspection
                                                </Label>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    trigger="hover focus"
                                                    target="tooltip98263982323567"
                                                    style={{maxWidth: "30rem"}}
                                                >
                                                    This indicates whether or not your signed up to have
                                                    us coordinate to have us coordinate a professional
                                                    third-party company to inspect a fireplace yearly. If
                                                    Owner is selected, this means you are responsible for
                                                    this service at your property. This service is an
                                                    extra cost if Hoste coordinates. Contact your owner
                                                    account manager if you would like to change this
                                                    service.
                                                </UncontrolledTooltip>
                                                <Col sm={4}>
                                                    <Input
                                                        id="fireplace_inspections"
                                                        name="fireplace_inspections"
                                                        plaintext
                                                        value={
                                                            formData.fireplace_inspections
                                                                ? formData.fireplace_inspections
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <Row>
                                                <Col>
                                                    <hr className="horizontal-line"/>
                                                </Col>
                                            </Row>

                                            <FormGroup row>
                                                <Label for="trash-company" sm={6}>
                                                    Garbage Collection Company
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="trash-company"
                                                        sm={5}
                                                        maxLength={50}
                                                        // placeholder="Trash Company..." onChange={handleInputChange}
                                                        plaintext
                                                        value={
                                                            formData.trash_company
                                                                ? formData.trash_company
                                                                : "No option selected"
                                                        }
                                                        name="trash_company"
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="trash_day" sm={6}>
                                                    Garbage Pickup Day
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="trash-company"
                                                        sm={5}
                                                        // type="select" onChange={handleInputChange}
                                                        plaintext
                                                        value={
                                                            formData.trash_day
                                                                ? formData.trash_day
                                                                : "No option selected"
                                                        }
                                                        name="trash_day"
                                                    >
                                                        {/* <option value="" hidden></option>
                              <option value={"Monday"}>Monday</option>
                              <option value={"Tuesday"}>Tuesday</option>
                              <option value={"Wednesday"}>Wednesday</option>
                              <option value={"Thursday"}>Thursday</option>
                              <option value={"Friday"}>Friday</option>
                              <option value={"Saturday"}>Saturday</option>
                              <option value={"Sunday"}>Sunday</option> */}
                                                    </Input>
                                                </Col>
                                            </FormGroup>

                                            {/* <FormGroup
                        row
                        tag="fieldset"
                      >
                        <Label for="trash_bins" sm={6}>Trash Bins</Label>
                        <Col sm={4}>
                          <FormOption
                            subtitle=""
                            type="radio"
                            name="trash_bins"
                            direction="horizontal"
                            options={[
                              { label: 'Yes', value: 'Yes', name: 'trash_bins' },
                              { label: 'No', value: 'No', name: 'trash_bins' }
                            ]}
                            value={formData.trash_bins}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </FormGroup> */}

                                            {/* <FormGroup row>
                        <Label for="trash_pickup" sm={6}>
                          Trash Pickup
                        </Label>
                        <Col sm={5}>
                          <FormOption
                            type="radio"
                            name="trash_pickup"
                            direction="vertical"
                            options={[
                              {
                                label: "Hoste",
                                value: "Hoste",
                                name: "trash_pickup",
                              },
                              {
                                label: "Myself",
                                value: "Myself",
                                name: "trash_pickup",
                              },
                              {
                                label: "Not applicable",
                                value: "Not applicable",
                                name: "trash_pickup",
                              },
                            ]}
                            value={formData.trash_pickup}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </FormGroup> */}

                                            <FormGroup row>
                                                <Label for="bear_proof_bins" sm={6}>
                                                    Bear Proof Bins
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="bear_proof_bins"
                                                        sm={5}
                                                        maxLength={50}
                                                        plaintext
                                                        value={
                                                            formData.bear_proof_bins == "True" ? "Yes" : "No"
                                                        }
                                                        name="bear_proof_bins"
                                                    />
                                                    {/* <FormOption
                            type="radio"
                            name="bear_proof_bins"
                            direction="horizontal"
                            options={[
                              { label: 'Yes', value: 'Yes', name: 'bear_proof_bins' },
                              { label: 'No', value: 'No', name: 'bear_proof_bins' }
                            ]}
                            value={formData.bear_proof_bins}
                            onChange={handleInputChange}
                          /> */}
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="recycling" sm={6}>
                                                    Recycling
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="recycling"
                                                        sm={5}
                                                        maxLength={50}
                                                        plaintext
                                                        value={formData.recycling == "True" ? "Yes " : "No"}
                                                        name="recycling"
                                                    />
                                                    {/* <FormOption
                            type="radio"
                            name="recycling"
                            direction="vertical"
                            options={[
                              { label: 'Hoste', value: 'Hoste', name: 'recycling' },
                              { label: 'Myself', value: 'Myself', name: 'recycling' },
                              { label: 'Not applicable', value: 'Not applicable', name: 'recycling' }
                            ]}
                            value={formData.recycling}
                            onChange={handleInputChange}
                          /> */}
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="internet_company" sm={6}>
                                                    Internet Company
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="internet-company"
                                                        sm={5}
                                                        maxLength={50}
                                                        // placeholder="Internet Company..." onChange={handleInputChange}
                                                        plaintext
                                                        value={
                                                            formData.internet_company
                                                                ? formData.internet_company
                                                                : "No data"
                                                        }
                                                        name="internet_company"
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label for="wifi_network" sm={6}>
                                                    Wifi Network
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="wifi_network"
                                                        name="wifi_network"
                                                        plaintext
                                                        value={
                                                            formData.wifi_network
                                                                ? formData.wifi_network
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label for="wifi_password" sm={6}>
                                                    Wifi Password
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="wifi_password"
                                                        name="wifi_password"
                                                        plaintext
                                                        value={
                                                            formData.wifi_password
                                                                ? formData.wifi_password
                                                                : "No option selected"
                                                        }
                                                    ></Input>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="form-group-field">
                                                <Label for="cable_tv" sm={6}>
                                                    Сable tv
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="cable_tv"
                                                        name="cable_tv"
                                                        plaintext
                                                        value={formData.cable_tv == "True" ? "Yes" : "No"}
                                                    ></Input>
                                                    {/* <FormOption
                            type="radio"
                            name="cable_tv"
                            direction="horizontal"
                            options={[
                              { label: 'Yes', value: 'Yes', name: 'cable_tv' },
                              { label: 'No', value: 'No', name: 'cable_tv' }
                            ]}
                            value={formData.cable_tv}
                            onChange={handleInputChange}
                          /> */}
                                                </Col>
                                            </FormGroup>
                                        </div>

                                        <Row>
                                            <Col>
                                                <hr className="horizontal-line"/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <h4 style={{fontWeight: "bold"}}>Payouts</h4>
                                            </Col>
                                        </Row>

                                        <div className="section-body">
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="management_fee" sm={6}>
                                                            Management Fee
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="management_fee"
                                                                name="management_fee"
                                                                plaintext
                                                                value={
                                                                    formData.management_fee
                                                                        ? formData.management_fee
                                                                        : "No option selected"
                                                                }
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="stocking_fee" sm={6}>
                                                            Stocking Fee
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="stocking_fee"
                                                                name="stocking_fee"
                                                                plaintext
                                                                value={
                                                                    formData.stocking_fee
                                                                        ? formData.stocking_fee
                                                                        : "No option selected"
                                                                }
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <FormGroup row className="form-group-field">
                                                <Label for="bank-info" sm={6}>
                                                    Banking Information
                                                </Label>
                                                <Col sm={5}>
                                                    <p
                                                        id="bank-info"
                                                        className=" form-control-plaintext "
                                                        style={{
                                                            color: "#525f7f",
                                                            fontFamily: "inherit",
                                                            fontWeight: "inherit",
                                                        }}
                                                    >
                                                        Contact your account manager to change the bank
                                                        where your payouts go
                                                    </p>
                                                </Col>
                                            </FormGroup>

                                            {/*
                        <Col md={6}>
                          <FormGroup row className="form-group-field">
                            <Label for="routing" sm={6}>
                              Routing Number
                            </Label>
                            <Col sm={7}>
                              <Input id="routing" sm={5} minLength={9} maxLength={9}
                                onChange={handleInputChange} value={formData.routing} onBlur={onBlur}
                                placeholder="Routing number..." name="routing" />
                              <Input id="routing" sm={5} plaintext
                                value={formData.routing ? formData.routing : 'No data'}
                                name="routing" />
                              {formData.routing?formData.routing: }
                              <p
                                className=" form-control-plaintext "
                                style={{
                                  color: "#525f7f",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                }}
                              >
                                Contact your account manager to change the bank
                                where your payouts go
                              </p>
                            </Col>
                          </FormGroup>
                        </Col> */}

                                            {/* <Col md={6}>
                          <FormGroup row className="form-group-field">
                            <Label for="account" sm={6}>
                              Account Number
                            </Label>
                            <Col sm={7}>
                              <Input id="account" sm={5} plaintext
                                value={formData.account ? formData.account : 'No data'}
                                name="account" />
                              {formData.account?formData.account:``}
                              <p
                                className=" form-control-plaintext "
                                style={{
                                  color: "#525f7f",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                }}
                              >
                                Contact your account manager to change the bank
                                where your payouts go
                              </p>
                            </Col>
                          </FormGroup>
                        </Col> */}

                                            {/* <Row>
                        <Col md={6}>
                          <FormGroup row className="form-group-field">
                            <Label for="bank_name" sm={6}>
                              Bank Name
                            </Label>
                            <Col sm={7}>
                              <Input id="bank-name" onChange={handleInputChange}
                            plaintext
                            type="textarea"
                            value={formData.bank_name?formData.bank_name:`Contact your account manager to change the bank where your payouts go`} placeholder="Bank Name..."
                            name="bank_name" />
                              {formData.bank_name?formData.bank_name:``}
                              <p
                                className=" form-control-plaintext "
                                style={{
                                  color: "#525f7f",
                                  fontFamily: "inherit",
                                  fontWeight: "inherit",
                                }}
                              >
                                Contact your account manager to change the bank
                                where your payouts go
                              </p>
                            </Col>
                          </FormGroup>
                           </Col>
                        </Col>
                      </Row> */}
                                        </div>

                                        <Row>
                                            <Col>
                                                <hr className="horizontal-line"/>
                                            </Col>
                                        </Row>

                                        <Label for="bank_name" sm={6}>
                                            Contact your account manager to change ownership
                                            information or to update your W9 tax form information
                                        </Label>

                                        {/* {formData.company_structure?<Button type="button" onClick={toggle} style={{ marginBottom: '1rem', }}>
                      Taxes
                    </Button>:"Contact your account manager to change ownership information or to update your W9 tax form information"} */}
                                        {/* <Button type="button" onClick={toggle} style={{ marginBottom: '1rem', }}>
                      Taxes
                    </Button> */}
                                        <Collapse isOpen={isOpen}>
                                            <Card>
                                                <CardBody>
                                                    <div className="section-body">
                                                        <p style={{fontWeight: "500"}}>
                                                            You will need to provide tax info if you meet one
                                                            or more of these requiremenets: <br/>
                                                            - You have an active US listing <br/>
                                                            - You have a US payout method on file <br/>
                                                            - You are a US resident according to our records{" "}
                                                            <br/>
                                                            <br/>
                                                            The American Rescue Plan Act (ARPA) of 2021
                                                            requires us to issue a valid tax document for all
                                                            Hosts earning over <br/>
                                                            $600 annually and we need your tax info to do
                                                            this. If we don't receive it, your Calendar will
                                                            be blocked from future <br/>
                                                            reservations, and your payouts scheduled for Jan
                                                            2022 and beyond will be paused. <br/>
                                                            <br/>
                                                            You will need to fill out a{" "}
                                                            <span style={{fontWeight: "bold"}}>
                                Form W-9
                              </span>{" "}
                                                            if you are a US citizen or tax resident and
                                                            receive payouts from listings in or outside the
                                                            US.
                                                        </p>

                                                        <Row>
                                                            <Col>
                                                                <hr className="horizontal-line"/>
                                                            </Col>
                                                        </Row>

                                                        <FormGroup row className="form-group-field">
                                                            <Col sm={7}>
                                                                <h4 style={{fontWeight: "bold"}}>
                                                                    Substitute Form W-9
                                                                </h4>
                                                                <p style={{fontWeight: "500"}}>
                                                                    We take steps to keep this info private.
                                                                    <a href="#" style={{fontWeight: "bold"}}>
                                                                        {" "}
                                                                        Learn more
                                                                    </a>
                                                                </p>
                                                            </Col>
                                                        </FormGroup>

                                                        <div className="ml-3">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup row className="form-group-field">
                                                                        <Label for="owner_full_name" sm={6}>
                                                                            Full name
                                                                        </Label>
                                                                        <Col sm={7}>
                                                                            <Input
                                                                                id="owner_full_name"
                                                                                name="owner_full_name"
                                                                                plaintext
                                                                                value={`${formData.owner_first_name} ${formData.owner_last_name}`}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup row className="form-group-field">
                                                                        <Label for="entity_name" sm={9}>
                                                                            Busniness/disregarded entity name
                                                                        </Label>
                                                                        <Col sm={7}>
                                                                            <Input
                                                                                id="entity_name"
                                                                                name="entity_name"
                                                                                plaintext
                                                                                value={formData.entity_name}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mt-3">
                                                                <Col md={6}>
                                                                    <FormGroup row className="form-group-field">
                                                                        <Label for="recipient_tin" sm={6}>
                                                                            Recipient TIN
                                                                        </Label>
                                                                        <Col sm={7}>
                                                                            <Input
                                                                                id="recipient_tin"
                                                                                name="recipient_tin"
                                                                                plaintext
                                                                                maxLength={9}
                                                                                minLength={9}
                                                                                value={
                                                                                    formData.recipient_tin
                                                                                        ? secureFormat(
                                                                                            formData.recipient_tin
                                                                                        )
                                                                                        : "No data"
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup row className="form-group-field">
                                                                        <Label for="recipient_tin_type" sm={6}>
                                                                            Recipient TIN Type
                                                                        </Label>
                                                                        <Col sm={7}>
                                                                            {/* <span className="rdrMonthAndYearPickers ">
                              <Dropdown
                                type="radio"
                                name="recipient_tin_type"
                                direction="horizontal"
                                options={globalConstants.TYPE_OF_TIN}
                                value={formData.recipient_tin_type}
                                onChange={handleInputChange}
                                defaultValue={'Individual/Sole proprietor'}
                              />
                                  </span> */}
                                                                            <Input
                                                                                id="recipient_tin_type"
                                                                                name="recipient_tin_type"
                                                                                plaintext
                                                                                value={
                                                                                    formData.recipient_tin_type
                                                                                        ? formData.recipient_tin_type
                                                                                        : "No option selected"
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <FormGroup row className="form-group-field">
                                                                <Label for="street_address" sm={4}>
                                                                    Address
                                                                </Label>
                                                                <Col sm={10}>
                                                                    <Input
                                                                        id="street_address"
                                                                        name="street_address"
                                                                        plaintext
                                                                        value={formData.street_address}
                                                                    />
                                                                </Col>
                                                            </FormGroup>

                                                            <Row>
                                                                <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="city">City</Label>
                                                                        <Input
                                                                            id="city"
                                                                            name="city"
                                                                            plaintext
                                                                            value={formData.city}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <Label for="state">State</Label>
                                                                        <Input
                                                                            id="state"
                                                                            name="state"
                                                                            plaintext
                                                                            value={formData.state}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <FormGroup>
                                                                        <Label for="country">Country</Label>
                                                                        <Input
                                                                            id="country"
                                                                            name="country"
                                                                            plaintext
                                                                            value={formData.country}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <Label for="postal_code">Postal code</Label>
                                                                        <Input
                                                                            id="postal_code"
                                                                            name="postal_code"
                                                                            plaintext
                                                                            value={formData.postal_code}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <FormGroup row className="form-group-field mt-4">
                                                                <Label for="business_individual" sm={6}>
                                                                    Type
                                                                </Label>
                                                                <Col sm={5}>
                                                                    <FormOption
                                                                        type="radio"
                                                                        name="business_individual"
                                                                        direction="horizontal"
                                                                        options={[
                                                                            {
                                                                                label: "Business",
                                                                                value: "Business",
                                                                                name: "business_individual",
                                                                            },
                                                                            {
                                                                                label: "Individual",
                                                                                value: "Individual",
                                                                                name: "business_individual",
                                                                            },
                                                                        ]}
                                                                        value={formData.business_individual}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </Col>
                                                            </FormGroup>

                                                            <Row>
                                                                <Col>
                                                                    <FormGroup row className="form-group-field">
                                                                        <Label for="company_structure" sm={6}>
                                                                            Company structure
                                                                        </Label>
                                                                        <Col sm={4}>
                                                                            <Input
                                                                                id="company_structure"
                                                                                type="textarea"
                                                                                maxLength={50}
                                                                                onChange={handleInputChange}
                                                                                value={formData.company_structure}
                                                                                placeholder="Company structure..."
                                                                                name="company_structure"
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <Row>
                                                            <Col>
                                                                <hr className="horizontal-line"/>
                                                            </Col>
                                                        </Row>

                                                        <p style={{fontWeight: "500"}} className="mt-2">
                                                            <h3>
                                                                Under penalties of perjury, I certify that:
                                                            </h3>
                                                            1. The number shown on this form is my correct
                                                            taxpayer identificaton number (or I am waiting for
                                                            a numver to be issued to me); <br/>
                                                            <br/>
                                                            2. I am not subject to backup withholding because:
                                                            (a) I am exempt from backup withholding, or (b) I
                                                            have not been notified by The Internal Revenue
                                                            Service (IRS) that I am subject to backup
                                                            withholding as a result of a failure to report all
                                                            interest or dividens, or (c) the IRS has notified
                                                            me that I am subject to backup withholding; <br/>
                                                            <br/>
                                                            3. I am a US citizen or other US person;
                                                            <br/>
                                                            <br/>
                                                            4. The FATCA code(s) entered on this form (if any)
                                                            indicating that I am exempt from FATCA reporting
                                                            is correct. <br/>
                                                            <br/>
                                                            The Internal Revenue Service doesn't require your
                                                            consent to any provision of this document other
                                                            than the certifications required to avoid backup
                                                            withholding.
                                                        </p>

                                                        <div className="mt-5">
                                                            <Row>
                                                                <Col md={8}>
                                                                    <FormGroup>
                                                                        <Label for="signature">
                                                                            Your signature
                                                                        </Label>
                                                                        <Input
                                                                            id="signature"
                                                                            name="signature"
                                                                            plaintext
                                                                            value={
                                                                                formData.owner_first_name +
                                                                                " " +
                                                                                formData.owner_last_name
                                                                            }
                                                                        />
                                                                    </FormGroup>

                                                                    <FormText className="mt--4 ml-3">
                                                                        Typing your name acts as your signature
                                                                        sertifies that you have capacity for sign
                                                                        for the person entity identified for this
                                                                        form.
                                                                    </FormText>
                                                                </Col>

                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label for="signing-date" sm={6}>
                                                                            Sign Date
                                                                        </Label>
                                                                        <Input
                                                                            id="signing-date"
                                                                            name="initial_signing_date"
                                                                            className="ml-3"
                                                                            plaintext
                                                                            value={
                                                                                formData.initial_signing_date
                                                                                    ? formData.initial_signing_date
                                                                                    : "No option selected"
                                                                            }
                                                                        ></Input>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>

                                                    <Row>
                                                        <Col md={8}>
                                                            <FormGroup check className="ml--3 mt-4">
                                                                <Label for="delivery">
                                                                    Electronic delivery
                                                                </Label>
                                                                <Checkbox
                                                                    options={[
                                                                        {
                                                                            value: true,
                                                                            label:
                                                                                "Issue me electronic 1099 forms only",
                                                                            isChecked: formData.electronic_delivery,
                                                                        },
                                                                    ]}
                                                                    onChange={handleSingleCheckbox}
                                                                    name="electronic_delivery"
                                                                    value={formData.electronic_delivery}
                                                                />

                                                                <FormText className="mt--4 ml-4">
                                                                    Uncheck to receive a mailed copy in addition
                                                                    to an electronic copy <br/>
                                                                    Please read important{" "}
                                                                    <a
                                                                        href=""
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "#4D7BCA",
                                                                        }}
                                                                    >
                                                                        disclosure information
                                                                    </a>
                                                                </FormText>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Collapse>

                                        <Row>
                                            <Col>
                                                <hr className="horizontal-line"/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <h4
                                                    id="tooltip9826398141414567"
                                                    style={{fontWeight: "bold"}}
                                                >
                                                    Property Contacts
                                                </h4>
                                            </Col>
                                            <UncontrolledTooltip
                                                delay={0}
                                                trigger="hover focus"
                                                target="tooltip9826398141414567"
                                                style={{maxWidth: "30rem"}}
                                            >
                                                These are individuals you give us permission to contact
                                                for matters regarding the home if you aren’t available.
                                            </UncontrolledTooltip>
                                        </Row>

                                        <div className="section-body">
                                            {/* <Row>
                        <Col md={6}>
                          <FormGroup row className="form-group-field">
                            <Label
                              for="phone"
                              sm={6}
                            >
                              Primary Phone
                            </Label>
                            <Col sm={7}>
                              <TextField
                                id="phone"
                                name="phone"
                                className="mt-2"
                                type="tel"
                                error={errors.phone}
                                value={formData.phone}
                                onChange={onPhoneChange}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">
                                    +1
                                  </InputAdornment>,
                                }}
                              />
                              {errors.phone && <FormText className="helper-message mb-2"> Invalid phone format.</FormText>}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup row className="form-group-field">
                            <Label
                              for="primary_email"
                              sm={6}
                            >
                              Primary Email
                            </Label>
                            <Col sm={7}>
                              <Input id="primary_email" onChange={handleInputChange} type="email" maxLength={50}
                                value={formData.primary_email} placeholder="Primary Email..." name="primary_email" />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <hr className="horizontal-line" />
                        </Col>
                      </Row> */}

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="secondary_contact_first_name" sm={7}>
                                                            Secondary contact first name
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="secondary_contact_first_name"
                                                                sm={5}
                                                                placeholder="First name..."
                                                                onChange={handleInputChange}
                                                                value={formData.secondary_contact_first_name}
                                                                name="secondary_contact_first_name"
                                                                maxLength={25}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="secondary_contact_last_name" sm={7}>
                                                            Secondary contact last name
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="secondary_contact_last_name"
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                value={formData.secondary_contact_last_name}
                                                                placeholder="Last name..."
                                                                name="secondary_contact_last_name"
                                                                maxLength={25}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="secondary-phone" sm={6}>
                                                            Secondary Phone
                                                        </Label>
                                                        <Col sm={7}>
                                                            <TextField
                                                                id="secondary-phone"
                                                                className="mt-2"
                                                                name="secondary_phone"
                                                                type="tel"
                                                                error={errors.secondary_phone}
                                                                // value={secureFormat(formData.secondary_phone)}
                                                                value={formData.secondary_phone}

                                                                onChange={onPhoneChange}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            +1
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            {errors.secondary_phone && (
                                                                <FormText className="helper-message mb-2">
                                                                    {" "}
                                                                    Invalid phone format.
                                                                </FormText>
                                                            )}
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="secondary-email" sm={6}>
                                                            Secondary Email
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="secondary-email"
                                                                onChange={handleInputChange}
                                                                type="email"
                                                                maxLength={50}
                                                                // value={secureEmail(formData.secondary_email)}
                                                                value={formData.secondary_email}
                                                                placeholder="Secondary Email..."
                                                                name="secondary_email"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <hr className="horizontal-line"/>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="third_contact_first_name" sm={7}>
                                                            Third contact first name
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="third_contact_first_name"
                                                                sm={5}
                                                                placeholder="First name..."
                                                                onChange={handleInputChange}
                                                                value={formData.third_contact_first_name}
                                                                name="third_contact_first_name"
                                                                maxLength={25}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="third_contact_last_name" sm={7}>
                                                            Third contact last name
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="third_contact_last_name"
                                                                onChange={handleInputChange}
                                                                type="text"
                                                                value={formData.third_contact_last_name}
                                                                placeholder="Last name..."
                                                                name="third_contact_last_name"
                                                                maxLength={25}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="third_phone" sm={6}>
                                                            Third Phone
                                                        </Label>
                                                        <Col sm={7}>
                                                            <TextField
                                                                id="third_phone"
                                                                type="tel"
                                                                className="mt-2"
                                                                name="third_phone"
                                                                error={errors.third_phone}
                                                                // value={secureFormat(formData.third_phone)}
                                                                value={formData.third_phone}
                                                                onChange={onPhoneChange}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            +1
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            {errors.third_phone && (
                                                                <FormText className="helper-message mb-2">
                                                                    {" "}
                                                                    Invalid phone format.
                                                                </FormText>
                                                            )}
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup row className="form-group-field">
                                                        <Label for="third_email" sm={6}>
                                                            Third Email
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                id="third-email"
                                                                onChange={handleInputChange}
                                                                type="email"
                                                                maxLength={50}
                                                                // value={secureEmail(formData.third_email)}
                                                                value={formData.third_email}
                                                                placeholder="Third Email..."
                                                                name="third_email"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <FormGroup check row>
                                            <Col
                                                sm={{
                                                    offset: -3,
                                                    size: 10,
                                                }}
                                            >
                                                <Button
                                                    className="primary-btn header mt-4 ml--2"
                                                    color="info"
                                                    type="submit"
                                                    form="property-details"
                                                    disabled={Object.values(errors).some(
                                                        (item) => item === true
                                                    )}
                                                >
                                                    Save Changes
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    property: state.property.property,
    documentsData: state.document.documents,
    message: state.alert.message,
    type: state.alert.type,
});

export default connect(mapStateToProps, {
    getPropertyById,
    updatePropertyInfo,
    getAllDocuments,
})(PropertyDetails);
