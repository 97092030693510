import { authConstants } from "../constants";

let user = JSON.parse(localStorage.getItem("hoste_user"));
const initialState = user
  ? { loggedIn: true, user }
  : { loggedIn: false, user: null };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        user: null,
      };
    case authConstants.LOGOUT:
      return {
        loggedIn: false,
        user: null,
      };
    case authConstants.REGISTER_SUCCESS:
      return {
        loggedIn: false,
      };
    default:
      return state;
  }
}
