/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { generateKey } from "app/helpers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    Button,
    Row
} from "reactstrap";


const LatestPropertyCareWidget = () => {
    const dashboardData = useSelector(state => state.property.dashboard);
    const [latestTasks, setLatestTasks] = useState([]);
    const history = useHistory();

    useEffect(() => {
        // if (dashboardData) {
        //     const latestPropertyTasks = Object.values(dashboardData.latest_completed_property_tasks);
        //     setLatestTasks(latestPropertyTasks);
        // }
        if (dashboardData && dashboardData.quick_tasks_access_table) {
            const latestPropertyTasks = Object.values(dashboardData.quick_tasks_access_table);
            setLatestTasks(latestPropertyTasks);
        }
    }, [dashboardData]);

    const onClickHandle = (event) => {
        const propertyId = event.currentTarget.getAttribute('property-id');
        history.push(`/owner/property-care/${propertyId}`);
    };

    const renderPropertyCare = (propertyCare) => {
        return (
            <li key={generateKey()}
                property-id={propertyCare.property_id} onClick={onClickHandle}>
                <Row className="align-items-center">
                    <div className="col-auto">
                        <span className="text-success mr-1">●</span>
                    </div>
                    <div className="col">
                        <h4 className="mb-0">
                            <a href="">
                                {propertyCare.name} at {propertyCare.entity_name}
                            </a>
                        </h4>
                        <small className="text-muted">
                            ID: {propertyCare.breezeway_task_id},
                        </small>
                        <small className="ml-2 text-muted font-weight-bold">
                            Completed: {propertyCare.finished_at}
                        </small>
                    </div>
                    <div className="col-auto">
                        <Button color="primary" size="sm" type="button">
                            <i className="fas fa-angle-right"></i>
                        </Button>
                    </div>
                </Row>
            </li>
        );
    };

    return (
        <div className="dashboard-tasks-table">
            <div className="property-header">
                <h3>Recent Property Tasks</h3>
            </div>
            <div>
                <ul className="list" flush>
                    {latestTasks.length
                        ? latestTasks.map(task => renderPropertyCare(task))
                        : <li className="pl-3">No items</li>}
                </ul>
            </div>
        </div>
    )
};

export default LatestPropertyCareWidget;