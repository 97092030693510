// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";


function OwnerSubNav({ curPage }) {
  const [id, setId] = useState(null);

  useEffect(() => {
    setId(JSON.parse(localStorage.getItem('property_id')));
  }, [id]);

  const subNavList = [
    { link: `/owner/property-details/${id}`, name: "Property Info" },
    { link: `/owner/property-document/${id}`, name: "Documents" },
    { link: `/owner/property-calendar/${id}`, name: "Calendar" },
    { link: `/owner/property-care/${id}`, name: "Property Care" }
  ];

  return (
    <>
      <Navbar className="navbar-expand section-primary owner-subnav">
        <Container fluid>
          <Nav className="mr-auto owner-navbar" navbar>
            {subNavList.map((menu, idx) => (
              <NavItem key={idx}>
                <NavLink to={menu.link} tag={Link} className={menu.name === curPage ? 'active' : ''}>
                  <span className="nav-link-inner--text">{menu.name}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
OwnerSubNav.propTypes = {
  curPage: PropTypes.string,
};

export default OwnerSubNav;
