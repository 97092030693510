import {
  authConstants,
  alertConstants,
  onboardingConstants,
  userConstants,
  CSRFToken,
  propertyConstants,
} from "../constants";

export const login = (email, password) => {
  return async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": CSRFToken,
      },
      body: JSON.stringify({
        username: email,
        password,
      }),
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/login/",
        requestOptions
      );
      const user = await response.json();

      if (response.ok) {
        localStorage.setItem("hoste_user", JSON.stringify(user));

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: user,
        });
        dispatch({
          type: alertConstants.CLEAR,
          message: "",
        });
        dispatch({
          type: onboardingConstants.ONBOARDING_UPDATE_STEPS,
          step: 1,
          substep: 1,
        });
      } else {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
        });
        dispatch({
          type: alertConstants.ERROR,
          message: "Unable to log in with provided credentials",
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
            message: "",
          });
        }, [3000]);
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: "Error occured during proccesing your request. Try again!",
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR,
          message: "",
        });
      }, [3000]);
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export function logout() {
  return async (dispatch) => {
    try {
      await fetch(process.env.REACT_APP_API_URL + "/auth/logout/", {
        method: "POST",
      });

      dispatch({
        type: authConstants.LOGOUT,
      });
      dispatch({
        type: propertyConstants.CLEAR,
      });
      dispatch({
        type: userConstants.CLEAR,
      });
      dispatch({
        type: onboardingConstants.CLEAR,
      });

      localStorage.removeItem("hoste_user");
      localStorage.removeItem('id_for_fetch_property');
      window.location.reload()
    } catch (error) {
      console.error(error.message);
    }
  };
}

export function register(user) {
  return async (dispatch) => {
    try {
      if (user) {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/auth/registration/",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-CSRFToken": CSRFToken,
            },
            body: JSON.stringify(user),
          }
        );

        const responseJson = await response.json();
        await verifyEmail(responseJson.key);

        dispatch({
          type: authConstants.REGISTER_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        type: authConstants.REGISTER_FAILURE,
      });
      dispatch({
        type: alertConstants.ERROR,
        message: "Registration failed",
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR
        });
      }, 3000);
      console.error(error.message);
    }
  };
}

async function verifyEmail(token) {
  try {
    await fetch(
      process.env.REACT_APP_API_URL + "/auth/registration/verify-email/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": CSRFToken,
        },
        body: JSON.stringify({
          key: token,
        }),
      }
    );
  } catch (error) {
    console.error(error.message);
  }
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/auth/password/reset/confirm/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": CSRFToken,
          },
          body: JSON.stringify(credentials),
        }
      );

      if (response.ok) {
        dispatch({
          type: authConstants.RESET_PASSWORD_SUCCESS,
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: "Password has been reset with the new password.",
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR
          });
        }, 5000);
      }
    } catch (error) {
      dispatch({
        type: authConstants.RESET_PASSWORD_FAILURE,
      });
      console.error(error.message);
    }
  };
}

export function sendEmail(email) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/password/reset/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": CSRFToken,
          },
          body: JSON.stringify({email}),
        }
      );
      dispatch({
        type: authConstants.RESET_PASSWORD_SUCCESS,
      });

      if (response.ok) {
        dispatch({
          type: alertConstants.ERROR,
          message: "Password reset e-mail has been sent.",
        });
      }
    } catch (error) {
      dispatch({
        type: authConstants.RESET_PASSWORD_FAILURE,
      });
      console.error(error.message);
    }
  };
}

export function changePassword({ email, oldPassword, newPassword }) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": CSRFToken,
        },
        body: JSON.stringify({
          username: email,
          password: oldPassword,
        }),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/login/",
        requestOptions
      );
      const user = await response.json();

      if(user.non_field_errors) throw Error(user.non_field_errors)

      if (user) {
        const response = await fetch(process.env.REACT_APP_API_URL + "/auth/password/change/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + user.key,
          },
          body: JSON.stringify({
            new_password1: newPassword,
            new_password2: newPassword
          }),
        });
        
        return response;
      }
    } catch (error) {
      console.log(error.message)
      if(error.message === 'Unable to log in with provided credentials.'){
        dispatch({
          type: alertConstants.ERROR,
          message: "Your old password was entered incorrectly. Please enter it again.",
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
            message: "",
          });
        }, [3000]);
      }
    }
  };
}
