import { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createReservation,
  getPropertyById,
  updateReservation,
  getAllReservationsByPropertyId,
  getOwnerProfile,
} from "app/actions";

import {
  Button,
  Col,
  Collapse,
  Label,
  Row,
  Form,
  FormText,
  FormGroup,
  Card,
  CardHeader,
} from "reactstrap";
import { Formik, Form as FormikForm } from "formik";
import classNames from "classnames";
import moment from "moment";
import * as Yup from "yup";

import FormInput from "components/Forms/FormInput";
import FormOption from "components/Forms/FormOption";
import CustomDropdown from "components/Forms/CustomDropdown";

import { capitalizeFirstLetter } from "app/helpers";
import { reservationConstants } from "app/constants";

const StayForm = ({
  formData,
  setModal,
  isTheSameRange,
  isReadonly,
  setIsReadonly,
  reset,
  checkIfOverlapped,
  predefinedRanges,
  setFormData,
  setLoading,
  setRanges,
}) => {
  const [guestyMaxOccupancy, setGuestyMaxOccupancy] = useState(10);
  const allowedGuestsNumber = [...Array(guestyMaxOccupancy).keys()].map(
    (x) => ++x
  );

  const ownerData = useSelector((state) => state.user.ownerProfile);
  const propertyData = useSelector((state) => state.property.property);
  const dispatch = useDispatch();

  const propertyId = JSON.parse(localStorage.getItem("property_id"));
  const today = moment();

  const StayFormSchema = Yup.object().shape({
    startDate: Yup.date()
      .required("This field is required")
      .min(today, "You can’t book a date that is in the past"),
    endDate: Yup.date()
      .required("This field is required")
      .min(today, "You can’t book a date that is in the past")
      .when("startDate", (startDate, schema) => {
        return schema.test({
          test: function (endDate) {
            const { startDate } = this.parent;
            return moment(endDate).isSameOrAfter(startDate);
          },
          message: "Check out date must be on or after the check in date",
        });
      })
      .when("startDate", {
        is: (startDate) => startDate,
        then: Yup.date("Incorrect date format")
          .when("startDate", (startDate, schema) => {
            return schema.test({
              test: (endDate) => {
                return (
                  !!endDate &&
                  !checkIfOverlapped({ startDate, endDate }, predefinedRanges)
                    .length
                );
              },
              message: "That date range overlaps an existing reservation",
            });
          })
          .when('startDate', (startDate, schema) => {
            return schema.test({
              test: (endDate) => {
                return !!endDate && moment(endDate).diff(moment(startDate), 'days') > 1
              },
              message: 'Reservation doesn\'t match minimum nights restrictions: 2'
            })
          })
          .when("startDate", (startDate, schema) => {
            return schema.test({
              test: (endDate) => {
                return (
                  !!endDate &&
                  moment(endDate).diff(moment(startDate), "days") < 29
                );
              },
              message:
                "Please contact the Hoste team for bookings stays of 29 days or longer",
            });
          }),
      }),
    arrival: Yup.string()
      .required("This field is required")
      .test(
        "arrival",
        "Available time range: 02:00 PM - 11:59 PM",
        (arrival) => {
          if (!arrival) return;
          const [hours, minutes] = arrival.split(":");
          const selectedTime = moment().hours(hours).minutes(minutes);
          const timeRange = {
            start: moment().hours(14).minutes(0),
            end: moment().hours(23).minutes(59),
          };

          return selectedTime.isBetween(
            timeRange.start,
            timeRange.end,
            "minutes",
            "[]"
          );
        }
      ),
    guestsCount: Yup.number().required("This field is required"),
    notes: Yup.string().nullable(),
    reservationFor: Yup.string(),
    firstName: Yup.string().when("reservationFor", {
      is: "Other person",
      then: Yup.string().required("This field is required"),
    }),
    lastName: Yup.string().when("reservationFor", {
      is: "Other person",
      then: Yup.string().required("This field is required"),
    }),
    email: Yup.string()
      .when("reservationFor", {
        is: "Other person",
        then: Yup.string().required("This field is required"),
      })
      .email("Invalid email format"),
    phone: Yup.string(),
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getOwnerProfile());
  }, []);

  useEffect(() => {
    if (propertyData) {
      setFormData({
        ...formData,
        cleaningCost: propertyData.cleaning_fee,
      });
      setGuestyMaxOccupancy(+propertyData.max_occupancy);
      setLoading(false);
    }
  }, [propertyData]);

  const isDisabled = (errors) => {
    return Object.values(errors).some((error) => !!error);
  };

  const onUpdate = (data) => {
    const reservation = {
      ...data,
      propertyId,
    };
    const status = reservationConstants.status.confirmed;

    dispatch(updateReservation(reservation, status));
  };

  const onSubmit = (data) => {
    const isOwnerBooking =
      data.reservationFor === "Myself" || data.reservationFor === "";
    const owner = {
      firstName: ownerData.first_name,
      lastName: ownerData.last_name,
      email: ownerData.primary_email,
      phone: ownerData.phone,
    };
    const guest = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
    };

    const reservation = {
      ...data,
      propertyId,
      guest: isOwnerBooking ? owner : guest,
    };

    dispatch(createReservation(reservation));
  };

  return (
    <>
      <Card>
        {isTheSameRange && (
          <CardHeader className="pl-3 pr-1 pt-3 pb-3">
            <Row className="no-wrap">
              <Col md="6">
                <h3 className="mb-0">My Reservation</h3>
              </Col>
              <Col className="text-right mt--2">
                <Button
                  outline
                  className="edit-button"
                  type="button"
                  style={{ height: ".1rem" }}
                  onClick={() => {
                    setIsReadonly(!isReadonly);
                  }}
                >
                  {isReadonly ? "Edit" : "Back"}
                </Button>
              </Col>
            </Row>
          </CardHeader>
        )}

        {isReadonly ? (
          <Form
            className="reservation-form pl-4 pt-2 pr-2"
            style={{ backgroundColor: "#f8f9fe" }}
          >
            <Row className="bg-custom">
              <Col md="6">
                <FormGroup>
                  <Label for="startDate">Check In</Label>
                  <p className="mb-0 pt-1 mt-2">{formData.startDate}</p>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="endDate">Check Out</Label>
                  <p className="mb-0 pt-1 mt-2">{formData.endDate}</p>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="arrival">Expected Arrival</Label>
                  <p className="mb-0 pt-1 mt-2">{formData.arrival}</p>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="departure">Standard Departure</Label>
                  <p className="mb-0 pt-1 mt-2">10:00 AM (for cleaning)</p>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="guestsCount">Number of Guests</Label>
                  <p className="mb-0 pt-1 mt-2">{formData.guestsCount}</p>
                </FormGroup>
              </Col>
              <Col>
                <Label for="cleaningCost">Cleaning Cost</Label>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 pt-1 mt-2">${formData.cleaningCost}</p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <FormGroup row>
              <Label for="reservationFor" sm={6}>
                Reserving for
              </Label>
              <Col md="6">
                <p className="mb-0 pt-1 mt-2">
                  {formData.reservationFor === "Myself" ? "Myself" : ""}
                </p>
              </Col>
            </FormGroup>

            <Collapse
              isOpen={formData?.reservationFor === "Other person"}
              className="p-2 mt--4"
            >
              <FormGroup row className="m--2">
                <Label for="firstName" sm={6}>
                  First Name
                </Label>
                <Col>
                  <p className="mb-0 pt-1 mt-2">
                    {capitalizeFirstLetter(formData.guest?.firstName)}
                  </p>
                </Col>
              </FormGroup>

              <FormGroup row className="m--2">
                <Label for="lastName" sm={6}>
                  Last name
                </Label>
                <Col>
                  <p className="mb-0 pt-1 mt-2">
                    {capitalizeFirstLetter(formData.guest?.lastName)}
                  </p>
                </Col>
              </FormGroup>

              {formData.phone && (
                <FormGroup row className="m--2">
                  <Label for="phone" sm={6}>
                    Phone number
                  </Label>
                  <Col>
                    <p className="mb-0 pt-1 mt-2">{formData.phone}</p>
                  </Col>
                </FormGroup>
              )}

              <FormGroup row className="m--2">
                <Label for="lastName" sm={6}>
                  Email
                </Label>
                <Col>
                  <p className="mb-0 pt-1 mt-2">{formData.email}</p>
                </Col>
              </FormGroup>
            </Collapse>

            {formData.notes && (
              <Row className="mt-3">
                <Col md="12">
                  <FormGroup>
                    <Label for="notes">Notes</Label>

                    <p className="mb-0 pt-1 mt-2">{formData.notes}</p>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Form>
        ) : (
          <Formik
            initialValues={formData}
            enableReinitialize
            validateOnMount
            validationSchema={StayFormSchema}
            onSubmit={async (values) => {
              setLoading(true);

              const isId = values.guestyReservationId;
              isId ? onUpdate(values) : onSubmit(values);

              reset();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <FormikForm
                onSubmit={handleSubmit}
                className="reservation-form pl-4 pt-2 pr-3"
                style={{ backgroundColor: "#f8f9fe" }}
              >
                <Row className="bg-custom">
                  <Col md="6">
                    <FormInput
                      id="startDate-stay"
                      className="reservation-input "
                      label="Check In"
                      placeholder="MM/DD/YYYY"
                      name="startDate"
                      type="date"
                      value={values.startDate}
                      onChange={(event) => {
                        if (event.target.value) {
                          const selectedStartDate = moment(
                            event.target.value
                          ).toDate();
                          const selectedEndDate = values.endDate
                            ? moment(values.endDate).toDate()
                            : null;

                          if (
                            !selectedEndDate ||
                            selectedStartDate <= selectedEndDate
                          ) {
                            setRanges([
                              {
                                startDate: selectedStartDate,
                                endDate: selectedEndDate,
                                key: "Owner Reservation",
                                color: "#3D91FF",
                              },
                            ]);

                            setFormData({
                              ...formData,
                              startDate: moment(event.target.value).format(
                                "YYYY-MM-DD"
                              ),
                              endDate: selectedEndDate
                                ? moment(selectedEndDate).format("YYYY-MM-DD")
                                : "",
                            });
                          }
                        }

                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      invalid={errors.startDate || errors.endDate}
                      readonly={false}
                    />
                  </Col>

                  <Col md="6">
                    <FormInput
                      id="endDate-stay"
                      className="reservation-input"
                      label="Check Out"
                      placeholder="MM/DD/YYYY"
                      name="endDate"
                      type="date"
                      value={values.endDate}
                      onChange={(event) => {
                        if (event.target.value) {
                          const selectedEndDate = moment(
                            event.target.value
                          ).toDate();
                          const selectedStartDate = values.startDate
                            ? moment(values.startDate).toDate()
                            : null;

                          if (
                            !selectedStartDate ||
                            selectedStartDate <= selectedEndDate
                          ) {
                            setRanges([
                              {
                                startDate: selectedStartDate,
                                endDate: selectedEndDate,
                                key: "Owner Reservation",
                                color: "#3D91FF",
                              },
                            ]);

                            setFormData({
                              ...formData,
                              startDate: selectedStartDate
                                ? moment(selectedStartDate).format("YYYY-MM-DD")
                                : "",
                              endDate: moment(event.target.value).format(
                                "YYYY-MM-DD"
                              ),
                            });
                          }
                        }

                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      invalid={errors.startDate || errors.endDate}
                    />
                  </Col>

                  {(errors.startDate ||
                    touched.startDate ||
                    errors.endDate ||
                    touched.endDate) && (
                    <FormText className="helper-message mt--3 mb-2 ml-3 mr-2">
                      {errors.endDate ? errors.endDate : errors.startDate}
                    </FormText>
                  )}
                </Row>

                <Row className="bg-custom">
                  <Col md="6">
                    <FormInput
                      id="arrival"
                      label="Expected Arrival"
                      placeholder="00:00 PM"
                      name="arrival"
                      type="time"
                      value={values.arrival}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={errors.arrival && touched.arrival}
                    />
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label for="departure">Standard Departure</Label>
                      <p className="mb-0 pt-1 mt-2">10:00 AM (for cleaning)</p>
                    </FormGroup>
                  </Col>

                  {errors.arrival && touched.arrival && (
                    <FormText className="helper-message mt--3 mb-2 ml-3">
                      {errors.arrival}
                    </FormText>
                  )}
                </Row>

                {!formData.guestyReservationId && (
                  <Row>
                    <Col md="6">
                      <Label for="guestsCount">Number of Guests</Label>
                      <CustomDropdown
                        id="guestsCount"
                        name="guestsCount"
                        styles="custom-dropdown"
                        defaultValue={1}
                        options={
                          allowedGuestsNumber.length
                            ? allowedGuestsNumber
                            : [1, 2, 3]
                        }
                        value={values.guestsCount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={errors.guestsCount && touched.guestsCount}
                      />

                      {errors.guestsCount && touched.guestsCount && (
                        <FormText className="helper-message mt--3 mb-2">
                          {errors.guestsCount}
                        </FormText>
                      )}
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label for="cleaningCost">Cleaning Cost</Label>
                        <p id="cleaningCost" className="mb-0 pt-1 mt-2">
                          ${formData.cleaningCost}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {formData.guestyReservationId && (
                  <Row>
                    <Col md="12">
                      <FormInput
                        id="hostenote"
                        label="Leave a note for the Hoste team"
                        name="notes"
                        placeholder="Instructions for Hoste (maintenance, cleaning, inspection, etc.) and notes (# of guests)"
                        type="textarea"
                        rows={3}
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={errors.notes && touched.notes}
                      />

                      {errors.notes && touched.notes && (
                        <FormText className="helper-message mt--3 mb-2">
                          {errors.notes}
                        </FormText>
                      )}
                    </Col>
                  </Row>
                )}

                {formData.key !== "guesty" && !isTheSameRange && (
                  <>
                    <Row className="pb-2 mb-3 pt-2">
                      <Col md="12">
                        <FormOption
                          title="Reserving for"
                          name="reservationFor"
                          direction="horizontal"
                          type="radio"
                          options={[
                            { label: "Myself", value: "Myself" },
                            { label: "Other person", value: "Other person" },
                          ]}
                          defaultValue={values.reservationFor}
                          value={values.reservationFor}
                          onChange={(event) => {
                            event.target.value === "Other person" &&
                              setFormData({
                                ...formData,
                                firstName: "",
                                lastName: "",
                                email: "",
                                phone: "",
                              });
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>

                    <Collapse isOpen={values.reservationFor === "Other person"}>
                      <>
                        <Row>
                          <Col md="12">
                            <FormInput
                              label="First name"
                              title="First name"
                              name="firstName"
                              type="text"
                              placeholder="First name..."
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.firstName && touched.firstName}
                            />

                            {errors.firstName && touched.firstName && (
                              <FormText className="helper-message mt--3 mb-2">
                                {errors.firstName}
                              </FormText>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <FormInput
                              label="Last name"
                              title="Last name"
                              name="lastName"
                              type="text"
                              placeholder="Last name..."
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.lastName && touched.lastName}
                            />

                            {errors.lastName && touched.lastName && (
                              <FormText className="helper-message mt--3 mb-2">
                                {errors.lastName}
                              </FormText>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <FormInput
                              label="Email"
                              title="Email"
                              name="email"
                              type="email"
                              placeholder="Email..."
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.email && touched.email}
                            />

                            {errors.email && touched.email && (
                              <FormText className="helper-message mt--3 mb-2">
                                {errors.email}
                              </FormText>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <FormInput
                              label="Phone number"
                              title="Phone number"
                              name="phone"
                              type="text"
                              placeholder="Phone number..."
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={errors.phone && touched.phone}
                            />

                            {errors.phone && touched.phone && (
                              <FormText className="helper-message mt--3 mb-2">
                                {errors.phone}
                              </FormText>
                            )}
                          </Col>
                        </Row>
                      </>
                    </Collapse>

                    <Row>
                      <Col md="12">
                        <FormInput
                          id="hostenote"
                          label="Leave a note for the Hoste team"
                          name="notes"
                          placeholder="Instructions for Hoste (maintenance, cleaning, inspection, etc.) and notes (# of guests)"
                          type="textarea"
                          rows={3}
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.notes && touched.notes}
                        />

                        {errors.notes && touched.notes && (
                          <FormText className="helper-message mt--3 mb-2">
                            {errors.notes}
                          </FormText>
                        )}
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col md="12">
                    {isTheSameRange ? (
                      <>
                        <Button
                          className={classNames("secondary-btn full-width")}
                          color="info"
                          type="submit"
                          disabled={isDisabled(errors)}
                        >
                          Update
                        </Button>

                        <Button
                          className="cancel-btn full-width text-center mt-3"
                          onClick={() => setModal(true)}
                        >
                          Cancel Reservation
                        </Button>
                      </>
                    ) : (
                      <Button
                        className={classNames("secondary-btn full-width")}
                        color="info"
                        type="submit"
                        disabled={isDisabled(errors)}
                      >
                        {" "}
                        Reserve
                      </Button>
                    )}
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  property: state.property.property,
});

export default connect(mapStateToProps, {
  getPropertyById,
  updateReservation,
  getAllReservationsByPropertyId,
  createReservation,
  getOwnerProfile,
})(StayForm);
