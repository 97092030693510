import { alertConstants } from "app/constants";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CardBody, Row, Col } from "reactstrap";

const ConfirmedReservation = ({ reservation }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(5);
  const [info, setInfo] = useState({
    forWho: "",
    reason: "",
  });

  const id = history.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch({
      type: alertConstants.CLEAR,
    });

    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        clearInterval(myInterval);
        history.push(`/owner/property-calendar/${id}`);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (reservation) {
      switch (reservation.reasonForReservation) {
        case "Stay":
          setInfo({
            reason: reservation.reasonForReservation,
            forWho: reservation.reservationFor,
          });
          break;
        case "Maintenance":
          setInfo({
            reason: reservation.reasonForReservation,
          });
          break;
      }
    }
  }, [reservation]);

  return (
    <>
      <CardBody className="mt-9">
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <div className="text-center mb-4 mt-3 logo">
              <img alt="" src={require("assets/img/main-logo.png").default} />
            </div>

            {info.reason && (
              <>
                {info.reason === "Stay" && (
                  <>
                    <Col className="text-center">
                      <h4 className="display-3 mb-0">Reservation confirmed</h4>

                      {info.forWho === "Myself" ? (
                        <>
                          <h4 className="display-3 mb-0">Enjoy your stay!</h4>
                        </>
                      ) : (
                        <>
                          <h4 className="display-3 mb-0">
                            Let us know how we can make the stay memorable!
                          </h4>
                        </>
                      )}
                    </Col>
                  </>
                )}

                {info.reason === "Maintenance" && (
                  <>
                    <Col className="text-center">
                      <h4 className="display-3 mb-0">
                        Your maintenance block has been confirmed!
                      </h4>
                      <h4 className="display-3 mb-0">
                        Let us know how we can help
                      </h4>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col className="mt-5 text-center">
              <span>
                You will be automatically redirected in {seconds} seconds
              </span>
            </Col>

            <Col className="mt-2 text-center">
              <button
                onClick={() => history.push(`/owner/property-calendar/${id}`)}
                style={{
                  backgroundColor: "white",
                  border: "none",
                  color: "#324cdd",
                }}
              >
                Continue to Calendar
              </button>
            </Col>
          </Col>
        </Row>
      </CardBody>
    </>
  );
};

const mapStateToProps = (state) => ({
  reservation: state.reservation.reservation,
});

export default connect(mapStateToProps, null)(ConfirmedReservation);
