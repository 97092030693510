import moment from "moment";
import { reservationConstants, alertConstants } from "app/constants";


export const createReservation = (reservation) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    const body = {
      property_id: reservation.propertyId,
      start_date: reservation.startDate,
      end_date: reservation.endDate,
      arrival: reservation.arrival,
      departure: reservation.departure,
      guests_count: reservation.guestsCount,
      nights_count: reservation.nights_count,
      revenue: 0,
      opportunity_cost: reservation.opportunityCost,
      guest: reservation.guest,
      status: "confirmed",
      notes: reservation.notes,
      key: "Owner Reservation",
      reason_for_reservation: reservation.reasonForReservation,
      reservation_for: reservation.reservationFor,
      color: "#3D91FF",
      guestId: reservation.guestId,
      type_of_reservation: "Owner"
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/owners/owner/property/reservation/`,
        requestOptions
      );

      if (response.ok) {
        reservation = await response.json();

        dispatch({
          type: reservationConstants.CREATE_RESERVATION_SUCCESS,
          payload: reservation,
        });

        dispatch({
          type: alertConstants.SUCCESS,
        });
      } else {
        const error = await response.json();

        dispatch({
          type: alertConstants.ERROR,
          message: error,
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 5000);
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );

      dispatch({
        type: alertConstants.ERROR,
        message: "The calendar of new listing is not available for those dates.",
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR,
        });
      }, 5000);
    }
  };
};

export const updateReservation = (reservation, status) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    const body = {
      property_id: reservation.propertyId,
      start_date: reservation.startDate,
      end_date: reservation.endDate,
      arrival: reservation.arrival,
      departure: reservation.departure,
      guests_count: reservation.guestsCount,
      guest: reservation.guest,
      nights_count: reservation.nights_count,
      revenue: reservation.revenue,
      opportunity_cost: reservation.opportunityCost,
      need_of_clean: reservation.needOfClean,
      notes: reservation.notes,
      key: reservation.key,
      guesty_reservation_id: reservation.guestyReservationId,
      status: status,
      color: "#3D91FF",
      guestId: reservation.guestId,
    };

    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/reservation/${reservation.guestyReservationId}/`,
        requestOptions
      );
      if (response.ok) {
        const payload = await response.json();

        if (status === reservationConstants.status.canceled) {
          dispatch({
            type: reservationConstants.GET_ALL_RESERVATIONS_BY_PROPERTY_ID,
            payload,
          });
        } else {
          dispatch({
            type: reservationConstants.UPDATE_RESERVATION,
            payload,
          });
        }
      } else {
        const error = await response.json();

        dispatch({
          type: alertConstants.ERROR,
          message: error,
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 5000);
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};


export const createMaintenanceBooking = (data, status) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));
  const new_endDate = moment(data.endDate).subtract(1, "days").format('YYYY-MM-DD');
  console.log('data.startDate, new_end', data.startDate, new_endDate)

  return async (dispatch) => {
    const body = {
      property_id: data.propertyId,
      start_date: data.startDate,
      end_date: moment(data.startDate).isSame(data.endDate) ? data.endDate : new_endDate,
      notes: data.notes,
      status: status,
      revenue: 0,
      nights_count: data.nights_count,
      opportunity_cost: data.opportunityCost,
      reason_for_reservation: data.reasonForReservation,
      reservation_for: data.reservationFor,
      color: "#3D91FF",
      key: "Owner Reservation",
      type_of_reservation: "Maintenance"
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/${data.propertyId}/calendar/`,
        requestOptions
      );

      if (response.ok) {
        const reservation = await response.json();

        dispatch({
          type: reservationConstants.CREATE_RESERVATION_SUCCESS,
          payload: reservation,
        });
        dispatch({
          type: alertConstants.SUCCESS,
        });
      } else {
        const error = await response.json();
        dispatch({
          type: alertConstants.ERROR,
          message: error,
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 5000);
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: "Error: The calendar of new listing is not available for those dates",
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR,
        });
      }, 3000);

      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const getAllReservationsByPropertyId = (propertyId) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/${propertyId}/reservations/`,
        requestOptions
      );
      const reservations = await response.json();

      if (response.ok) {
        dispatch({
          type: reservationConstants.GET_ALL_RESERVATIONS_BY_PROPERTY_ID,
          payload: reservations,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const getAllReservations = (page, sortId, finish, title, properties, range) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/reservations/${page}/${sortId}/${finish}/${title}/${properties}/${range}/`,
        requestOptions
      );
      const reservations = await response.json();

      if (response.ok) {
        dispatch({
          type: reservationConstants.GET_ALL_RESERVATIONS,
          payload: reservations,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const getReservationById = (reservationId) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/reservation/${reservationId}/`,
        requestOptions
      );
      const reservation = await response.json();

      if (response.ok) {
        dispatch({
          type: reservationConstants.GET_RESERVATION_BY_ID,
          payload: reservation,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const getCalendarByDateRange = (propertyId, data, isAvailabilitySettings = false) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/${propertyId}/calendar/${data.startDate}/${data.endDate}/`,
        requestOptions
      );

      if (response.ok) {
        const calendar = await response.json();
        console.log('isAvailabilitySettings', isAvailabilitySettings)

        dispatch({
          type: isAvailabilitySettings ? reservationConstants.RETREIVE_CALENDAR_FOR_SETTINGS : reservationConstants.RETREIVE_CALENDAR_FOR_COST,
          payload: JSON.parse(calendar),
        });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: "The error occured. Try again!",
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 3000);
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: "The error occured. Try again!",
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR,
        });
      }, 3000);
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};



export const updateMaintenanceBooking = (data, status) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));
  const new_endDate = moment(data.endDate).subtract(1, "days").format('YYYY-MM-DD');

  return async (dispatch) => {
    const body = {
      property_id: data.propertyId,
      start_date: data.startDate,
      end_date: moment(data.startDate).isSame(data.endDate) ? data.endDate : new_endDate,
      notes: data.notes,
      status: status,
      revenue: 0,
      key: "Owner Reservation",
      nights_count: data.nights_count,
      opportunity_cost: data.opportunityCost,
      reason_for_reservation: data.reasonForReservation,
      reservation_for: data.reservationFor,
      reservation_id: data.id,
      color: "#3D91FF",
    };

    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/owners/owner/property/${data.propertyId}/calendar/`,
        requestOptions
      );

      if (response.ok) {
        const payload = await response.json();

        if (status === "unavailable") {
          dispatch({
            type: reservationConstants.UPDATE_RESERVATION,
            payload,
          });
        } else {
          dispatch({
            type: reservationConstants.GET_ALL_RESERVATIONS_BY_PROPERTY_ID,
            payload,
          });
        }
      } else {
        const error = await response.json();

        dispatch({
          type: alertConstants.ERROR,
          message: error,
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 5000);
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const clearCostOfSelectedDays = () => {
  return async (dispatch) => {
    dispatch({
      type: reservationConstants.CLEAR_CALENDAR,
    });
  };
};

export const clearReservationState = () => {
  return async (dispatch) => {
    dispatch({
      type: reservationConstants.CLEAR,
    });
  };
};
