/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import TrafficCard from "components/Forms/TrafficCard.js";
import TrafficCards from "components/Forms/TrafficCardWithoutDescr";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getFinancials, getAllProperties } from "app/actions";

function CardsHeader({ padding, allProperties, incomes, page }) {
  const [profits, setProfits] = useState({
    total_profit: 0,
    montly_profit: 0,
    percentage: 0,
  });
  const [total, setTotal] = useState(0);
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();

  const a = <img
    src="https://ownerportaldev.s3.amazonaws.com/Files/question-circle.svg"
    style={{ width: "10px", marginLeft: "0.2rem" }}
  ></img>

  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  let monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  useEffect(() => {
    // page !== "dashboard" && dispatch(getAllProperties());
    dispatch(getFinancials());
  }, [page]);

  useEffect(() => {
    incomes && setProfits(incomes);
  }, [incomes]);

  useEffect(() => {
    if (allProperties && allProperties.length !== 0) {
      let active = 0;
      allProperties.map((prop) => {
        if (prop.is_actively_reserved) {
          active += 1;
        }
      });

      setTotal(allProperties.length);
      setActive(active);
    }
  }, [allProperties]);

  return (
    <>
      <div className={padding !== "" ? "header " + padding : "header"}>
        <Container fluid>
          <div className="header-body" >
            <Row>
              <Col md="6">
                <TrafficCard
                  title={monthNames[month] + " Net Income"}

                  img={a}
                  // description={a}

                  value={profits.montly_profit ? "$" + profits.montly_profit.toFixed(0) : "$" + 0}
                  // subtitle={"Year-to-date NET Income"}
                  subtitle={year +" Estimated Net Income"}
                  // subvalue={profits.total_profit ? "$" + profits.total_profit.toFixed(0) : "$" + 0}
                  subvalue={profits.year_income ? "$" + profits.year_income.toFixed(0) : "$" + 0}
                  subimg={a}
                  // uppercent={
                  //   profits.percentage
                  //     ? profits.percentage.toFixed(1) + "%"
                  //     : 0 + "%"
                  // }
                  // description={ profits.total_profit 
                  //   ? "Since last month. Total revenue: $" + profits.total_profit
                  //   : "Since last month. Total revenue: $0"
                  // }
                  ico="fas fa-dollar-sign"
                  bgcolor="bg-traffic1"
                />
              </Col>
              <Col md="6">
                <TrafficCards
                  title="ACTIVELY RESERVED PROPERTIES"
                  value={active}
                  description={`Out of ${total} total properties`}
                  ico="fas fa-home"
                  bgcolor="bg-traffic1"
                />
              </Col>
              {/* <Col md="6" xl="3">
                <TrafficCard
                  title="PROPERTY CARE IN PROGRESS"
                  value="0"
                  uppercent=""
                  description="Taken care by Hoste"
                  ico="fas fa-hand-holding-water"
                  bgcolor="bg-traffic1"
                />
              </Col>
              <Col md="6" xl="3">
                <TrafficCard
                  title="NEEDS OWNER ATTENTION"
                  value="0"
                  uppercent=""
                  description="View section"
                  ico="ni ni-bell-55"
                  bgcolor="bg-traffic2"
                />
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  padding: PropTypes.string,
};

const mapStateToProps = (state) => ({
  allProperties: state.property.allProperties,
  incomes: state.financials.incomes?.total,
});

export default connect(mapStateToProps, {
  getAllProperties,
  getFinancials,
})(CardsHeader);
