import { CSRFToken } from "app/constants";
import { alertConstants, propertyConstants } from "app/constants";
import axios from "axios";


export const getDashboardData = (idsList) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      let response;

      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };

      if (idsList.length) {
        response = await fetch(
          process.env.REACT_APP_API_URL + `/owners/dashboard/?id=${idsList.join('&id=')}`,
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_API_URL + `/owners/dashboard/`,
          requestOptions
        );
      }
      const dashboard = await response.json();

      if (response.ok) {
        dispatch({
          type: propertyConstants.GET_DASHBOARD,
          payload: dashboard,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};


export const getAllProperties = () => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + key,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/owners/owner/properties/",
        requestOptions
      );
      const properties = await response.json();

      if (response.ok) {
        dispatch({
          type: propertyConstants.GETALL_SUCCESS,
          payload: properties,
        });
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const updateProperty = (data) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  return async function (dispatch) {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": CSRFToken,
          Authorization: "Token " + key,
        },

        body: JSON.stringify(data),
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/owners/owner/properties/",
        requestOptions
      );

      if (response.ok) {
        // dispatch({
        //   type: propertyConstants.UPDATE_PROPERTY_DETAILS,
        //   payload: propertyInfo,
        // });
        dispatch({
          type: alertConstants.SUCCESS,
          message: "Property information updated successfully!",
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 3000);
      } else {
        const error = await response.json();

        dispatch({
          type: alertConstants.ERROR,
          message: error,
        });
        setTimeout(() => {
          dispatch({
            type: alertConstants.CLEAR,
          });
        }, 3000);
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: error,
      });
      setTimeout(() => {
        dispatch({
          type: alertConstants.CLEAR,
        });
      }, 3000);
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
};

export const updatePropertyInfo = (propertyInfo) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));
  const propertyId = JSON.parse(localStorage.getItem("property_id"));

  return async function (dispatch) {
    try {
      axios
        .request({
          method: "put",
          url: process.env.REACT_APP_API_URL + "/owners/owner/properties/",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": CSRFToken,
            Authorization: "Token " + key,
          },
          data: JSON.stringify({
            ...propertyInfo,
            id: propertyId,
            status: propertyConstants.status.completed,
          }),
        })
        .then((response) => {
          if (response.statusText == "OK") {
            dispatch({
              type: propertyConstants.UPDATE_PROPERTY_DETAILS,
              payload: propertyInfo,
            });
            dispatch({
              type: alertConstants.SUCCESS,
              message: "Property information updated successfully!",
            });
            setTimeout(() => {
              dispatch({
                type: alertConstants.CLEAR,
              });
            }, 3000);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: alertConstants.ERROR,
              message: error.response.data,
            });
            setTimeout(() => {
              dispatch({
                type: alertConstants.CLEAR,
              });
            }, 5000);
          }
        });
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
    // try {
    //   const requestOptions = {
    //     method: "PUT",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       "X-CSRFToken": CSRFToken,
    //       Authorization: "Token " + key,
    //     },

    //     body: JSON.stringify({
    //       ...propertyInfo,
    //       id: propertyId,
    //       status: propertyConstants.status.completed,
    //     }),
    //   };

    //   const response = await fetch(
    //     process.env.REACT_APP_API_URL + "/owners/owner/properties/",
    //     requestOptions
    //   );

    //   if (response.ok) {
    //     dispatch({
    //       type: propertyConstants.UPDATE_PROPERTY_DETAILS,
    //       payload: propertyInfo,
    //     });
    //     dispatch({
    //       type: alertConstants.SUCCESS,
    //       message: "Property information updated successfully!",
    //     });
    //     setTimeout(() => {
    //       dispatch({
    //         type: alertConstants.CLEAR,
    //       });
    //     }, 3000);
    //   } else {
    //     const error = await response.json();

    //     dispatch({
    //       type: alertConstants.ERROR,
    //       message: error,
    //     });
    //     setTimeout(() => {
    //       dispatch({
    //         type: alertConstants.CLEAR,
    //       });
    //     }, 5000);
    //   }
    // } catch (error) {
    //   console.error(
    //     "There has been a problem with your fetch operation:",
    //     error
    //   );
    // }
  };
};

export const getPropertyById = (id) => {
  const { key } = JSON.parse(localStorage.getItem("hoste_user"));
  return async (dispatch) => {
    try {
      axios
        .request({
          method: "get",
          url: process.env.REACT_APP_API_URL + `/owners/owner/property/${id}/`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + key,
          },
          validateStatus: function (status) {
            if (status == 406) {
              // Resolve only if the status code is 406
              window.location.href = "/owner/dashboard";
            }
            return status < 400;
          },
        })
        .then((response) => {
          const property = response.data;
          if (response.status == 200) {
            dispatch({
              type: propertyConstants.GET_PROPERTY_BY_ID,
              payload: property,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: alertConstants.ERROR,
              message: error.response.data,
            });
            setTimeout(() => {
              dispatch({
                type: alertConstants.CLEAR,
              });
            }, 5000);
          }
        });
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error.response.data
      );
    }
  };
  // const { key } = JSON.parse(localStorage.getItem("hoste_user"));

  // return async (dispatch) => {
  //   try {
  //     const requestOptions = {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Token " + key,
  //       },
  //     };
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + `/owners/owner/property/${id}/`,
  //       requestOptions
  //     );
  //     const property = await response.json();

  //     if (response.ok) {
  //       dispatch({
  //         type: propertyConstants.GET_PROPERTY_BY_ID,
  //         payload: property,
  //       });
  //     }
  //     else {
  //       window.location.href = "/owner/dashboard"
  //     }
  //   } catch (error) {
  //     console.error(
  //       "There has been a problem with your fetch operation:",
  //       error
  //     );
  //   }
  // };
};
